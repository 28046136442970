import React from "react";
import { Button, Popup } from "semantic-ui-react";
import MeetingTime from "../components/meetingPage/meetingTime.jsx";
import "../styles/homePage.css";
import "../styles/meetingPage.css";

const info = (
  <div>
    <h2>Help</h2>
    <p>
      The meetings page is designed to help you find optimal meeting times based
      on a number of criteria.
    </p>
    <h4>Dropdowns</h4>
    <p>
      The required dropdowns are indicated with an asterisk. The exceptions are: 
      <br/><br/>
      1) Either a project(s) or COE must be selected, but not necessarily
      both. <br/>
      2) Either a Date or a Day of the Week must be selected, NOT both.
      When a Day of the week is selected, the date is cleared out, and when a date is 
      selected, the day of the week is cleared out.
      <br/><br/>
      The Frequency dropdown defaults to "Once", displaying meetings that can 
      occur on ANY of the selected days. If "Recurring" is selected, the meeting 
      list updates to include only meeting times for ALL of the selected days AND
      the date is cleared out.
      <br/>
      The duration field defaults to 15 minutes and must have a number inputted. 
      <br/>
      The Minimum # Attendees dropdown defaults to 2 attendees. The required users 
      and time range dropdowns are optional, but serve to satisfy further criteria.
    </p>
    <h4>Available Meeting Times </h4>
    <p>
      Once the required dropdowns are selected, this section will display meetings
      that match the specified criteria. By hovering over the time, a list of all the
      users who can attend will display. For recurring meetings, lists of attendees for
      all selected days are provided.
    </p>
  </div>
);

function MeetingPageContainer() {
  return (
    <div>
      <div>
        <Popup
          trigger={
            <Button
              id="circleBtn"
              className="meetingInfoBtn"
              icon="question circle outline"
            />
          }
          on="click"
          position="bottom right"
          content={info}
          flowing={true}
          className="infoPopup"
        />
      </div>
      <div>
        <h2 className="title">Meetings</h2>
        <div>
          <MeetingTime />
        </div>
      </div>
    </div>
  );
}
export default MeetingPageContainer;
