import axios from "axios";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import {
  Button,
  Form,
  Icon,
  Input,
  Label,
  Modal,
  Table
} from "semantic-ui-react";
import "../../styles/adminTabs.css";
import ModalBuilder from "./modalBuilder";

function HolidayTab(props) {
  const [visible, setVisible] = useState(false);
  const [holidayList, setHolidayList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [clientDropdown, setClientDropdown] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [newHolidayName, setNewHolidayName] = useState("");
  const [newStartDate, setNewStartDate] = useState(new Date());
  const [newEndDate, setNewEndDate] = useState(new Date());
  const [newClientList, setNewClientList] = useState();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [deleteName, setDeleteName] = useState("");
  const [nameError, setNameError] = useState("");
  const [clientError, setClientError] = useState("");
  const [dateError, setDateError] = useState("");
  const [previousSort, setPreviousSort] = useState({
    column: "name",
    sortASC: true
  });
  const [filterBy, setFilterBy] = useState("");
  const [editable, setEditable] = useState(
    props.user.userRole.adminClientsPerm > 1
  );
  const [editHoliday, setEditHoliday] = useState({});
  const [editModalVisible, setEditModalVisible] = useState(false);

  useEffect(() => {
    getHolidayList();
    getClientList();
    sort(previousSort);
  }, []);

  useEffect(() => {
    filter(filterBy);
  }, [holidayList]);

  const closeModal = () => {
    setEditModalVisible(false);
    setNameError("");
    setClientError("");
    setDateError("");
  };

  const getHolidayList = () => {
    axios.get("/holiday/getHolidayList").then(res => {
      for (const i of res.data) {
        i.startDate = moment(i.startDate);
        i.endDate = moment(i.endDate);
      }
      setHolidayList(res.data);
    });
  };

  const holidayEdit = holiday => {
    setEditHoliday(holiday);
    setClientError("");
    setEditModalVisible(true);
  };

  const getClientList = () => {
    axios.get("/client/getClientList/active").then(res => {
      const client = res.data.map(list => {
        return {
          key: list["id"],
          text: list["clientName"],
          value: list["id"]
        };
      });
      setClientList(res.data);
      setClientDropdown(client);
    });
  };

  const sort = previousSort => {
    const newHolidayList = holidayList;
    if (previousSort.column === "name") {
      newHolidayList.sort((a, b) => {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
      });
    } else if (previousSort.column === "client") {
      newHolidayList.sort((a, b) => {
        let clientValueA = "";
        let clientValueB = "";
        _.map(a.clients, client => {
          clientValueA += client.clientName;
        });
        _.map(b.clients, client => {
          clientValueB += client.clientName;
        });
        return clientValueA > clientValueB ? 1 : -1;
      });
    } else if (previousSort.column === "startDate") {
      newHolidayList.sort((a, b) => {
        return a.startDate.isAfter(b.startDate) ? 1 : -1;
      });
    } else if (previousSort.column === "endDate") {
      newHolidayList.sort((a, b) => {
        return a.endDate.isAfter(b.endDate) ? 1 : -1;
      });
    }
    // Start with a sort by ascending. Then flip the array if it is supposed to be descending
    if (!previousSort.sortASC) newHolidayList.reverse();
    setPreviousSort(previousSort);
    setHolidayList(newHolidayList);
    filter(filterBy);
  };

  const filter = text => {
    if (text === "") {
      setFilteredList("");
      setFilteredList(holidayList);
    } else {
      setFilterBy(text);
      setFilteredList(
        holidayList.filter(item => {
          let holidayClients = "";
          for (const i of item.clients) {
            holidayClients += i.clientName;
          }
          return (
            item.name.toLowerCase().includes(text.toLowerCase()) ||
            holidayClients.toLowerCase().includes(text.toLowerCase()) ||
            item.startDate.format("MM/DD/YYYY").includes(text) ||
            item.endDate.format("MM/DD/YYYY").includes(text)
          );
        })
      );
    }
  };

  // This function makes sure that a new holiday added does not overlap any other holiday dates with clients that it is assigned to.
  const dateCheck = () => {
    let result = 0;
    // Goes through all of the holidays clients
    _.map(holidayList, holiday => {
      _.map(holiday.clients, client => {
        // Goes through the new holidays clients
        _.map(newClientList, newClient => {
          // Where the new clients are the same as old clients, this checks that the new start date is not the same as the old client's holiday start date
          if (client.id === newClient.id) {
            if (
              moment(holiday.startDate).format("MM/DD/YYYY") !==
              moment(newStartDate).format("MM/DD/YYYY")
            ) {
              // do nothing
            } else {
              result += 1;
            }
            // Does the same as previous lines for the end dates
            if (
              moment(holiday.endDate).format("MM/DD/YYYY") !==
              moment(newEndDate).format("MM/DD/YYYY")
            ) {
              // do nothing
            } else {
              result += 1;
            }
          }
        });
      });
    });
    if (result === 0) {
      return true;
    } else {
      return false;
    }
  };

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
    setDeleteModalVisible(false);
    setNameError("");
    setClientError();
    setDateError("");
    setNewHolidayName("");
    setNewStartDate(new Date());
    setNewEndDate(new Date());
    setNewClientList([]);
  };

  const showDeleteModal = holiday => {
    setDeleteModalVisible(true);
    setDeleteId(holiday.id);
    setDeleteName(holiday.name);
  };

  const handleSubmit = () => {
    let validForm = true;
    if (newHolidayName === "") {
      setNameError("Please Enter Holiday Name");
      validForm = false;
    } else if (holidayList.find(i => i.name === newHolidayName)) {
      setNameError("Holiday Already Exists");
      validForm = false;
    }
    if (!newClientList) {
      setClientError("Please Enter Client");
      validForm = false;
    } else if (!dateCheck()) {
      setClientError(
        "Selected client already has a holiday during these dates"
      );
      validForm = false;
    }
    if (moment(newStartDate).isAfter(moment(newEndDate))) {
      setDateError("Start Date is after End Date");
      validForm = false;
    }
    if (validForm) {
      const req = {
        name: newHolidayName,
        startDate: moment(newStartDate).format("MM/DD/YYYY"),
        endDate: moment(newEndDate).format("MM/DD/YYYY"),
        clients: newClientList
      };
      axios.post("/holiday/createHoliday", req).then(res => {
        hideModal();
        getHolidayList(), getClientList();
      });
    }
  };

  const validateForm = () => {
    setNameError("");
    setClientError("");
    setDateError("");
    let validForm = true;
    if (editHoliday.name === "") {
      setNameError("Please Enter Holiday Name");
      validForm = false;
    }
    if (editHoliday.clients.length === 0) {
      setClientError("Please Select a Client");
      validForm = false;
    }
    if (moment(editHoliday.startDate).isAfter(moment(editHoliday.endDate))) {
      setDateError("Start Date is after End Date");
      validForm = false;
    }
    if (
      _.find(holidayList, holiday => {
        return (
          editHoliday.id !== holiday.id && editHoliday.name === holiday.name
        );
      })
    ) {
      setNameError("Holiday Name already exists");
      validForm = false;
    }
    if (validForm) {
      axios
        .post("/holiday/updateHoliday", editHoliday)
        .then(res => {
          getHolidayList();
          closeModal();
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const handleDelete = () => {
    const req = {
      id: deleteId
    };
    axios.post("/holiday/deleteHoliday", req).then(() => {
      getHolidayList();
      getClientList();
    });
    hideModal();
  };

  const sortBy = column => {
    let sortASC = true;
    if (previousSort.column === column) {
      sortASC = !previousSort.sortASC;
    }
    sort(
      {
        column: column,
        sortASC: sortASC
      },
      () => filter(filterBy)
    );
  };

  const disabled = !editable;
  const inputStyle = { display: "flex", justifyContent: "space-between" };
  return (
    <div>
      <div>
        <div>
          <h2 className="title">Holiday Table</h2>
        </div>
        <br />
        <div className="aboveTable" id="holidayTableHeader">
          <div style={inputStyle}>
            <Button
              inverted
              color="green"
              onClick={showModal}
              size="medium"
              className="ui.medium.button"
              disabled={disabled}
            >
              Add Holiday
            </Button>
            <Input
              icon="search"
              placeholder="Filter By"
              maxLength="30"
              onChange={e => filter(e.target.value)}
            />
          </div>
          <br />
          <Table
            sortable
            className="ui table .ui.table"
            id="table"
            celled
            verticalAlign="middle"
            textAlign="center"
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  className="blockTableCell"
                  onClick={() => sortBy("name")}
                  sorted={
                    previousSort.column === "name"
                      ? previousSort.sortASC
                        ? "ascending"
                        : "descending"
                      : null
                  }
                >
                  Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="blockTableCell"
                  onClick={() => sortBy("client")}
                  sorted={
                    previousSort.column === "client"
                      ? previousSort.sortASC
                        ? "ascending"
                        : "descending"
                      : null
                  }
                >
                  Client(s)
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="blockTableCell"
                  onClick={() => sortBy("startDate")}
                  sorted={
                    previousSort.column === "startDate"
                      ? previousSort.sortASC
                        ? "ascending"
                        : "descending"
                      : null
                  }
                >
                  Start Date
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="blockTableCell"
                  onClick={() => sortBy("endDate")}
                  sorted={
                    previousSort.column === "endDate"
                      ? previousSort.sortASC
                        ? "ascending"
                        : "descending"
                      : null
                  }
                >
                  End Date
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {filteredList.length // The filtered list might be empty, so this ternary prevents the map function from running
                ? _.map(filteredList, holiday => (
                    <Table.Row
                      key={holiday.id}
                      className="rowLayout"
                      onClick={() => holidayEdit(holiday)}
                    >
                      <Table.Cell>{holiday.name}</Table.Cell>
                      <Table.Cell>
                        {_.map(holiday.clients, c => {
                          return (
                            <Label horizontal size="large" key={c.id}>
                              {c.clientName}
                            </Label>
                          );
                        })}
                      </Table.Cell>
                      <Table.Cell>
                        {holiday.startDate._i.slice(5, 7) +
                          "/" +
                          holiday.startDate._i.slice(8, 10) +
                          "/" +
                          holiday.startDate._i.slice(0, 4)}
                      </Table.Cell>
                      <Table.Cell>
                        {holiday.endDate._i.slice(5, 7) +
                          "/" +
                          holiday.endDate._i.slice(8, 10) +
                          "/" +
                          holiday.endDate._i.slice(0, 4)}
                      </Table.Cell>
                    </Table.Row>
                  ))
                : null}
            </Table.Body>
          </Table>
        </div>
      </div>
      <Modal
        closeIcon
        open={visible}
        className="scrolling modal"
        size="tiny"
        closeOnDocumentClick
        onClose={hideModal}
        id="addBlockModal"
      >
        <Modal.Header content="Add Holiday" />
        <Modal.Content>
          <Form size="small" className="login-form">
            <Form.Group widths="equal">
              <Form.Field>
                <Form.Input
                  required
                  id="newHolidayName"
                  label="Holiday Name"
                  placeholder="New Holiday Name"
                  maxLength="30"
                  error={nameError !== ""}
                  onBlur={e => setNewHolidayName(e.target.value.trim())}
                  onChange={() => setNameError("")}
                />
                {nameError === "" ? null : (
                  <Label pointing color="red">
                    {nameError}
                  </Label>
                )}
              </Form.Field>
              <Form.Field>
                <Form.Dropdown
                  id="autoMultiDrop"
                  scrolling
                  required
                  label="Client(s)"
                  error={clientError !== ""}
                  multiple
                  placeholder="Client"
                  options={clientList.map(client => ({
                    key: client.id,
                    value: client.id,
                    text: client.clientName
                  }))}
                  onChange={(e, data) => {
                    let selectedClients = data.value.map(client => {
                      return {
                        id: client
                      };
                    });
                    setNewClientList(selectedClients);
                    setClientError("");
                  }}
                />
                {clientError === "" ? null : (
                  <Label pointing color="red">
                    {clientError}
                  </Label>
                )}
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field error={dateError !== ""}>
                <label>Start Date</label>
                <DatePicker
                  id="startDate"
                  className={dateError !== "" ? "errorText" : null}
                  selected={newStartDate}
                  onChange={date => {
                    setDateError("");
                    setNewStartDate(date);
                  }}
                  popperModifiers={{
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                      boundariesElement: "viewport"
                    }
                  }}
                />
                {dateError === "" ? null : (
                  <Label pointing color="red">
                    {dateError}
                  </Label>
                )}
              </Form.Field>
              <Form.Field error={dateError !== ""}>
                <label>End Date</label>
                <DatePicker
                  id="endDate"
                  className={dateError !== "" ? "errorText" : null}
                  selected={newEndDate}
                  onChange={date => {
                    setDateError("");
                    setNewEndDate(date);
                  }}
                  popperModifiers={{
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                      boundariesElement: "viewport"
                    }
                  }}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            icon
            size="small"
            inverted
            color="green"
            onClick={handleSubmit}
          >
            <Icon name="checkmark" /> Create
          </Button>
        </Modal.Actions>
      </Modal>
      <ModalBuilder
        title="Delete Holiday?"
        content={
          <div>
            Are you sure you want to delete{" "}
            <span className="boldText">{deleteName}</span>?
          </div>
        }
        type="confirm"
        visible={deleteModalVisible}
        okFunction={handleDelete}
        closeFunction={hideModal}
      />
      <Modal
        closeIcon
        id="editHolidayModal"
        className="ui dimmable dimmed scrolling modal editModal"
        size="tiny"
        open={editModalVisible}
        closeOnDocumentClick={true}
        onClose={closeModal}
        disabled={true}
      >
        <Modal.Header>
          {editable ? "Edit Holiday" : "View Holiday"}
        </Modal.Header>
        <Modal.Content>
          <Form size="small" className="login-form">
            <Form.Group widths="equal">
              <Form.Field>
                <Form.Input
                  data-cy="holidayName"
                  disabled={!editable}
                  required
                  label="Holiday Name"
                  placeholder="Holiday Name"
                  maxLength="30"
                  value={editHoliday.name}
                  error={nameError !== ""}
                  onChange={(e, data) => {
                    let newHoliday = { ...editHoliday };
                    newHoliday.name = data.value;
                    setEditHoliday(newHoliday);
                  }}
                />
                {nameError === "" ? null : (
                  <Label pointing color="red">
                    {nameError}
                  </Label>
                )}
              </Form.Field>
              <Form.Field>
                <Form.Dropdown
                  data-cy="clientDropdown"
                  disabled={!editable}
                  required
                  selection
                  multiple
                  label="Client(s)"
                  selectOnBlur={false}
                  error={clientError !== ""}
                  defaultValue={_.map(editHoliday.clients, c => {
                    return c.id;
                  })}
                  options={clientDropdown}
                  onChange={(e, data) => {
                    let newHoliday = { ...editHoliday };
                    newHoliday.clients = [];
                    for (let i in data.value) {
                      for (let j in clientList) {
                        if (data.value[i] == clientList[j].id) {
                          newHoliday.clients = newHoliday.clients.concat([
                            clientList[j]
                          ]);
                          break;
                        }
                      }
                    }
                    setEditHoliday(newHoliday);
                  }}
                />

                {clientError === "" ? null : (
                  <Label pointing color="red">
                    {clientError}
                  </Label>
                )}
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field disabled={disabled} required error={dateError !== ""}>
                <label>Start Date</label>
                <DatePicker
                  id="startDate"
                  selected={moment(editHoliday.startDate).toDate()}
                  onChange={date => {
                    let newHoliday = { ...editHoliday };
                    newHoliday.startDate = date;
                    setEditHoliday(newHoliday);
                  }}
                  popperModifiers={{
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                      boundariesElement: "viewport"
                    }
                  }}
                />
                {dateError === "" ? null : (
                  <Label pointing color="red">
                    {dateError}
                  </Label>
                )}
              </Form.Field>
              <Form.Field disabled={disabled} required error={dateError !== ""}>
                <label>End Date</label>
                <DatePicker
                  id="endDate"
                  selected={moment(editHoliday.endDate).toDate()}
                  onChange={date => {
                    date._i = moment(date)
                      .format("YYYY-MM-DD")
                      .toString();

                    let newHoliday = { ...editHoliday };
                    newHoliday.endDate = date;
                    setEditHoliday(newHoliday);
                  }}
                  popperModifiers={{
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                      boundariesElement: "viewport"
                    }
                  }}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        {editable ? (
          <Modal.Actions>
            <Button
              floated="left"
              disabled={!editable}
              inverted
              color="red"
              size="small"
              className="ui button"
              onClick={() => {
                showDeleteModal(editHoliday);
                closeModal();
              }}
            >
              Delete
            </Button>

            <Button
              icon
              inverted
              color="green"
              size="small"
              onClick={editable ? () => validateForm() : () => closeModal()}
            >
              <Icon name="checkmark" />
              Save
            </Button>
          </Modal.Actions>
        ) : null}
      </Modal>
    </div>
  );
}

HolidayTab.propTypes = {
  user: PropTypes.object
};

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(HolidayTab);
