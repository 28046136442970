import axios from "axios";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import { updateSelectedBlock } from "../../redux-store/actions/userActions";
import "../../styles/schedulePage.css";

function BlockDropDown(props) {
  const [blockList, setBlocklist] = useState([]);
  const [selectedBlock, setSelectedBlock] = useState("");

  useEffect(() => {
    getBlockList();
  }, []);

  useEffect(() => {
    getCurrentBlock();
  }, [blockList]);

  const getBlockList = () => {
    const req = {
      coeId: props.user.coe.id
    };

    axios.post("/block/findCOEBlockList", req).then(res => {
      let sortBlock = _.orderBy(res.data, "startDate", ["desc"]);
      let currentBlocks = _.filter(sortBlock, block => moment(block.endDate).isAfter(moment().subtract(1, "days")));
      setBlocklist(currentBlocks);
    });
  };

  const getCurrentBlock = () => {
    const defaultBlock = {
      name: "Error Block",
      startDate: "2018-08-05",
      endDate: "2018-11-30",
      schedules: [],
      coe: {
        id: 1
      }
    };
    const block = _.find(blockList, block => {
      return block.id === props.user.block.selectedId;
    });
    if (block) {
      setSelectedBlock(block);
    } else {
      setSelectedBlock(defaultBlock);
    }
  };

  const handleDropDownChange = blockId => {
    props.updateSelectedBlock(
      blockId,
      props.user.block.currentId,
      props.user.id
    );
    const block = _.find(blockList, block => {
      return block.id === blockId;
    });
    setSelectedBlock(block);
    props.switchBlock(blockId);
  };

  return (
    <Dropdown
      className=".ui.dropdown meetingDropdownSize"
      text={selectedBlock.name}
      placeholder="Select a Block"
      data-cy="blockSelector"
    >
      <Dropdown.Menu>
        {_.map(blockList, block => (
          <Dropdown.Item
            key={block.id + "blockdropdown"}
            text={block.name}
            onClick={() => handleDropDownChange(block.id)}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

BlockDropDown.propTypes = {
  user: PropTypes.object,
  updateSelectedBlock: PropTypes.func
};

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateSelectedBlock: (blockId, currentBlock, userId) => {
      dispatch(updateSelectedBlock(blockId, currentBlock, userId));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BlockDropDown);
