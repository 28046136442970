import React from "react";
import { List } from "semantic-ui-react";
import { AvatarComponent } from "../../utilities/avatarComponent";

function OutOfOffice(props) {
  return (
    <div>
      <h2 className="title">Who's done for the day?</h2>
      <List>
        {props.doneForDayList.length > 0 ? (
          props.doneForDayList.map(({ time, avatar }) => {
            return (
              <List.Item key={time} data-cy="li">
                <List.Content floated="left">
                  <AvatarComponent image={avatar} />
                  {time}
                </List.Content>
              </List.Item>
            );
          })
        ) : (
          <div>No one has left for the day yet</div>
        )}
      </List>
    </div>
  );
}

export default OutOfOffice;
