import axios from "axios";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import {
  Button,
  Form,
  Icon,
  Input,
  Checkbox,
  Label,
  Modal,
  Table
} from "semantic-ui-react";
import "../../styles/adminTabs.css";
import ModalBuilder from "./modalBuilder";

function BlockTab(props) {
  const [blockList, setBlockList] = useState([]);
  const [COEList, setCOEList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [displayedList, setDisplayedList] = useState([]);
  const [showInactiveChecked, setShowInactiveChecked] = useState(false);
  const [newBlockName, setNewBlockName] = useState("");
  const [newBlockStartDate, setNewBlockStartDate] = useState(new Date());
  const [newBlockEndDate, setNewBlockEndDate] = useState(new Date());
  const [newBlockCOEId, setNewBlockCOEId] = useState("");
  const [deleteBlockId, setDeleteBlockId] = useState(null);
  const [deleteBlockName, setDeleteBlockName] = useState("");
  const [nameError, setNameError] = useState("");
  const [coeError, setCoeError] = useState("");
  const [dateError, setDateError] = useState("");
  const [previousSort, setPreviousSort] = useState({
    column: "name",
    sortASC: true
  });
  const [filterBy, setFilterBy] = useState("");
  const [editable, setEditable] = useState(
    props.user.userRole.adminClientsPerm > 1
  );
  const [editBlock, setEditBlock] = useState({});
  const [modal, setModal] = useState("");

  const editBlockHelper = block => {
    setEditBlock(block);
    setModal("editBlock");
  };

  useEffect(() => {
    getBlockList();
  }, []);

  useEffect(() => {
    if (previousSort.column === "name" && previousSort.sortASC) {
      filter(filterBy);
    } else {
      sort(previousSort);
    }
    getCOEList();
  }, [blockList]);

  useEffect(() => {
    updateDisplayedBlocks();
  }, [showInactiveChecked, filteredList]);

  const getBlockList = () => {
    axios.get("/block/getBlockList").then(res => {
      for (const i of res.data) {
        i.startDate = moment(i.startDate);
        i.endDate = moment(i.endDate);
      }
      setBlockList(res.data);
    });
  };

  const getCOEList = () => {
    axios.get("/coe/getCOEList/active").then(res => {
      setCOEList(res.data);
    });
  };

  const updateBlock = req => {
    axios.post("/block/updateBlock", req).then(res => {
      getBlockList();
      getCOEList();
    });
  };

  const validateDelete = () => {
    const req = {
      id: deleteBlockId
    };
    axios.post("/block/findBlock", req).then(res => {
      if (res.data.schedules.length === 0) {
        confirmDelete(req);
      } else {
        if (
          moment(res.data.endDate, "YYYY-MM-DD").isBefore(
            moment(new Date(), "YYYY-MM-DD")
          )
        ) {
          confirmDelete(req);
        } else {
          setModal("errorModal");
        }
      }
    });
  };

  const confirmDelete = req => {
    axios.post("/block/deleteBlock", req).then(() => {
      getBlockList();
    });
    hideModal();
  };

  const showDeleteModal = block => {
    setModal("deleteModal");
    setDeleteBlockId(block.id);
    setDeleteBlockName(block.name);
  };

  const hideModal = () => {
    setModal(null);
    setNameError("");
    setCoeError("");
    setDateError("");
    setNewBlockName("");
    setNewBlockCOEId("");
    setNewBlockStartDate(new Date());
    setNewBlockEndDate(new Date());
  };

  const showModal = () => {
    setModal("addModal");
  };

  const handleSubmit = (block, modal) => {
    //Error checks, only updates when no errors
    let validForm = true;
    if (block.name === "") {
      setNameError("Please Enter Block Name");
      validForm = false;
    } else if (
      blockList.find(i => i.name === block.name && i.id !== block.id)
    ) {
      setNameError("Block Already Exists");
      validForm = false;
    }
    if (!block.coe) {
      setCoeError("Please Enter COE");
      validForm = false;
    }
    if (moment(block.startDate).isAfter(moment(block.endDate))) {
      setDateError("Start Date is after End Date");
      validForm = false;
    }
    if (validForm) {
      if (modal === "add") {
        axios.post("/block/createBlock", block).then(res => {
          hideModal();
          getBlockList();
        });
      } else if (modal === "edit") {
        axios.post("/block/updateBlock", editBlock).then(res => {
          getBlockList();
          getCOEList();
          hideModal();
        });
      }
    }
  };

  const sort = previousSort => {
    //Checks previous sort and reverses direction if it was sorted by the same
    const newBlockList = blockList;
    if (previousSort.column === "name") {
      newBlockList.sort((a, b) => {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
      });
    } else if (previousSort.column === "COE") {
      newBlockList.sort((a, b) => {
        return a.coe.name.toLowerCase() > b.coe.name.toLowerCase() ? 1 : -1;
      });
    } else if (previousSort.column === "startDate") {
      newBlockList.sort((a, b) => {
        return a.startDate.isAfter(b.startDate) ? 1 : -1;
      });
    } else if (previousSort.column === "endDate") {
      newBlockList.sort((a, b) => {
        return a.endDate.isAfter(b.endDate) ? 1 : -1;
      });
    }
    // Start with a sort by ascending. Then flip the array if it is supposed to be descending
    if (!previousSort.sortASC) newBlockList.reverse();
    setPreviousSort(previousSort);
    setBlockList(newBlockList);

    filter(filterBy);
  };

  const sortBy = column => {
    let sortASC = true;
    if (previousSort.column === column) {
      sortASC = !previousSort.sortASC;
    }
    sort({
      column: column,
      sortASC: sortASC
    });
  };

  const filter = text => {
    if (text === "") {
      setFilterBy("");
      setFilteredList(blockList);
    } else {
      setFilterBy(text);
      setFilteredList(
        blockList.filter(
          item =>
            item.name.toLowerCase().includes(text.toLowerCase()) ||
            item.startDate.format("MM/DD/YYYY").includes(text) ||
            item.endDate.format("MM/DD/YYYY").includes(text) ||
            item.coe.name.toLowerCase().includes(text.toLowerCase())
        )
      );
    }
  };

  function updateDisplayedBlocks() {
    if (showInactiveChecked) setDisplayedList(filteredList)
    else {
      let activeList = filterActiveBlocks(filteredList);
      setDisplayedList(activeList);
    }
  }

  // A block is active if the block's end date is equal to or after the current date
  function filterActiveBlocks(blocks) {
    return _.filter(blocks, block => {
      return (
        moment(block.endDate).isAfter(moment().subtract(1, 'days'))
      );
    });
  }


  let coeDropdownOption = [];
  coeDropdownOption = _.map(COEList, coe => ({
    key: coe.id,
    value: coe.id,
    text: coe.name
  }));

  let disabled = !editable;
  return (
    <div>
      <div>
        <h2 className="title">Block Table</h2>
      </div>
      <br />
      <div className="aboveTable" id="blockTableHeader">
        <Button
          id="addBlockButton"
          inverted
          color="green"
          onClick={showModal}
          size="medium"
          disabled={disabled}
        >
          Add Block
        </Button>
        <Checkbox
          label="Show past blocks"
          checked={showInactiveChecked}
          onChange={() => setShowInactiveChecked(!showInactiveChecked)}
        />
        <Input
          icon="search"
          placeholder="Filter By"
          maxLength="30"
          onChange={e => filter(e.target.value)}
        />
      </div>
      <br />
      <Table
        sortable
        className="ui single line table .ui.table"
        id="table"
        celled
        verticalAlign="middle"
        textAlign="center"
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              className="blockTableCell"
              onClick={() => sortBy("name")}
              sorted={
                //Displays ascending/descending arrows if being sorted; nothing if not sorted field
                previousSort.column === "name"
                  ? previousSort.sortASC
                    ? "ascending"
                    : "descending"
                  : null
              }
            >
              Name
            </Table.HeaderCell>
            <Table.HeaderCell
              className="blockTableCell"
              onClick={() => sortBy("COE")}
              sorted={
                previousSort.column === "COE"
                  ? previousSort.sortASC
                    ? "ascending"
                    : "descending"
                  : null
              }
            >
              COE
            </Table.HeaderCell>
            <Table.HeaderCell
              className="blockTableCell"
              onClick={() => sortBy("startDate")}
              sorted={
                previousSort.column === "startDate"
                  ? previousSort.sortASC
                    ? "ascending"
                    : "descending"
                  : null
              }
            >
              Start Date
            </Table.HeaderCell>
            <Table.HeaderCell
              className="blockTableCell"
              onClick={() => sortBy("endDate")}
              sorted={
                previousSort.column === "endDate"
                  ? previousSort.sortASC
                    ? "ascending"
                    : "descending"
                  : null
              }
            >
              End Date
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {displayedList.length // The filtered list might be empty, so this ternary prevents the map function from running
            ? _.map(displayedList, block => (
                <Table.Row
                  key={block.id}
                  className="rowLayout"
                  onClick={() => editBlockHelper(block)}
                >
                  <Table.Cell>{block.name}</Table.Cell>
                  <Table.Cell>
                    <p id="coeName">{block.coe.name}</p>
                  </Table.Cell>
                  <Table.Cell>
                    {block.startDate.format("MM/DD/YYYY")}
                  </Table.Cell>
                  <Table.Cell>{block.endDate.format("MM/DD/YYYY")}</Table.Cell>
                </Table.Row>
              ))
            : null}
        </Table.Body>
      </Table>
      <Modal
        closeIcon
        open={modal === "addModal"}
        className="scrolling modal"
        size="tiny"
        closeOnDocumentClick
        onClose={hideModal}
        id="addBlockModal"
      >
        <Modal.Header content="Add Block" />
        <Modal.Content>
          <Form size="small" className="login-form">
            <Form.Group widths="equal">
              <Form.Field>
                <Form.Input
                  required
                  id="newBlock"
                  label="Block Name"
                  placeholder="New Block Name"
                  maxLength="30"
                  error={nameError !== ""}
                  onBlur={e => setNewBlockName(e.target.value.trim())}
                  onChange={() => setNameError("")}
                />
                {nameError === "" ? null : (
                  <Label pointing color="red">
                    {nameError}
                  </Label>
                )}
              </Form.Field>
              <Form.Field>
                <Form.Dropdown
                  required
                  selection
                  id="blockDropDown"
                  label="COE"
                  error={coeError !== ""}
                  value={newBlockCOEId}
                  options={coeDropdownOption}
                  onChange={(e, data) => {
                    setNewBlockCOEId(data.value);
                    setCoeError("");
                  }}
                  text={newBlockCOEId ? null : "COE"}
                />
                {coeError === "" ? null : (
                  <Label pointing color="red">
                    {coeError}
                  </Label>
                )}
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field required error={dateError !== ""}>
                <label>Start Date</label>

                <DatePicker
                  data-cy="startDate"
                  id="startDate"
                  className={dateError !== "" ? "errorText" : null}
                  selected={newBlockStartDate}
                  onChange={date => {
                    setNewBlockStartDate(date);
                    setDateError("");
                  }}
                  popperModifiers={{
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                      boundariesElement: "viewport"
                    }
                  }}
                />
                {dateError === "" ? null : (
                  <Label pointing color="red">
                    {dateError}
                  </Label>
                )}
              </Form.Field>
              <Form.Field required error={dateError !== ""}>
                <label>End Date</label>
                <DatePicker
                  data-cy="endDate"
                  id="endDate"
                  className={dateError !== "" ? "errorText" : null}
                  selected={newBlockEndDate}
                  onChange={date => {
                    setNewBlockEndDate(date);
                    setDateError("");
                  }}
                  popperModifiers={{
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                      boundariesElement: "viewport"
                    }
                  }}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            icon
            inverted
            color="green"
            size="small"
            onClick={() =>
              handleSubmit(
                {
                  name: newBlockName,
                  coe: COEList.find(item => item.id === newBlockCOEId),
                  startDate: moment(newBlockStartDate),
                  endDate: moment(newBlockEndDate),
                  active: true
                },
                "add"
              )
            }
          >
            <Icon name="checkmark" /> Create
          </Button>
        </Modal.Actions>
      </Modal>
      {/* Are you sure you want to delete modal */}
      <ModalBuilder
        closeIcon
        id="deleteModal"
        title="Delete Scheduling Block?"
        content={
          <div>
            Are you sure you want to delete{" "}
            <span className="boldText">{deleteBlockName}</span>?
          </div>
        }
        type="confirm"
        visible={modal === "deleteModal"}
        okFunction={validateDelete}
        closeFunction={() => {
          hideModal();
        }}
      />
      {/* Error Modal */}
      <ModalBuilder
        closeIcon
        title="Error"
        content="Please wait until this block has passed its end date or remove schedules associated with this block to delete it."
        type="notification"
        visible={modal === "errorModal"}
        closeFunction={hideModal}
      />

      {modal !== "editBlock" ? null : (
        <Modal
          closeIcon
          id="editBlockModal"
          className="ui dimmable dimmed scrolling modal editModal"
          size="tiny"
          open={modal === "editBlock"}
          closeOnDocumentClick
          onClose={hideModal}
        >
          <Modal.Header>{editable ? "Edit Block" : "View Block"}</Modal.Header>
          <Modal.Content>
            <Form size="small" className="login-form">
              <Form.Group widths="equal">
                <Form.Field>
                  <Form.Input
                    data-cy="blockName"
                    disabled={disabled}
                    required
                    id="newBlock"
                    label="Block Name"
                    placeholder="Block Name"
                    maxLength="30"
                    defaultValue={editBlock.name}
                    error={nameError !== ""}
                    onBlur={e => {
                      let newBlock = { ...editBlock };
                      newBlock.name = e.target.value;
                      setEditBlock(newBlock);
                    }}
                    onChange={() => setNameError("")}
                  />
                  {nameError === "" ? null : (
                    <Label pointing color="red">
                      {nameError}
                    </Label>
                  )}
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    data-cy="coeDropdown"
                    disabled={true}
                    label="COE"
                    error={coeError !== ""}
                    value={editBlock.coe.name}
                  />
                  {coeError === "" ? null : (
                    <Label pointing color="red">
                      {coeError}
                    </Label>
                  )}
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field
                  disabled={disabled}
                  required
                  error={dateError !== ""}
                >
                  <label>Start Date</label>
                  <DatePicker
                    data-cy="startDate"
                    id="startDate"
                    className={dateError !== "" ? "errorText" : null}
                    selected={moment(editBlock.startDate).toDate()}
                    onChange={date => {
                      let newBlock = { ...editBlock };
                      newBlock.startDate = date;
                      setEditBlock(newBlock);
                    }}
                    popperModifiers={{
                      preventOverflow: {
                        enabled: true,
                        escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                        boundariesElement: "viewport"
                      }
                    }}
                  />
                  {dateError === "" ? null : (
                    <Label pointing color="red">
                      {dateError}
                    </Label>
                  )}
                </Form.Field>
                <Form.Field
                  disabled={disabled}
                  required
                  error={dateError !== ""}
                >
                  <label>End Date</label>
                  <DatePicker
                    data-cy="endDate"
                    id="endDate"
                    className={dateError !== "" ? "errorText" : null}
                    selected={moment(editBlock.endDate).toDate()}
                    onChange={date => {
                      let newBlock = { ...editBlock };
                      newBlock.endDate = date;
                      setEditBlock(newBlock);
                    }}
                    popperModifiers={{
                      preventOverflow: {
                        enabled: true,
                        escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                        boundariesElement: "viewport"
                      }
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Modal.Content>
          {editable ? (
            <Modal.Actions>
              <Button
                icon
                positive
                inverted
                color="green"
                size="small"
                onClick={() => {
                  if (editable) {
                    handleSubmit(editBlock, "edit");
                  } else {
                    hideModal();
                  }
                }}
              >
                <Icon name="checkmark" />
                Save
              </Button>
            </Modal.Actions>
          ) : null}
        </Modal>
      )}
    </div>
  );
}

BlockTab.propTypes = {
  user: PropTypes.object
};

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(BlockTab);
