import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { Button, Form, Icon, Image, Label, Modal } from "semantic-ui-react";
import ImageUploader from "react-images-upload";
import _ from "lodash";
import "../../../src/styles/userTab.css";
import { Portrait_Placeholder } from "../../images";
import ModalBuilder from "./modalBuilder";

import { updateUserAccount } from "../../redux-store/actions/userActions";

function UserForm(props) {
  const [user, setUser] = useState({});
  const [errors, setErrors] = useState({});
  const [editable, setEditable] = useState(false);
  const [formType, setFormType] = useState("Add");
  const [projectList, setProjectList] = useState([]);
  const [COEList, setCOEList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [modal, setModal] = useState(null);
  const [image, setImage] = useState({});
  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    setEditable(parseInt(props.reduxUser.userRole.adminUsersPerm) > 1);
  }, []);

  useEffect(() => {
    setImage({});
    setErrors({});
    if (!props.open) {
      setFormType(editable ? (props.user.firstName ? "Edit" : "Add") : "View");
      getProjectList();
      getCOEList();
      getRoleList();
      getAvatar();
      if (props.user.id) setUser(props.user);
      else populateUser();
    }
  }, [props.user]);

  function populateUser() {
    setUser({
      firstName: "",
      lastName: "",
      email: "",
      maxHours: "",
      password: "",
      confirmPassword: "",
      coe: {},
      projects: [],
      userRole: {},
      active: true
    });
  }

  function getProjectList() {
    axios.get("/project/findProjectList").then(res => {
      const project = res.data.map(list => {
        return {
          key: list["id"],
          text: list["projectName"],
          value: list["id"],
          active: list["active"]
        };
      });
      setProjectList(project);
    });
  }

  function getCOEList() {
    axios.get("/COE/getCOEList/active").then((res, error) => {
      if (error) {
        console.log(error);
      } else {
        const COE = res.data.map(list => {
          return {
            text: list["name"],
            value: list["id"]
          };
        });
        setCOEList(COE);
      }
    });
  }

  function getRoleList() {
    axios.get("/role/getRoleList").then(res => {
      const Roles = res.data.map(list => {
        return {
          text: list["role"],
          value: list["id"]
        };
      });
      setRoleList(Roles);
    });
  }

  function getAvatar() {
    if (props.user.id)
      axios.post("/avatar/getAvatar", { id: props.user.id }).then(res => {
        setAvatar(res.data.image);
      });
    else setAvatar(null);
  }

  function confirmCOEChange(data) {
    setModal(
      <ModalBuilder
        title={`Changing a User's COE`}
        content={`Changing a user's COE will remove the user's schedules. Are you sure you want to delete all of this user's schedules?`}
        type="confirm"
        visible
        okFunction={() => {
          handleChange("coe", { id: data.value });
          setModal(null);
        }}
        closeFunction={() => {
          handleChange("coe", user.coe);
          setModal(null);
        }}
      />
    );
  }

  function validateForm() {
    let compiledErrors = {};
    if (!user.firstName || user.firstName === "" || /\s/g.test(user.firstName))
      compiledErrors["firstName"] = "Please enter a first name";

    if (!user.lastName || user.lastName === "" || /\s/g.test(user.lastName))
      compiledErrors["lastName"] = "Please enter a last name";

    if (!user.email || user.email === "" || /\s/g.test(user.email))
      compiledErrors["email"] = "Please enter an email address";
    else if (props.isUserInList(user))
      compiledErrors["email"] = "That email address is already in use";
    else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(user.email))
      compiledErrors["email"] = "The email address entered is not valid";

    if (!user.maxHours || user.maxHours === "")
      compiledErrors["maxHours"] = "Please enter planned hours";
    else if (isNaN(user.maxHours))
      compiledErrors["maxHours"] = "Please enter a number";
    else if (parseInt(user.maxHours) < 1)
      compiledErrors["maxHours"] = "Please enter a value greater than 0";
    else if (parseInt(user.maxHours) > 60)
      compiledErrors["maxHours"] = "Please enter no more than 60 hours";

    if (!props.user.id) {
      if (!user.coe.id) compiledErrors["coe"] = "Please select a COE";
      if (!user.userRole.id)
        compiledErrors["userRole"] = "Please select a Role";
    }

    if (Object.entries(compiledErrors).length > 0) setErrors(compiledErrors);
    else {
      if (user.id) saveUser();
      else createUser();
    }
  }

  function createUser() {
    axios.post("/user/createUser", user).then(createUserResponse => {
      if (image.newAvatar) updateAvatar(createUserResponse.data);
      else {
        props.updateListCallback();
        hideModal();
      }
    });
  }

  function saveUser() {
    axios.post("/user/updateUser", user).then(() => {
      axios.post("/user/updateUserProject", user).then(() => {
        if (image.newAvatar) updateAvatar(user);
        else {
          if (user.id === props.reduxUser.id)
            props.updateUserAccount(props.reduxUser.id);
          props.updateListCallback();
          hideModal();
        }
      });
    });
  }

  function updateAvatar(savedUser) {
    let url = `/avatar/${user.id ? "update" : "upload"}`;
    axios
      .post(url, { id: savedUser.id, image: image.newAvatar })
      .then(avatarResponse => {
        if (savedUser.id === props.reduxUser.id)
          props.updateUserAccount(props.reduxUser.id);
        props.updateListCallback();
        hideModal();
      });
  }

  function handleChange(id, data) {
    setUser({ ...user, [id]: data });
    setErrors(_.omit(errors, id));
  }

  function hideModal() {
    props.closeCallback({});
  }

  function getProjectOptionList() {
    // Add all active projects to option list
    let optionList = projectList.filter( proj => proj.active );

    // Add to list any inactive projects the user is currently assigned to
    let currentUserProjects = user.projects === undefined ? [] : user.projects;
    if (currentUserProjects.length) {
      let inactiveUserProjects = currentUserProjects.filter( proj => !proj.active );
      inactiveUserProjects.forEach( proj => {
        optionList.push(
          {
            key: proj["id"],
            text: proj["projectName"],
            value: proj["id"],
            active: proj["active"]
          }
        );
      })
    }

    return optionList;
  }

  return (
    <Fragment>
      <Modal
        open={props.open}
        closeOnDocumentClick
        onClose={hideModal}
        closeIcon
      >
        <Modal.Header content={`${formType} User`} />
        <Modal.Content>
          <Form size="small">
            <Form.Group widths="equal">
              <Form.Field>
                <Form.Input
                  id="firstName"
                  data-cy="fName"
                  disabled={!editable}
                  error={errors.firstName ? true : false}
                  required
                  label="First Name"
                  placeholder="First Name"
                  onInput={e => handleChange(e.target.id, e.target.value)}
                  value={user.firstName}
                  maxLength="20"
                />
                {errors.firstName ? (
                  <Label pointing color="red" content={errors.firstName} />
                ) : null}
              </Form.Field>
              <Form.Field>
                <Form.Input
                  data-cy="lName"
                  id="lastName"
                  disabled={!editable}
                  error={errors.lastName ? true : false}
                  required
                  label="Last Name"
                  placeholder="Last Name"
                  onInput={e => handleChange(e.target.id, e.target.value)}
                  value={user.lastName}
                  maxLength="30"
                />
                {errors.lastName ? (
                  <Label pointing color="red" content={errors.lastName} />
                ) : null}
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <Form.Input
                  error={errors.email ? true : false}
                  id="email"
                  data-cy="userEmail"
                  required
                  disabled={!editable}
                  label="Email"
                  placeholder="example@fenwaygroup.com"
                  value={user.email}
                  onInput={e => handleChange(e.target.id, e.target.value)}
                  maxLength="30"
                />
                {errors.email ? (
                  <Label pointing color="red" content={errors.email} />
                ) : null}
              </Form.Field>
              <Form.Field>
                <Form.Input
                  data-cy="maxHours"
                  id="maxHours"
                  required
                  disabled={!editable}
                  error={errors.maxHours ? true : false}
                  label="Planned Hours"
                  type="number"
                  placeholder="1 - 40 hours"
                  value={user.maxHours}
                  onInput={e =>
                    handleChange(
                      e.target.id,
                      Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 2)
                    )
                  }
                />
                {errors.maxHours ? (
                  <Label pointing color="red" content={errors.maxHours} />
                ) : null}
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <Form.Dropdown
                  data-cy="coeDropdown"
                  id="coe"
                  selection
                  label="COE"
                  required
                  error={errors.coe ? true : false}
                  placeholder="Please select a COE"
                  disabled={!editable}
                  selectOnBlur={false}
                  value={user.coe ? user.coe.id : null}
                  options={COEList}
                  onChange={(e, data) =>
                    props.user.coe && user.coe.name
                      ? confirmCOEChange(data)
                      : handleChange(data.id, { id: data.value })
                  }
                />
                {errors.coe ? (
                  <Label pointing color="red" content={errors.coe} />
                ) : null}
              </Form.Field>
              <Form.Field>
                <Form.Dropdown
                  data-cy="projDropdown"
                  id="projects"
                  selection
                  multiple
                  label="Project(s)"
                  placeholder="Please select project(s)"
                  error={errors.project ? true : false}
                  disabled={!editable}
                  selectOnBlur={false}
                  defaultValue={
                    user.projects
                      ? user.projects.map(p => {
                          return p.id;
                        })
                      : null
                  }
                  options={getProjectOptionList()}
                  onChange={(e, data) =>
                    handleChange(
                      data.id,
                      data.value.map(proj => {
                        return { id: proj };
                      })
                    )
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <Form.Dropdown
                  selection
                  placeholder="Please select a Role"
                  id="userRole"
                  label="Role"
                  error={errors.userRole ? true : false}
                  data-cy="roleDropdown"
                  required
                  disabled={user.isLoggedIn || !editable}
                  selectOnBlur={false}
                  defaultValue={user.userRole ? user.userRole.id : null}
                  options={roleList}
                  onChange={(e, data) =>
                    handleChange(data.id, { id: data.value })
                  }
                />
                {errors.userRole ? (
                  <Label pointing color="red" content={errors.userRole} />
                ) : null}
              </Form.Field>
              <Form.Field style={{ paddingTop: "25px", paddingLeft: "20px" }}>
                <Form.Checkbox
                  label="Active"
                  id="active"
                  data-cy="activeCheckbox"
                  disabled={user.isLoggedIn || !editable}
                  defaultChecked={user.active}
                  onChange={(e, data) =>
                    handleChange(e.target.id, data.checked)
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field style={{ alignSelf: "center" }}>
                <div className={!editable ? "disabled field" : ""}>
                  <label>Avatar</label>
                </div>
                <Image
                  id="avatar"
                  data-cy="userAvatar"
                  size="tiny"
                  src={
                    image.newAvatar
                      ? image.newAvatar
                      : avatar
                      ? avatar
                      : Portrait_Placeholder
                  }
                  floated="left"
                  rounded
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            data-cy="createOkBtn"
            icon
            inverted
            color="green"
            size="small"
            onClick={() => {
              editable ? validateForm() : hideModal();
            }}
          >
            <Icon name="checkmark" />
            {editable ? (user.id ? "Save" : "Create") : "Ok"}
          </Button>
        </Modal.Actions>
      </Modal>
      {modal}
    </Fragment>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    updateUserAccount: userId => {
      dispatch(updateUserAccount(userId));
    }
  };
};

const mapStateToProps = state => {
  return {
    reduxUser: state.user
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserForm);
