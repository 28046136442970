import React, { useState, useEffect } from "react";
import moment from "moment";
import { List } from "semantic-ui-react";
import axios from "axios";
import * as _ from "lodash";
import "../../../styles/homePage.css";
import "../../../styles/eventSchedule.css";
import { connect } from "react-redux";

function EventSchedule(props) {
  const [eventList, setEventList] = useState([]);
  // Effectively Component Did Mount
  useEffect(() => {
    getUserProjectList(props);
  }, []);

  function getUserProjectList(props) {
    //returns an array of client IDs for logged in user
    let clientList = [];
    for (let i = 0; i < props.user.projects.length; i++) {
      clientList.push(props.user.projects[i].client.id);
    }
    clientList = _.uniq(clientList)
    getEventList(clientList);
  }

  function getEventList(clientList) {
    const tempList = [];
    const currDay = moment(); // gets current day
    const req = {
      id: clientList //array of client IDs
    };
    axios.post("/holiday/getHolidayByClient", req).then(res => {
      for (let i = 0; i < res.data.length; i++) {
        let client = res.data[i][0];
        client.holidays.map(holiday => {
          const difference = moment(holiday.startDate).diff(currDay, "days"); // days until event
          if (difference >= 0 && difference < 14) {
            //pushes attributes to the array if within 14 days
            tempList.push({
              clientName: client.clientName,
              id: holiday.id,
              name: holiday.name,
              startDate: moment(holiday.startDate).format("MMMM Do, YYYY")
            });
          }
        });
      }
      setEventList(tempList); //Events to be rendered to DOM
    });
  }

  return (
    <div>
      <h2 className="title" data-cy="sectionHeader">
        Upcoming Events
      </h2>
      <List>
        {eventList.length > 0 ? (
          eventList.map(({ clientName, id, name, startDate }) => {
            return (
              <List.Item key={id}>
                <List.Content>
                  <h3>
                    {clientName} - {name}
                  </h3>
                  {startDate}
                </List.Content>
              </List.Item>
            );
          })
        ) : (
          <div>There are no upcoming events</div>
        )}
      </List>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};
export default connect(mapStateToProps)(EventSchedule);
