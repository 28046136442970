import React from "react";
import { Button, Icon } from "semantic-ui-react";

const PasteButton = props => {
  return (
    <div
      className={
        props.locked && props.pasteShow ? "pasteButton" : "noDisplayRow"
      }
    >
      <Button
        inverted
        color="green"
        onClick={() => props.pasteSchedule(props.date)}
      >
        <Icon name="paste" />
        Paste
      </Button>
    </div>
  );
};

export default PasteButton;
