import axios from "axios";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Input, Label, Popup } from "semantic-ui-react";
import "../../styles/adminTabs.css";

class BulkTab extends Component {
  constructor(props) {
    super(props);

    this.state = { errorMessage: null, confirmMessage: null, prevFile: null };

    this.handleUploadFile = this.handleUploadFile.bind(this);
  }

  handleUploadFile = ev => {
    ev.preventDefault();
    if (
      this.uploadInput.files.length !== 0 &&
      this.uploadInput.files[0] !== this.state.prevFile
    ) {
      let file = this.uploadInput.files[0];

      let formData = new FormData();
      formData.append("file", file);
      formData.set("sheetName", this.sheetName.value);
      axios
        .post("/bulk/update", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(() => {
          this.setState({
            errorMessage: null,
            confirmMessage: "Update was successful."
          });
        })
        .catch(() => {
          this.setState({
            errorMessage:
              "There was an issue with the file uploaded. Please try again or submit a trouble ticket.",
            confirmMessage: null,
            prevFile: file
          });
        });
    } else {
      this.setState({
        errorMessage:
          "There was an issue with the file uploaded. Please try again or submit a trouble ticket."
      });
    }
  };

  render() {
    return (
      <div>
        <div>
          <h2 className="title">Bulk Update Data</h2>
        </div>
        <br />
        <div>
          <div>
            <Popup
              trigger={
                <Input
                  children={
                    <input
                      ref={ref => {
                        this.uploadInput = ref;
                      }}
                      type="file"
                      name="file"
                      accept=".xlsx"
                    />
                  }
                />
              }
              content={
                <p>The expected file is the COE directory Excel spreedsheet.</p>
              }
              position={"right center"}
            />
          </div>
          <div>
            <Popup
              trigger={
                <Input
                  children={
                    <input
                      style={{ width: "307px", marginTop: "10px" }}
                      ref={ref => {
                        this.sheetName = ref;
                      }}
                      type="text"
                      placeholder="Name of Sheet to Process"
                    />
                  }
                />
              }
              content={
                <p>
                  This is used to specify the sheet name of the Excel
                  spreedsheet.
                </p>
              }
              position="right center"
            />
          </div>
          <br />
          <div style={{ display: "flex" }}>
            <Button inverted color="green" onClick={this.handleUploadFile}>
              Upload
            </Button>
            {this.state.confirmMessage === null ? (
              <div>
                {this.state.errorMessage === null ? null : (
                  <Label pointing color="red" pointing="left">
                    {this.state.errorMessage}
                  </Label>
                )}
              </div>
            ) : (
              <div>
                <Label pointing color="green" pointing="left">
                  {this.state.confirmMessage}
                </Label>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

BulkTab.propTypes = {
  user: PropTypes.object
};

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(BulkTab);
