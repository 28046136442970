import TimePicker from "rc-time-picker";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { Dropdown, Grid, Label, Popup, Input } from "semantic-ui-react";
import "../../styles/homePage.css";
import "../../styles/meetingPage.css";

function useCheckErrorListEffect(fn, flags) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      fn();
    } else {
      didMountRef.current = true;
    }
  }, [...flags]);
}

function MeetingTime(props) {
  const [newSelection, setNewSelection] = useState(false);
  const [fullProjectList, setFullProjectList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [projectIdList, setProjectIdList] = useState([0]);
  const [fullCoeList, setFullCoeList] = useState([]);
  const [coeList, setCoeList] = useState([]);
  const [coeIdList, setCoeIdList] = useState([0]);
  const [projectCOEError, setProjectCOEError] = useState({
    b: false,
    message: ""
  });
  const [userList, setUserList] = useState([]);
  const [fullUserList, setFullUserList] = useState([]);
  const [userIdList, setUserIdList] = useState([]);
  const [minAttendees, setMinAttendees] = useState({
    value: 2
  });
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dateError, setDateError] = useState({
    b: false,
    message: ""
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [optimalMeeting, setOptimalMeeting] = useState(undefined);
  const weekdays = ["Friday", "Saturday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const [frequencyList] = useState([
    {
      key: 0,
      value: "Once",
      text: "Once"
    },
    {
      key: 1,
      value: "Recurring",
      text: "Recurring"
    }
  ]);
  const [dayOfWeekList] = useState([
    {
      key: 0,
      value: "Saturday",
      text: "Saturday"
    },
    {
      key: 1,
      value: "Sunday",
      text: "Sunday"
    },
    {
      key: 2,
      value: "Monday",
      text: "Monday"
    },
    {
      key: 3,
      value: "Tuesday",
      text: "Tuesday"
    },
    {
      key: 4,
      value: "Wednesday",
      text: "Wednesday"
    },
    {
      key: 5,
      value: "Thursday",
      text: "Thursday"
    },
    {
      key: 6,
      value: "Friday",
      text: "Friday"
    }
  ]);
  const [durationValue, setDurationValue] = useState(15);
  const [durationError, setDurationError] = useState({
    b: false,
    message: ""
  });
  const [dayOfWeekError, setDayOfWeekError] = useState({
    b: false,
    message: ""
  });
  const [frequencyError, setFrequencyError] = useState({
    b: false,
    message: ""
  });
  let [dayOfWeekPlaceholder, setDayOfWeekPlaceholder] = useState({
    text: "Select a Day",
    dayOfWeek: []
  });
  const [frequencyPlaceholder, setFrequencyPlaceholder] = useState({
    text: "Once",
    frequency: "Once"
  });
  const [timeRange, setTimeRange] = useState({
    start: null, // uses null because of how the antd time picker
    end: null
  });
  const [timeError, setTimeError] = useState({
    b: false,
    message: ""
  });

  useEffect(() => {
    getProjectList();
    getCOEList();
    getUserList();
  }, []);

  useCheckErrorListEffect(checkErrorList, [
    projectIdList,
    coeIdList,
    userIdList,
    selectedDate,
    timeRange,
    dayOfWeekPlaceholder,
    frequencyPlaceholder,
    minAttendees,
    durationValue
  ]);

  function getProjectList() {
    axios
      .get("/project/findProjectList/active")
      .then(res => {
        const newProjectList = [{ key: 0, text: "All Projects", value: 0 }];

        _.map(res.data, option =>
          newProjectList.push({
            key: option.id,
            text: option.projectName,
            value: option.id
          })
        );

        setProjectList(newProjectList);
        setFullProjectList(newProjectList);
      })
      .catch(err => {
        console.log(err);
      });
  }

  function getCOEList() {
    axios
      .get("/coe/getCOEList/active")
      .then(res => {
        const newCOEList = [{ key: 0, text: "All COEs", value: 0 }];
        _.map(res.data, option =>
          newCOEList.push({
            key: option.id,
            text: option.name,
            value: option.id
          })
        );

        setCoeList(newCOEList);
        setFullCoeList(newCOEList);
      })
      .catch(err => {
        console.log(err);
      });
  }

  function getUserList() {
    axios
      .get("/User/findAllUsers/active")
      .then(res => {
        const newUserList = [
          { key: 0, text: "All Individuals Required", value: 0 }
        ];

        _.map(res.data, option =>
          newUserList.push({
            key: option.id,
            text: option.firstName + " " + option.lastName,
            value: option.id,
            coe: option.coe,
            projects: option.projects
          })
        );

        setUserList(newUserList);
        setFullUserList(newUserList);
      })
      .catch(err => {
        console.log(err);
      });
  }

  function allOptionHandler(optionList) {
    // this will return data in the format for the dropDown Handler optionList
    if (optionList.value[0] == 0 && optionList.value.length != 1) {
      optionList.value = optionList.value.slice(1);
    } else if (optionList.value[optionList.value.length - 1] == 0) {
      optionList.value = [0];
    }
    return optionList;
  }

  function dropDownHandler(optionList, stateTarget) {
    switch (stateTarget) {
      case "projectIdList":
        setProjectIdList(optionList.value);
        break;
      case "coeIdList":
        setCoeIdList(optionList.value);
        break;
      case "userIdList":
        setUserIdList(optionList.value);
        break;
    }
  }

  function updateDayOfWeek(dayOfWeek) {
    if (dayOfWeek.value != ""){
      setSelectedDate(undefined);
    }
    setDayOfWeekPlaceholder({
      text: "Select a Day",
      dayOfWeek: dayOfWeek.value
    });
  }
  
  function updateFrequency(frequency, name) {
    if (frequency.value == "Recurring"){
      setSelectedDate(undefined);
    }
    setFrequencyPlaceholder({
      text: name,
      frequency: frequency.value
    });
  }
  
  function updateMinAttendees(minAttendeesChangeEvent) {
    setMinAttendees({
      value: minAttendeesChangeEvent.target.value
    });
  }
  
  function updateDate(date) {
    setDayOfWeekPlaceholder({
      text: "Select a Day",
      dayOfWeek: []
    });
    
    setFrequencyPlaceholder({
      text: 'Once',
      frequency: 'Once'
    });
    setSelectedDate(date);


  }

  function updateStartTime(time) {
    if (time !== null) {
      const newTime = { ...timeRange };

      let updatedTime = moment(time); // create a new object for time
      updatedTime.set({'year': 2000, 'month': 0, 'date': (time.day() < 6 ? time.day()+2 : 1)});
      newTime.start = updatedTime;
  
      setTimeRange(newTime);
    }
  }

  function updateEndTime(time) {
    if (time !== null) {
      const newTime = { ...timeRange };

      let updatedTime = moment(time); // create a new object for time
      updatedTime.set({'year': 2000, 'month': 0, 'date': (time.day() < 6 ? time.day()+2 : 1)});
      if (updatedTime.hours() == 0 && updatedTime.minutes() == 0) {
        updatedTime = updatedTime.add(1, "days");
      }
      newTime.end = updatedTime;
      setTimeRange(newTime);
    }
}

  function isPastDay(date) {
    // Disables selecting past days on the calendar
    return (
      moment()
        .subtract(1, "day")
        .isBefore(date)
    );
  }

  function checkErrorList() {
    // resets all error messages and then displays any error messages

    setOptimalMeeting(undefined);
    setErrorMessage("");
    setProjectCOEError({ b: false });
    setDurationError({ b: false });
    setDayOfWeekError({ b: false });
    setFrequencyError({ b: false });
    setDateError({ b: false });
    setTimeError({ b: false });
    
    let requiredFieldMissing = false;
    
    if (projectIdList.length === 0 && coeIdList.length === 0) {
      setProjectCOEError({
        b: true,
        message: "Select a COE or Project"
      });
      requiredFieldMissing = true;
    }
    if (Number.isNaN(durationValue)) {
      setDurationError({
        b: true,
        message: "Invalid entry"
      });
      requiredFieldMissing = true;
    } else if (durationValue < 0) {
      setDurationError({
        b: true,
        message: "Cannot have negative duration"
      });
      requiredFieldMissing = true;
    } else if (durationValue > 720) {
      setDurationError({
        b: true,
        message: "Time entered is too long"
      });
      requiredFieldMissing = true;
    }
    if (((selectedDate === undefined || selectedDate === null) && dayOfWeekPlaceholder.dayOfWeek.length === 0) || ((selectedDate !== undefined && selectedDate !== null) && dayOfWeekPlaceholder.dayOfWeek.length !== 0)) {
      setDateError({
        b: true,
        message: "Select either a Date or Day of the Week"
      });
      setDayOfWeekError({
        b: true,
        message: "Select either a Date or Day of the Week"
      });
      requiredFieldMissing = true;
    }
    
    if ((timeRange.start === null) !== (timeRange.end === null)) {
      setTimeError({
        b: true,
        message: "Please provide a time range"
      });
      requiredFieldMissing = true; // can't send an incomplete backend call
    } else if (timeRange.start === null && timeRange.end === null) {
      // do nothing
    } else if (timeRange.start.isSameOrAfter(timeRange.end)) {
      setTimeError({
        b: true,
        message: "Please ensure the start time is before the end time"
      });
      requiredFieldMissing = true; // can't send a bad call to the backend
    }
    
    // at this point all errors should have been accounted for
    if (requiredFieldMissing === false) {
      getOptimalMeeting();
    }
  }

  function getOptimalMeeting() {
    let newProjectList = projectIdList;
    // if the all option was selected pass an empty array which will be handled on the backend
    if (projectIdList == 0) {
      newProjectList = [];
    }
    // if the all option was selected pass an empty array which will be handled on the backend
    let newCOEList = coeIdList;
    if (coeIdList == 0) {
      newCOEList = [];
    }

    let newUserList = userIdList;
    if (userIdList == 0 && userIdList.length != 0) {
      newUserList = [];
      _.map(userList, user => newUserList.push(user.value));
    } else if (userIdList.length == 0) {
      newUserList = [];
    }

    let dayOfWeekLocal = [...dayOfWeekPlaceholder.dayOfWeek]
    if (dayOfWeekLocal.length === 0) {
      dayOfWeekLocal.push(moment(selectedDate).format('dddd'));  
    }

    // Create the array of time slots we want to find a meeting
    let timeRangeArray = [];
    for (var day = 0; day<7; day++) {

      let timeRangeLocal = { start: moment(), end: moment() };
      
      // set the default of each day to start at midnight
      timeRangeLocal.start.set({'year': 2000, 'month': 0, 'date': (day + 1), 'hour': 0, 'minute': 0, "second": 0});
      // adjust start time if this is a selected day
      if (timeRange.start !== null && dayOfWeekLocal.includes(timeRangeLocal.start.format('dddd'))) {
        timeRangeLocal.start.set({'hour': timeRange.start.hour(), 'minute': timeRange.start.minute(), "second": timeRange.start.second()});
      }

      // set the default of each day to end at midnight same as start (no range)
      timeRangeLocal.end.set({'year': 2000, 'month': 0, 'date': (day + 1), 'hour': 0, 'minute': 0, "second": 0});
      // adjust end if we are including this day
      if (dayOfWeekLocal.includes(timeRangeLocal.start.format('dddd'))) {
        // use provided end time if available
        if (timeRange.end !== null) {
          timeRangeLocal.end.set({'hour': timeRange.end.hour(), 'minute': timeRange.end.minute(), "second": timeRange.end.second()});
          if (timeRange.end.hour() === 0 && timeRange.end.minute() === 0) {
            timeRangeLocal.end.set({'date': (day + 2)});
          }
        } else { // use midnight of next day if no provided end time
          timeRangeLocal.end.set({'date': (day + 2)});
        }
      }
      // Add this day to the request array
      timeRangeArray.push(timeRangeLocal);
    }
    const req = {
      projectList: newProjectList,
      coeList: newCOEList,
      date: selectedDate,
      dayOfWeek: dayOfWeekLocal,
      frequency: frequencyPlaceholder.frequency,
      minAttendees: minAttendees.value,
      duration: durationValue,
      requiredUserList: newUserList,
      timeRange: timeRangeArray
    };
    axios
    .post("/meetings/getMeetingScheduleList", req)
    .then(res => {
        if (res.status === 200 && typeof res.data === "string") {
          setErrorMessage(res.data);
        } else {
          let fullMeetingList = [];
          if (frequencyPlaceholder.frequency == "Once"){
            let weekdays = ["Saturday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            for (let dayNumber = 0; dayNumber < 7; dayNumber++) {
              res.data[dayNumber].forEach(meetingTimeBlock => {
                fullMeetingList.push({
                  "startTime" : moment(meetingTimeBlock.startTime).local().format("LT"), 
                  "endTime" : moment(meetingTimeBlock.endTime).local().format("LT"), 
                  "attendees" : meetingTimeBlock.attendees,
                  "day" : weekdays[moment(meetingTimeBlock.startTime).format("D")-1]
                });
              });
            }
          }
          else if (frequencyPlaceholder.frequency == "Recurring"){
            // Group data by meeting time; each meeting time should have an attribute (attendees) which contains an array of subarrays. Each subarray contains an array of the users which can attend the meeting on that day of the week
            // filtering for min attendees is done on the backend
            let day;
            res.data.forEach(meetingTimeBlock => {
              day = dayOfWeekPlaceholder.dayOfWeek.length == 1 ? dayOfWeekPlaceholder.dayOfWeek[0] : "";
              fullMeetingList.push({
                "startTime" : moment(meetingTimeBlock.startTime).local().format("LT"), 
                "endTime" : moment(meetingTimeBlock.endTime).local().format("LT"), 
                "attendees" : meetingTimeBlock.attendees,
                "day" : day
              });
            });
          }
          setOptimalMeeting(fullMeetingList);
        }
      })
      .catch(err => {
        console.log(err);

        setErrorMessage("Please retry the selected meeting time");
      });
  }

  return (
    <div>
      <Grid id="meetingGrid" centered stackable container columns={4}>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h4 className="meetingPageRequired">Project</h4>
            <Dropdown
              label="Select a Project"
              placeholder="Select a Project"
              id="meetingProject"
              className={
                projectCOEError.b
                  ? ".ui.dropdown meetingDropdownSize errorBorder"
                  : ".ui.dropdown meetingDropdownSize"
              }
              multiple
              search
              scrolling
              selection
              floating
              value={projectIdList}
              options={projectList}
              onChange={(e, data) => {
                dropDownHandler(allOptionHandler(data), "projectIdList");
                setNewSelection(true);
              }}
            />
            {projectCOEError.b ? (
              <Label pointing color="red">
                {projectCOEError.message}
              </Label>
            ) : null}
          </Grid.Column>
          <Grid.Column textAlign="center">
            <h4 className="meetingPageRequired">COE</h4>
            <Dropdown
              label="Select a COE"
              placeholder="Select a COE"
              id="meetingCOE"
              className={
                projectCOEError.b
                  ? ".ui.dropdown meetingDropdownSize errorBorder"
                  : ".ui.dropdown meetingDropdownSize"
              }
              multiple
              search
              scrolling
              floating
              selection
              value={coeIdList}
              options={coeList}
              onChange={(e, data) => {
                dropDownHandler(allOptionHandler(data), "coeIdList");
                setNewSelection(true);
              }}
            />
            {projectCOEError.b ? (
              <Label pointing color="red">
                {projectCOEError.message}
              </Label>
            ) : null}
          </Grid.Column>
          <Grid.Column textAlign="center">
            <h4 className="meetingPageRequired">Date</h4>
            <DatePicker
              minDate={moment()}
              showDisabledMonthNavigation
              className={
                dateError.b
                  ? ".ui.dropdown meetingDropdownSize errorBorder"
                  : ".ui.dropdown meetingDropdownSize"
              }
              id="datePicker"
              placeholderText="Select a Date"
              selected={selectedDate}
              onSelect={date => updateDate(date)}
              filterDate={isPastDay}
            />
            {dateError.b ? (
              <Label pointing color="red">
                {dateError.message}
              </Label>
            ) : null}            
          </Grid.Column>
          <Grid.Column textAlign="center">
          <h4 className="meetingPageRequired">Day of the Week</h4>
            <Dropdown
              id="dayOfWeekDropdown"
              className={
                dayOfWeekError.b
                  ? ".ui.dropdown meetingDropdownSize errorBorder"
                  : ".ui.dropdown meetingDropdownSize"
              }
              multiple
              clearable
              placeholder={dayOfWeekPlaceholder.text}
              selection
              options={dayOfWeekList}
              onChange={(e, data) => {
                updateDayOfWeek(data);
              }}
              value={dayOfWeekPlaceholder.dayOfWeek}
            />
            {dayOfWeekError.b ? (
              <Label pointing color="red">
                {dayOfWeekError.message}
              </Label>
            ) : null}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign="center">
          <h4 className="meetingPageRequired">Frequency</h4>
            <Dropdown
              id="frequencyDropdown"
              className={
                frequencyError.b
                  ? ".ui.dropdown meetingDropdownSize errorBorder"
                  : ".ui.dropdown meetingDropdownSize"
              }
              text={frequencyPlaceholder.text}
              options={frequencyList}
              onChange={(e, data) => {
                updateFrequency(data);
              }}
            />
          </Grid.Column>
          <Grid.Column textAlign="center">
          <h4 className="meetingPageRequired">Minimum # Attendees</h4>
          <Input
            children={
              <input
                type="number"
                name="minAttendees"
                placeholder={minAttendees.minAttendees}
                value={minAttendees.value}
                min={2}
                onChange={(event) => {
                  updateMinAttendees(event);
                }}
                />
            }
          />
          </Grid.Column>
          <Grid.Column textAlign="center">
            <h4 className="meetingPageRequired">Duration (in minutes)</h4>
            <Input
              children={
                <input
                  type="number"
                  name="minAttendees"
                  placeholder="Duration"
                  defaultValue={15}
                  min={5}
                  className={".ui.dropdown" + 
                  (durationError.b
                    ? " errorBorder"
                    : "")}
                onChange={e => setDurationValue(Number.parseInt(e.target.value))}
                  />
              }
            />
            {durationError.b ? (
                <Label pointing color="red">
                  {durationError.message}
                </Label>
              ) : null}
          </Grid.Column>
          <Grid.Column textAlign="center">
            <h4>Required Users</h4>
            <Dropdown
              placeholder="Required Users"
              id="meetingUserDropdown"
              className=".ui.dropdown meetingDropdownSize"
              multiple
              search
              scrolling
              selection
              floating
              value={userIdList}
              options={userList}
              onChange={(e, data) => {
                dropDownHandler(allOptionHandler(data), "userIdList");
              }}
            />
          </Grid.Column>
          <Grid.Column textAlign="center">
            <h4>Select a Time Range</h4>
            {/* <br /> */}
            <TimePicker
              id="meetingStart"
              className="meetingDropdownSize"
              use12Hours
              placeholder="Start"
              onChange={updateStartTime}
              format="h:mm A"
              showSecond={false}
              minuteStep={10}
              defaultOpenValue={moment("08:00:00", "HH:mm Z")}
            />
            <TimePicker
              id="meetingEnd"
              className="meetingDropdownSize"
              use12Hours
              placeholder="End"
              onChange={updateEndTime}
              format="h:mm A"
              showSecond={false}
              minuteStep={10}
              defaultOpenValue={moment("00:00:00", "HH:mm Z")}
            />
            {timeError.b ? (
              <Label pointing color="red">
                {timeError.message}
              </Label>
            ) : null}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <hr />
      <div className="meetingResultLayout">
        <div>
          <h2 className="title">Available Meeting Times</h2>
          {errorMessage !== "" ?
          <p className="errorMessage" id="meetingError">
            {errorMessage}
          </p>
          : 
          <ul className="meetingList">
            {_.map(optimalMeeting, option => (
              <Popup
                key={optimalMeeting.indexOf(option)}
                trigger={
                  <li key={optimalMeeting.indexOf(option)} className="list">
                    {(frequencyPlaceholder.frequency == "Once" || dayOfWeekPlaceholder.dayOfWeek.length == 1 ) ? option.day : "" } {option.startTime} - {option.endTime}
                  </li>
                }
                header={frequencyPlaceholder.frequency == "Recurring" ? "Attendees" : "Attendees (" + option.attendees.length + ")"}
                content={
                  <Grid className="fullWeekAttendeesPopup" centered stackable container columns={7}>
                    <Grid.Row>
                    {frequencyPlaceholder.frequency == "Recurring" ? 
                      _.map(option.attendees, day => (
                        day.length>0 ?
                          <Grid.Column key={option.attendees.indexOf(day)} className="dailyAttendees" textAlign="center">
                            <h4 style={{marginBottom: 10 + "px"}}>{weekdays[option.attendees.indexOf(day) + 1] + "(" + day.length + ")"}</h4>
                            {_.map(day, user => (
                              <p style={{marginBottom: 2 + "px"}} key={user.id}>{user.firstName + " " + user.lastName}</p>
                            ))}
                          </Grid.Column>
                        : ""
                          
                      ))
                      :
                      <Grid.Column className="dailyAttendees" textAlign="center">
                        {_.map(option.attendees, user => (
                          <p style={{marginBottom: 2 + "px"}} key={user.id}>{user.firstName + " " + user.lastName}</p>
                        ))}
                      </Grid.Column>
                      }
                    </Grid.Row>
                  </Grid>
                }
                position="bottom center"
                className="meetingPopup scrolling"
                hoverable
              />
            ))}
          </ul>
          }
        </div>
      </div>
    </div>
  );
}
export default MeetingTime;
