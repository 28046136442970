import moment from "moment";
import axios from "axios";

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { List } from "semantic-ui-react";
import "../../../styles/homePage.css";
import "../../../styles/myschedule.css";
import PropTypes from "prop-types";
import { getSchedulePopupText, isTimeOnDifferentDay } from '../../utilities/timeRangeDisplay'

function MySchedule(props) {
  const [currentDayOfWeek, setCurrentDayOfWeek] = useState([]);
  const [nextDayOfWeek, setNextDayOfWeek] = useState([]);
  const [schedule, setSchedule] = useState({ today: [], tomorrow: [] });

  useEffect(() => {
    checkDay();
  }, []);

  function checkDay() {
    // returns today's day of the week if called with 0 or default value
    const days = [
      "Sunday", // index 0
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday" // index 6
    ];
    // If today is Saturday, set nextWorkDay to Sunday
    let today = new Date().getDay();
    const tomorrow = today + 1;
    const nextWorkDay = tomorrow > 6 ? days[0] : days[tomorrow];
    setCurrentDayOfWeek(days[today]);
    setNextDayOfWeek(nextWorkDay);
    getHomePageSchedule(days[today], nextWorkDay);
  }

  function getHomePageSchedule(today, tomorrow) {
    const req = {
      id: props.user.id,
      today: today,
      tomorrow: tomorrow,
      blockId: props.user.block.currentId
    };
    axios.post("/schedule/findScheduleByDay", req).then(response => {
      setSchedule({
        ...schedule,
        today: response.data[0],
        tomorrow: response.data[1]
      });
    });
  }

  function mapScheduleFromState(stateScheduleList) {
    if (stateScheduleList.length === 0) {
      // if there is no schedule a link to schedule creation will display
      return props.user.userRole.schedulePerm > 1 ? (
        <List.Item>
          <List.Content>
            <Link to="/schedule">Not Scheduled for Work</Link>
          </List.Content>
        </List.Item>
      ) : (
        <List.Item>
          <List.Content>Not Scheduled for Work</List.Content>
        </List.Item>
      );
    } else {
      // Convert to first week of Jan 2000
      let weekdays = ["Saturday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
      const currentTimeInt = moment().local();
      const currentTime2000 = moment(currentTimeInt).set({'year': 2000, 'month': 0, 'date': (weekdays.indexOf(currentTimeInt.format('dddd')))+1 });
      const list = stateScheduleList.map((schedule, index) => {
        const endTimeInt = schedule.endTime;
        if (
          // compares current time to end time to strike through
          schedule.day === currentDayOfWeek &&
          moment(endTimeInt).isBefore(
            moment(currentTime2000)
          )
        ) {
          return (
            <List.Item key={index} className="strikethrough">
              <List.Content>
                <span style={ isTimeOnDifferentDay(schedule, "start") ? { fontWeight: 'bold' } : { fontWeight: 'normal' } } >
                  {moment(schedule.startTime).local().format("LT")} -
                </span>
                  {/* This is there to add a single space between the 2 different dates */}
                <span style={ isTimeOnDifferentDay(schedule, "end") ? { fontWeight: 'bold' } : { fontWeight: 'normal' } } >
                  {moment(schedule.endTime).local().format("LT")}
                </span>
              </List.Content>
            </List.Item>
          );
        } else {
          return (
            <List.Item key={index}>
              <List.Content>
                <span style={ isTimeOnDifferentDay(schedule, "start") ? { fontWeight: 'bold' } : { fontWeight: 'normal' } } >
                  {moment(schedule.startTime).local().format("LT")} -
                </span>
                {/* This is there to add a single space between the 2 different dates */}
                <span style={ isTimeOnDifferentDay(schedule, "end") ? { fontWeight: 'bold' } : { fontWeight: 'normal' } } >
                {moment(schedule.endTime).local().format("LT")}
                </span>
              </List.Content>
            </List.Item>
          );
        }
      });
      return list;
    }
  }
  return (
    <div>
      <div>
        <h2 className="title">
          {props.user.firstName}
          's Schedule
        </h2>
        <h3 style={{ marginBottom: 0 }}>{currentDayOfWeek}</h3>
        <List style={{ margin: 0}}>
          {mapScheduleFromState(schedule.today)}
        </List>
        <h3 style={{ marginBottom: 0 }}>{nextDayOfWeek}</h3>
        <List style={{ margin: 0 }}>
          {mapScheduleFromState(schedule.tomorrow)}
        </List>
      </div>
    </div>
  );
}

MySchedule.propTypes = {
  user: PropTypes.object
};

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(
  mapStateToProps,
  null
)(MySchedule);
