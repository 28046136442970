import React, { useState, useEffect } from "react";
import "../styles/techLeadPage.css";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ApprovalSection from "../components/techPage/approvalSection.jsx";
import TeamSection from "../components/techPage/teamSection.jsx";
import { Dropdown } from "semantic-ui-react";
import _ from "lodash";
import axios from "axios";

const allProj = { id: -1, projectName: "All Projects" };

function TechLeadContainer(props) {
  const [selectedProject, setSelectedProject] = useState(allProj);
  const [projectList, setProjectList] = useState([]);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    if (props.user.userRole.id !== 2 && props.user.userRole.id !== 3) {
      props.history.push("/home");
    } else {
      async function fetchData() {
        axios.post('/project/findTechLeadsProjects', { id: [props.user.id] }).then((res) => {
          let projList = _.orderBy(
            _.map(res.data, project => {
              return {
                id: project.id,
                projectName: `${project.client.clientName} - ${project.projectName}`
              };
            }),
            ["projectName"],
            ["asc"]
          );
          projList.unshift(allProj);
          setProjectList(projList);

        })
      }
      fetchData()
    }
  }, []);

  useEffect(()=>{
    getProjectUserList()
  },[projectList])

  useEffect(() => {
    getProjectUserList();
  }, [selectedProject]);

  const getProjectUserList = () => {
    let idList = [];
    if (selectedProject.id >= 0) {
      idList = [selectedProject.id];
    } else {
      idList = _.map(projectList, proj => {
        return proj.id;
      });
    }

    axios
      .post("/project/getTechLeadUsers/active", {
        projectIdList: idList
      })
      .then(res => {
        setUserList(
          _.filter(res.data, user => {
            return user.id !== props.user.id;
          })
        );
      });
  };

  return (
    <div>
      <h2 className="title">Tech Lead Page</h2>
      <div style={{ textAlign: "center" }}>
        <h4 style={{ marginBottom: "0px" }}>Project</h4>
        <Dropdown
          id="techLeadProjectDropdown"
          className=".ui.dropdown meetingDropdownSize"
          text={selectedProject.projectName}
          scrolling
          data-cy="projDrop"
        >
          <Dropdown.Menu>
            {_.map(projectList, project => (
              <Dropdown.Item
                key={project.id}
                text={project.projectName}
                onClick={() => {
                  setSelectedProject(project);
                }}
              />
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="techLeadLayout">
        <ApprovalSection userList={userList} />
        <TeamSection
          getProjectUserList={getProjectUserList}
          userList={userList}
          setUserList={setUserList}
          disabledUser={null}
        />
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default withRouter(connect(mapStateToProps)(TechLeadContainer));
