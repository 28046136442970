import axios from "axios";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import "../../styles/accountDropDown.css";
import { AvatarComponent } from "../utilities/avatarComponent";

function AccountDropDown(props) {
  //State variables
  const [userAvatar, setUserAvatar] = useState(null);

  useEffect(() => {
    getUserAvatar();
  }, []);

  function trigger() {
    return (
      <span key="AccountSpan">
        <AvatarComponent image={userAvatar} />
        <span key="Account" id="usernameLogo">
          {props.user.firstName + " " + props.user.lastName}
        </span>
      </span>
    );
  }

  function getUserAvatar() {
    const req = {
      id: props.user.id
    };
    axios.post("/avatar/getAvatar", req).then(res => {
      setUserAvatar(res.data.image);
    });
  }

  function menuOptions() {
    return [
      <Dropdown.Item
        id="dropDownItem"
        to="/account"
        text="My Account"
        as={Link}
        key="1"
      />
    ];
  }

  return (
    <Dropdown
      id="userDropDown"
      data-cy="userDropDown"
      trigger={trigger()}
      options={menuOptions()}
    />
  );
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

AccountDropDown.propTypes = {
  history: PropTypes.object
};

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(AccountDropDown)
);
