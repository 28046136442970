import axios from "axios";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Icon, Popup } from "semantic-ui-react";
import AccountDropDown from "../components/accountPage/accountDropDown.jsx";
import { fenwayLogo, fenwhereLogo } from "../images";
import "../styles/header.css";

function HeaderContainer(props) {
  const [show, setShow] = useState(false);
  const [newMessage, setNewMessage] = useState(false);
  const [items, setNewItems] = useState([]);
  const node = useRef();

  useEffect(() => {
    findAllNotifications();
    document.addEventListener("click", closeNotificationsMenu);
    return function cleanup() {
      document.removeEventListener("click", closeNotificationsMenu);
    };
  }, []);

  useEffect(() => {
    findAllNotifications();
  }, [newMessage]);

  useEffect(() => {
    const newMessageFound = _.find(items, { wasViewed: false });
    setNewMessage(!!newMessageFound);
    localStorage.setItem("newMessage", JSON.stringify(newMessage));
    localStorage.setItem("items", JSON.stringify(items));
  }, [items]);

  const closeNotificationsMenu = e => {
    const bell = document.getElementById("bellBtn");
    const menu = document.getElementById("notificationContainer");
    // If you are not clicking the bell or the menu then close Notifications
    if (menu && !menu.contains(e.target) && !bell.contains(e.target)) {
      setShow(false);
    }
  };

  const findAllNotifications = () => {
    if (props.user.id) {
      axios
        .post("/notification/findNotificationsByUser", {
          id: props.user.id
        })
        .then(res => {
          setNewItems(res.data);
        });
    }
  };

  const markAsRead = btnId => {
    axios.post("/notification/markAsRead", { id: btnId }).then(res => {
      let notifiList = items.slice();
      let newItems = _.map(notifiList, o => {
        return o.id === btnId ? res.data : o;
      });
      setNewItems(newItems);
    });
  };

  const deleteNotification = btnId => {
    axios.post("/notification/deleteNotification", { id: btnId }).then(res => {
      let notifiList = items.slice();
      setNewItems(
        _.filter(notifiList, o => {
          return o.id !== btnId;
        })
      );
    });
  };

  const toggleNotification = () => {
    setShow(!show);
    //on mobile the opening notifications will close sidebar
    if (window.innerWidth <= 850) {
      props.closeSidebar();
    }
  };

  const deleteAll = () => {
    if (items !== []) {
      let req = { id: props.user.id };
      axios.post("/notification/deleteAllNotificationByUser", req).then(res => {
        if (res.status === 200) {
          setNewMessage(false);
          setNewItems([]);
          localStorage.setItem("items", JSON.stringify(items));
          localStorage.setItem("newMessage", false);
        } else if (res.status === 204) {
          // conditional if no notifications are found
        } else {
          console.log("An unexpected error occured");
        }
      });
    }
  };

  const markAllRead = () => {
    if (newMessage === true) {
      let req = { id: props.user.id };
      axios.post("/notification/markAllRead", req).then(res => {
        if (res.status === 200) {
          setNewMessage(false);
          setNewItems(res.data);
          localStorage.setItem("items", JSON.stringify(items));
          localStorage.setItem("newMessage", false);
        } else {
          console.log("An unexpected error occured");
        }
      });
    }
  };

  const notifiList = _.cloneDeep(items);
  const notifiItems = _.map(notifiList, obj => {
    return (
      <div
        className="notificationList"
        key={obj.id}
        style={
          obj.wasViewed
            ? { backgroundColor: "#fff", color: "#0A2240", display: "flex" } // white is viewed notification
            : { backgroundColor: "#0a2240", color: "#fff", display: "flex" } // color blue if new notification
        }
      >
        <Popup
          id="notificationPopup"
          trigger={
            <Button
              className="notifiBtn"
              inverted
              color="red"
              id="deleteNotiBtn"
              data-cy="notifiDeleteBtn"
              onClick={() => deleteNotification(obj.id)}
            >
              <Icon id="archiveIcon" key="Icon2" className="archive" />
            </Button>
          }
          content="Delete Message"
          position="left center"
        />{" "}
        <li
          key={obj.id}
          className={obj.wasViewed ? "viewedItem" : "nonViewed"}
          onClick={() => markAsRead(obj.id)}
        >
          {obj.message}
          <div className="notifiedTime">
            {moment(obj.createdDate)
              .local()
              .format("MM/DD/YYYY") +
              " at " +
              moment(obj.createdDate)
                .local()
                .format("h:mma")}
          </div>
        </li>
      </div>
    );
  });

  return (
    props.user.active && (
      // If logged in and not using default password, the button should appear
      <div id="headerdiv">
        <img
          src={fenwayLogo}
          alt="error"
          id="logo"
          onClick={() => props.history.push("/home")}
        />
        <Button
          inverted
          icon
          id="hamburger"
          onClick={() => {
            props.toggleSidebar();
            if (show) {
              setShow(false);
            }
          }}
        >
          <Icon name="bars" size="big" />
        </Button>
        <img id="headertitle" src={fenwhereLogo}></img>
        <div className="preferencesRow">
          <AccountDropDown />
          <Button
            id="bellBtn"
            color={newMessage ? "red" : "green"}
            inverted
            className={"notifiBtn"}
            data-cy="bellBtn"
            onClick={toggleNotification}
          >
            <Icon id="bellIcon" key="Icon" className="bell" />
          </Button>
        </div>
        {show && (
          <div id="notificationContainer">
            <h2 id="notifTitle" data-cy="notifiTitle">
              Notifications
            </h2>
            <div id="notifAll">
              <Button
                id="deleteAllButton"
                inverted
                color="red"
                onClick={() => {
                  deleteAll();
                }}
              >
                Delete All
              </Button>
              <Button
                id="markAllReadButton"
                inverted
                color="green"
                onClick={() => {
                  markAllRead();
                }}
              >
                Mark All as Read
              </Button>
            </div>
            <ul data-cy="notifiList">{notifiItems}</ul>
          </div>
        )}
      </div>
    )
  );
}

const mapStateToProps = state => {
  return {
    user: state.user,
    loginStatus: state.loginStatus
  };
};

export default withRouter(connect(mapStateToProps)(HeaderContainer));
