import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {
  Modal,
  Button,
  Form,
  FormGroup,
  Label,
  Popup,
  Input,
  Message
} from "semantic-ui-react";

class TroubleTicketModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      success: false,
      error: false,
      pageError: "",
      pageErrorValid: true,
      errorDesc: "",
      errorDescValid: true,
      modalHeight: "350px",
      submitLoading: false,
      buttonDisable: false,
      imageSizeValid: true
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }
  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  open = () => this.setState({ open: true, submitLoading: false });

  close = () => {
    this.setState({ open: false }, this.resetForm());
  };

  handleChange = e => {
    let validReset = e.target.id + "Valid";
    this.setState(
      {
        [e.target.id]: e.target.value,
        [validReset]: true,
        success: false,
        error: false
      },
      this.heightCorrection
    );
  };

  validate = () => {
    this.setState({submitLoading: true, buttonDisable: true})
    let { pageErrorValid, errorDescValid, pageError, errorDesc, imageSizeValid } = this.state;

    if (!pageError.replace(/\s/g, "").length) {
      pageErrorValid = false;
    }
    if (!errorDesc.replace(/\s/g, "").length) {
      errorDescValid = false;
    }

    if (pageErrorValid && errorDescValid && imageSizeValid) {
      this.setState({ pageErrorValid, errorDescValid}, this.submitForm);
    } else {
      this.setState(
        { pageErrorValid, errorDescValid, success: false, submitLoading: false, buttonDisable: false },
        this.heightCorrection
      );
    }
  };

  checkImageSize = () => {
    if(this.uploadInput.files[0] != undefined){
      if(this.uploadInput.files[0].size > 10000000){
        this.setState({imageSizeValid: false})
      }else{
        this.setState({imageSizeValid: true})
      }
    }else{
      this.setState({imageSizeValid: true})
    }
  }

  heightCorrection = () => {
    let spaceMultiplier = 0;

    if (!this.state.pageErrorValid) spaceMultiplier += 1;
    if (!this.state.errorDescValid) spaceMultiplier += 1;
    if (this.state.success || this.state.error) spaceMultiplier += 2;

    let modalHeight = 350;
    if (spaceMultiplier > 0) {
      modalHeight += 30 * spaceMultiplier;
    }
    modalHeight = modalHeight + "px";

    this.setState({ modalHeight });
  };

  resetForm = () => {
    this.setState({
      pageError: "",
      errorDesc: "",
      pageErrorValid: true,
      errorDescValid: true,
      imageSizeValid: true,
      modalHeight: "350px",
      success: false,
      error: false
    });
  };

  submitForm = () => {
    this.setState({ submitLoading: true })
    let file = this.uploadInput.files[0];
    let formData = new FormData()
    formData.set("name", this.props.user.firstName + " " + this.props.user.lastName)
    formData.set("email", this.props.user.email)
    formData.set("role", this.props.user.userRole.role)
    formData.set("page", this.state.pageError)
    formData.set("desc", this.state.errorDesc)
    formData.append("file", file)
    axios
      .post("/trouble/sendTicket", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(res => {
        if (res.status === 200) {
          this.setState(
            { success: true, error: false, pageError: "", errorDesc: "", undefined: "", submitLoading: false, buttonDisable: false},
            this.heightCorrection
          );
          document.getElementById('troubleTicketFileInput').value = "";//clears file input
        } else {
          this.setState({ error: true, success: false, submitLoading: false }, this.heightCorrection);
        }
      })
      .catch(err => {
        this.setState({ error: true, success: false, submitLoading: false }, this.heightCorrection);
      });
  };

  render() {
    return (
      <Modal
        closeIcon
        style={{
          margin: "auto auto",
          marginTop: "auto",
          maxHeight: this.state.modalHeight
        }}
        centered
        size="tiny"
        open={this.state.open}
        onClose={this.close}
        data-cy="ttModal"
      >
        <Modal.Header>Trouble Ticket</Modal.Header>
        <Modal.Content scrolling>
          <Form loading={this.state.submitLoading} success={this.state.success} error={this.state.error}>
            <Form.Group widths="equal">
              <Form.Field>
                <Form.Input
                  id="pageError"
                  data-cy="pError"
                  error={!this.state.pageErrorValid}
                  required
                  label="Page With Error:"
                  onInput={this.handleChange}
                  value={this.state.pageError}
                />
                {this.state.pageErrorValid ? null : (
                  <Label data-cy="errorLabel" pointing color="red">
                    Please fill in the required field
                  </Label>
                )}
              </Form.Field>
              <Form.Field />
            </Form.Group>
            <FormGroup widths="equal">
              <Form.Field>
                <Form.TextArea
                  id="errorDesc"
                  data-cy="dError"
                  error={!this.state.errorDescValid}
                  required
                  label="Please Describe the Error:"
                  onInput={this.handleChange}
                  value={this.state.errorDesc}
                />
                {this.state.errorDescValid ? null : (
                  <Label data-cy="errorLabel" pointing color="red">
                    Please fill in the required field
                  </Label>
                )}
              </Form.Field>
            </FormGroup>
            <FormGroup widths="equal">
              <Form.Field>
                <Form.Input
                  error={!this.state.imageSizeValid}
                  label="Please Upload an Image or PDF of the Error:"
                  children={
                    <input
                      onChange={this.checkImageSize}
                      id='troubleTicketFileInput'
                      ref={ref => {
                        this.uploadInput = ref;
                      }}
                      type="file"
                      name="file"
                      accept="image/*,.pdf"//popup window shows these file types(MIME)
                    />
                  }
                />
                {this.state.imageSizeValid ? null : (
                  <Label data-cy="errorLabel" pointing color="red">
                    Image must be less than 10MB
                  </Label>
                )}
              </Form.Field>
            </FormGroup>
            <Message
              data-cy="successMsg"
              success
              header="Trouble Ticket has been Submitted"
            />
            <Message
              data-cy="errorMsg"
              error
              header="There was an issue sending your Ticket, please try again at a later time"
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button disabled={this.state.buttonDisable} inverted color="green" positive onClick={this.validate}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(
  mapStateToProps,
  null
)(TroubleTicketModal);
