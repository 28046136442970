import initialState from "../initialState";
import types from "../actions/types";

export default function userReducer(state = initialState.user, action) {
  switch (action.type) {
    case types.UPDATE_TIMEZONE:
      return {
        ...state,
        timezone: action.timezone
      };
    case types.UPDATE_USER_PERMS:
      return {
        ...state,
        userPerms: action.userPerms
      };
    case types.UPDATE_BLOCK:
      return {
        ...state,
        block: action.blockData
      };
    case types.UPDATE_USER:
      return {
        ...state,
        ...action.user
      };
    case types.ACCOUNT_STATUS:
      return {
        ...state,
        accountStatus: action.accountStatus
      };
    case "persist/PURGE":
      return {
        ...initialState.user
      };
    default:
      return state;
  }
}
