import React from "react";
import { Image } from "semantic-ui-react";
import { Portrait_Placeholder } from "../../images";

export const AvatarComponent = props => {
  return (
    <>
      {props.image === undefined ? (
        <Image src={Portrait_Placeholder} data-cy="teamMemberAvatar" avatar spaced verticalAlign={"middle"}/>
      ) : (
        <Image src={props.image} data-cy="teamMemberAvatar" avatar spaced verticalAlign={"middle"}/>
      )}
    </>
  );
};
