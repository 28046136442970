import moment from "moment";
import React from "react";
import {
  Button,
  Header as SemanticHeader,
  Icon,
  Modal
} from "semantic-ui-react";
import _ from "lodash";

const MaxHourModal = props => {
  let newKey = 0;
  return (
    <Modal
      closeIcon
      open={props.conflictModalVisible}
      size="small"
      className="scrolling conflictModal"
      onClose={props.handleConflictClose}
    >
      <SemanticHeader>Conflict Detected</SemanticHeader>
      <Modal.Content>
        <h3> {props.conflictModalMessage} </h3>
        {_.isEmpty(props.conflictScheduleArray) ? (
          <p> Empty Conflict List </p>
        ) : (
          _.map(props.conflictScheduleArray, time => {
            return (
              <p key={newKey++}>
                {" "}
                {moment(time.startTime)
                  .local()
                  .format("LT")
                  .toString()}{" "}
                -{" "}
                {moment(time.endTime)
                  .local()
                  .format("LT")
                  .toString()}{" "}
              </p>
            );
          })
        )}
        <h3> Would you like to delete the conflicting schedules? </h3>
      </Modal.Content>
      <Modal.Actions>
        {" "}
        <Button
          icon
          inverted
          color="red"
          size="small"
          onClick={() => {
            props.handleConflictClose();
          }}
        >
          <Icon name="checkmark" />
          {" No"}
        </Button>
        <Button
          icon
          inverted
          color="green"
          size="small"
          onClick={props.handleConflictDelete}
        >
          <Icon name="checkmark" />
          {" Yes"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default MaxHourModal;
