import axios from "axios";
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Table } from "semantic-ui-react";
import ModalBuilder from "../../components/adminPage/modalBuilder.jsx";
import { AvatarComponent } from "../utilities/avatarComponent";

function TeamSection(props) {
  const [userList, setUserList] = useState([]);
  const [sortedList, setSortedList] = useState([]);
  const [sortedProperty, setSortedProperty] = useState({
    key: "name",
    direction: "asc"
  });
  const [alertAllModal, setAlertAllModal] = useState(false);
  const [alertAllButtonDisabled, setAlertAllButtonDisabled] = useState(false);

  useEffect(() => {
    setUserList(props.userList);
  }, [props.userList]);

  const alertList = () => {
    const list = _.filter(userList, user => {
      if (user.alerted === false || user.alerted === undefined) {
        return true;
      }
    });
    list.map(user => {
      user.alerted = true;
      sendNotification(
        user,
        "Your tech lead has requested that you update your schedule."
      );
    });
    setAlertAllModal(true);
    setUserList(list);
  };

  const closeModal = () => {
    setAlertAllModal(false);
  };

  useEffect(() => {
    sortBy();
  }, [sortedProperty, userList]);

  const sortBy = () => {
    switch (sortedProperty.key) {
      case "name":
        setSortedList(
          _.orderBy(
            userList,
            user => {
              return `${user.firstName} ${user.lastName}`;
            },
            [sortedProperty.direction]
          )
        );
        break;
      case "hours":
        setSortedList(
          _.orderBy(userList, ["maxHours"], [sortedProperty.direction])
        );
        break;
      case "alert":
        setSortedList(
          _.orderBy(userList, ["alerted"], [sortedProperty.direction])
        );
        break;
      default:
        setSortedList(
          _.orderBy(
            userList,
            user => {
              return `${user.firstName} ${user.lastName}`;
            },
            [sortedProperty.direction]
          )
        );
        break;
    }
  };

  let reverseDirection = direction => {
    switch (direction) {
      case "asc":
        return "desc";
      case "desc":
        return "asc";
      default:
        return "asc";
    }
  };

  let convertDirectionSyntax = direction => {
    switch (direction) {
      case "asc":
        return "ascending";
      case "desc":
        return "descending";
      default:
        return "ascending";
    }
  };

  const sendNotification = (user, message) => {
    axios.post("/notification/createNotification", {
      id: user.id,
      message: message
    });
  };

  return (
    <div>
      <ModalBuilder
        closeIcon
        title="Alert All Successful"
        content={"All appropriate users have been alerted."}
        visible={alertAllModal}
        type="notification"
        closeFunction={closeModal}
      />

      <Table
        sortable
        celled
        verticalAlign="middle"
        textAlign="center"
        style={{ maxWidth: "900px", margin: "auto" }}
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell disabled>
              <h3>Change Schedule Alerts</h3>
            </Table.HeaderCell>
            <Table.HeaderCell
              colSpan="2"
              className={alertAllButtonDisabled ? "disableCursorPointer" : ""}
            >
              <Button
                onClick={() => {
                  alertList();
                  setAlertAllButtonDisabled(true);
                }}
                inverted
                color="red"
                className={
                  alertAllButtonDisabled
                    ? "buttonsWidth disableCursorPointer"
                    : "buttonsWidth"
                }
                disabled={alertAllButtonDisabled}
              >
                Alert All
              </Button>
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell
              className="columnHeader"
              onClick={() => {
                setSortedProperty({
                  key: "name",
                  direction:
                    sortedProperty.key !== "name"
                      ? "asc"
                      : reverseDirection(sortedProperty.direction)
                });
              }}
              sorted={
                sortedProperty.key === "name"
                  ? convertDirectionSyntax(sortedProperty.direction)
                  : undefined
              }
            >
              Name
            </Table.HeaderCell>
            <Table.HeaderCell
              className="columnHeader"
              onClick={() => {
                setSortedProperty({
                  key: "hours",
                  direction:
                    sortedProperty.key !== "hours"
                      ? "asc"
                      : reverseDirection(sortedProperty.direction)
                });
              }}
              sorted={
                sortedProperty.key === "hours"
                  ? convertDirectionSyntax(sortedProperty.direction)
                  : undefined
              }
            >
              Hours
            </Table.HeaderCell>
            <Table.HeaderCell
              className="columnHeader"
              onClick={() => {
                setSortedProperty({
                  key: "alert",
                  direction:
                    sortedProperty.key !== "alert"
                      ? "asc"
                      : reverseDirection(sortedProperty.direction)
                });
              }}
              sorted={
                sortedProperty.key === "alert"
                  ? convertDirectionSyntax(sortedProperty.direction)
                  : undefined
              }
            >
              Alerts
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(sortedList, user => (
            <Table.Row key={user.id}>
              <Table.Cell style={{ textAlign: "left" }}>
                {user.userAvatar === undefined ? (
                  <AvatarComponent />
                ) : (
                  <AvatarComponent image={user.userAvatar.image} />
                )}
                {user.firstName + " " + user.lastName}
              </Table.Cell>
              <Table.Cell>{user.maxHours}</Table.Cell>
              <Table.Cell>
                <Button
                  className="buttonsWidth"
                  inverted
                  color="red"
                  disabled={user.alerted}
                  onClick={() => {
                    sendNotification(
                      user,
                      "Your tech lead has requested that you update your schedule."
                    );
                    const changedUserList = userList.slice();
                    for (const i in changedUserList) {
                      if (changedUserList[i].id === user.id) {
                        changedUserList[i].alerted = true;
                        break;
                      }
                    }
                    setUserList(changedUserList);
                  }}
                >
                  Send Alert
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(TeamSection);
