import React, { Fragment } from "react";
import { fenwayLogo } from "../images";
import "../styles/loginPage.css";

function LoginContainer(props) {
  return (
    <div className="gradientBackground">
      <div className="loginPage rounded">
        <div style={{ textAlign: "center" }}>
          <img src={fenwayLogo} alt="error" />
          <br />
          <br />
          <h1>FenWhere</h1>
          {props.loginStatus === "Logging In...." ? (
            <Fragment>
              <h2>{props.loginStatus}</h2>
              <div className="ui active centered inline loader"></div>
            </Fragment>
          ) : (
            <Fragment>
              <h2>{props.loginStatus}</h2>
              <h3>Please contact an admin or refresh to try again.</h3>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
}

export default LoginContainer;
