import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "../../styles/accountContainer.css";
import { Form, Image, Label } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { changeAccount } from "../../redux-store/actions/userActions";
import { Portrait_Placeholder } from "../../images";

//Custom hook for handleChange, should not run on first render, may need improvement
function useDidChangeInfoEffect(fn, flag) {
  const didMountRef = useRef(false);
  useEffect(() => {
    if (didMountRef.current) {
      fn();
    } else {
      didMountRef.current = true;
    }
  }, [...flag]);
}

function UserInformation(props) {
  const [firstName, setFirstName] = useState({
    old: props.user.firstName,
    new: props.user.firstName
  });
  const [lastName, setLastName] = useState({
    old: props.user.lastName,
    new: props.user.lastName
  });

  const [errors, setErrors] = useState({});
  const [userAvatar, setUserAvatar] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const [myProjectList, setMyProjectList] = useState(
    props.user.projects.map(project => {
      return project.id;
    })
  );
  const [targetId, setTargetId] = useState("");

  useEffect(() => {
    setFirstName({ new: firstName.new , old: firstName.new });
    setLastName({ new: lastName.new, old: lastName.new });
    setErrors({});
  }, [props.user]);

  useEffect(() => {
    getUserAvatar();
  }, []);

  //Custom hooks, these may not be optimized or are poor practice
  // useDidMountAvatarEffect(getUserAvatar, userAvatar);
  useDidChangeInfoEffect(validateInfoChange, [firstName, lastName]);

  //Functions
  function getUserAvatar() {
    const req = {
      id: props.user.id
    };
    axios.post("/avatar/getAvatar", req).then(res => {
      setUserAvatar(res.data.image);
    });
  }

  function projectList() {
    return props.user.projects
      .map(project => {
        return {
          key: project.id,
          text: project.projectName,
          value: project.id
        };
      })
      .concat({ key: 0, text: "No Assigned Projects", value: 0 });
  }

  function handleSubmit() {
    const req = {
      firstName: firstName,
      lastName: lastName,
      email: props.user.email
    };
    props.changeAccount(req);
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
    }, 3000);
  }

  function handleChange(event) {
    setTargetId(event.target.id);
    setShowMessage(false);
    switch (event.target.id) {
      case "firstName":
        setFirstName({
          ...firstName,
          new: event.target.value.trim()
        });
        break;
      case "lastName":
        setLastName({
          ...lastName,
          new: event.target.value.trim()
        });
        break;
      case "email":
        setEmail({
          ...email,
          new: event.target.value.trim()
        });
        break;
    }
  }

  // async function validateInfoChange(id) {
  async function validateInfoChange() {
    switch (targetId) {
      case "firstName":
        let first = validateName(firstName.new, "first");

        setErrors({
          ...errors,
          firstName: first.nameMessage
        });
        break;
      case "lastName":
        let last = validateName(lastName.new, "last");

        setErrors({
          ...errors,
          lastName: last.nameMessage
        });
        break;
      default:
        break;
    }
  }
  function validateName(name, type) {
    let nameError = false;
    let nameMessage = "";
    if (type === "first" || type === "last") {
      const regex = RegExp(/[^A-Za-z ]/);
      if (regex.test(name)) {
        nameError = true;
        nameMessage = "Please include only letters and spaces in names";
      }
    }
    if (!props.validateNotEmpty(name)) {
      nameError = true;
      if (type === "first") {
        nameMessage = "Enter First Name";
      } else if (type === "last") {
        nameMessage = "Enter Last Name";
      }
    }
    if (props.containsWhiteSpace(name)) {
      nameError = true;
      nameMessage = "Contains Empty Characters";
    }
    if (name.length > 20) {
      nameError = true;
      nameMessage = "20 Character Maximum";
    }
    let res = {
      nameError,
      nameMessage
    };
    return res;
  }

  return (
    <div id="userInformation">
      <Form size="small" onSubmit={handleSubmit}>
        <h3>User Information</h3>
        {userAvatar === undefined ? (
          <Image
            data-cy="accountAvatar"
            size="small"
            src={Portrait_Placeholder}
            floated="left"
            rounded
          />
        ) : (
          <Image
            data-cy="accountAvatar"
            size="small"
            src={userAvatar}
            floated="left"
            rounded
          />
        )}
        <Form.Group widths="equal">
          <Form.Field>
            <Form.Input
              id="firstName"
              label="First Name"
              placeholder="First Name"
              maxLength="20"
              defaultValue={props.user.firstName}
              onInput={handleChange}
            />
            {errors.firstName ? (
              <Label pointing color="red">
                {errors.firstName}
              </Label>
            ) : null}
          </Form.Field>
          <Form.Field>
            <Form.Input
              id="lastName"
              label="Last Name"
              placeholder="Last Name"
              maxLength="30"
              defaultValue={props.user.lastName}
              onInput={handleChange}
            />
            {errors.lastName ? (
              <Label pointing color="red">
                {errors.lastName}
              </Label>
            ) : null}
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Dropdown
            id="accountProjectList"
            label="Projects"
            multiple
            fluid
            options={projectList()}
            value={myProjectList.length > 0 ? myProjectList : [0]}
            readOnly
            width={16}
          />
        </Form.Group>

        <Form.Group>
          {showMessage && props.user.accountStatus === "updatedInformation" ? (
            <Label className="accountLabel" color="green">
              Your information has been updated!
            </Label>
          ) : showMessage && props.user.accountStatus == "NoNewInfo" ? (
            <Label className="accountLabel" color="red">
              No new information was provided.
            </Label>
          ) : null}
        </Form.Group>

        <Form.Button
          id="updateUser"
          inverted
          color="green"
          type="submit"
          floated="right"
          disabled={
            errors.firstName || errors.lastName || errors.email ? true : false
          }
        >
          Update
        </Form.Button>
      </Form>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    user: state.user,
    loginStatus: state.loginStatus
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeAccount: user => {
      dispatch(changeAccount(user));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserInformation)
);
