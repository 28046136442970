import axios from "axios";
import React, { useState, useEffect } from "react";
import "../../styles/adminTabs.css";
import { connect } from "react-redux";
import {
  Button,
  Table,
  Input,
  Checkbox,
  Icon,
  Modal,
  Form,
  Label
} from "semantic-ui-react";
import ModalBuilder from "./modalBuilder";
import * as _ from "lodash";
import PropTypes from "prop-types";

function COETab(props) {
  const [coeList, setCoeList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [displayedList, setDisplayedList] = useState([]);
  const [showInactiveChecked, setShowInactiveChecked] = useState(false);
  const [editable, setEditable] = useState(
    props.user.userRole.adminCOEPerm > 1
  );
  const [previousSort, setPreviousSort] = useState({
    column: "name",
    sortASC: true
  });
  const [filterBy, setFilterBy] = useState("");
  const [modal, setModal] = useState("");
  const [editCoe, setEditCoe] = useState({});
  const [nameError, setNameError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [newCOEName, setNewCOEName] = useState("");
  const [newCOELocation, setNewCOELocation] = useState("");

  useEffect(() => {
    updateTab();
  }, [previousSort]);

  useEffect(() => {
    if (previousSort.column === "name" && previousSort.sortASC) {
      filter(filterBy);
    } else {
      sort(previousSort);
    }
  }, [coeList]);

  useEffect(() => {
    updateDisplayedCOEs();
  }, [showInactiveChecked, filteredList]);

  const updateTab = () => {
    axios.get("/coe/getCompactCOEList").then(res => {
      setCoeList(res.data);
    });
  };

  const sort = previousSort => {
    const newCOEList = coeList;
    if (previousSort.column === "name") {
      newCOEList.sort((a, b) => {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
      });
    } else if (previousSort.column === "location") {
      newCOEList.sort((a, b) => {
        return a.location.toLowerCase() > b.location.toLowerCase() ? 1 : -1;
      });
    }
    // Start with a sort by ascending. Then flip the array if it is supposed to be descending
    if (!previousSort.sortASC) newCOEList.reverse();

    setPreviousSort(previousSort);
    setCoeList(newCOEList);
    filter(filterBy);
  };

  const sortBy = column => {
    let sortASC = true;
    if (previousSort.column === column) {
      sortASC = !previousSort.sortASC;
    }
    sort({
      column: column,
      sortASC: sortASC
    });
  };

  const filter = text => {
    if (text === "") {
      setFilterBy("");
      setFilteredList(
        coeList.filter(
          item =>
            item.name.toLowerCase().includes(text.toLowerCase()) ||
            item.location.toLowerCase().includes(text.toLowerCase())
        )
      );
    } else {
      setFilterBy(text);
      setFilteredList(
        coeList.filter(
          item =>
            item.name.toLowerCase().includes(text.toLowerCase()) ||
            item.location.toLowerCase().includes(text.toLowerCase())
        )
      );
    }
  };

  function updateDisplayedCOEs() {
    if (showInactiveChecked) setDisplayedList(filteredList)
    else {
      let activeList = filterActiveCOEs(filteredList);
      setDisplayedList(activeList);
    }
  }

  function filterActiveCOEs(COEs) {
    return COEs.filter( coe => coe.active );
  }

  const closeModal = () => {
    setModal("");
    setNameError("");
    setLocationError("");
    setNewCOEName("");
    setNewCOELocation("");
  };

  const addCOE = () => {
    let validForm = true;
    if (newCOEName === "") {
      setNameError("Please Enter COE Name");
      validForm = false;
    } else if (coeList.find(COE => COE.name === newCOEName)) {
      setNameError("COE Already Exists");
      validForm = false;
    }
    if (newCOELocation === "") {
      setLocationError("Please Enter a Location");
      validForm = false;
    }
    if (validForm) {
      const req = {
        name: newCOEName,
        location: newCOELocation
      };
      axios.post("/coe/addCOE", req).then(() => {
        closeModal();
        updateTab();
      });
    }
  };

  const validateForm = () => {
    setNameError("");
    setLocationError("");
    let validForm = true;
    if (editCoe.name === "") {
      setNameError("Please Enter COE Name");
      validForm = false;
    } else if (
      _.find(coeList, COE => {
        return COE.id !== editCoe.id && COE.name === editCoe.name;
      })
    ) {
      setNameError("COE Already Exists");
      validForm = false;
    }

    if (editCoe.location === "") {
      setLocationError("Please Enter a Location");
      validForm = false;
    }
    if (validForm) {
      axios
        .post("/coe/updateCOE", editCoe)
        .then(res => {
          updateTab();
          closeModal();
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const handleBlankSpaceInput = project => {
    const re = /^[^\s].+[^\s]$/;

    return re.test(String(project).toLowerCase());
  };

  return (
    <div>
      <div>
        <h2 className="title">COE Table</h2>
      </div>
      <br />
      <div className="aboveTable" id="coeTableHeader">
        <Button
          inverted
          color="green"
          disabled={!editable}
          onClick={() => setModal("addModal")}
          size="medium"
        >
          Add COE
        </Button>
        <Checkbox
          label="Show inactive COEs"
          checked={showInactiveChecked}
          onChange={() => setShowInactiveChecked(!showInactiveChecked)}
        />
        <Input
          icon="search"
          placeholder="Filter By"
          maxLength="30"
          onChange={e => filter(e.target.value)}
        />
      </div>
      <br />
      <Table
        sortable
        celled
        id="table"
        verticalAlign="middle"
        textAlign="center"
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              className="columnHeader"
              onClick={() => sortBy("name")}
              sorted={
                previousSort.column === "name"
                  ? previousSort.sortASC
                    ? "ascending"
                    : "descending"
                  : null
              }
            >
              Name
            </Table.HeaderCell>
            <Table.HeaderCell
              className="columnHeader"
              onClick={() => sortBy("location")}
              sorted={
                previousSort.column === "location"
                  ? previousSort.sortASC
                    ? "ascending"
                    : "descending"
                  : null
              }
            >
              Location
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(displayedList, coe => (
            <Table.Row
              key={coe.id}
              className="rowLayout"
              onClick={() => {
                setEditCoe(coe);
                setModal("editCoe");
              }}
            >
              <Table.Cell>{coe.name}</Table.Cell>
              <Table.Cell>{coe.location}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <Modal
        closeIcon
        open={modal === "addModal"}
        className="scrolling modal"
        size="tiny"
        closeOnDocumentClick
        onClose={closeModal}
        id="addCOEModal"
      >
        <Modal.Header content="Create COE" />
        <Modal.Content>
          <Form size="small" className="login-form">
            <Form.Group widths="equal">
              <Form.Field>
                <Form.Input
                  id="NewCOEName"
                  required
                  label="COE Name"
                  placeholder="COE Name"
                  maxLength="20"
                  error={nameError !== ""}
                  onBlur={e => setNewCOEName(e.target.value.trim())}
                  onChange={() => setNameError("")}
                />
                {nameError === "" ? null : (
                  <Label pointing color="red">
                    {nameError}
                  </Label>
                )}
              </Form.Field>
              <Form.Field>
                <Form.Input
                  id="COELocation"
                  required
                  label="Location Name"
                  placeholder="Location Name"
                  maxLength="30"
                  error={locationError !== ""}
                  onBlur={e => setNewCOELocation(e.target.value.trim())}
                  onChange={() => setLocationError("")}
                />
                {locationError === "" ? null : (
                  <Label pointing color="red">
                    {locationError}
                  </Label>
                )}
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button icon size="small" inverted color="green" onClick={addCOE}>
            <Icon name="checkmark" /> Create
          </Button>
        </Modal.Actions>
      </Modal>
      {modal !== "editCoe" ? null : (
        <Modal
          closeIcon
          id="editCoeModal"
          className="ui dimmable dimmed scrolling modal editModal"
          size="tiny"
          open
          closeOnDocumentClick
          onClose={closeModal}
        >
          <Modal.Header>{editable ? "Edit COE" : "View COE"}</Modal.Header>
          <Modal.Content>
            <Form size="small" className="login-form">
              <Form.Group widths="equal">
                <Form.Field>
                  <Form.Input
                    data-cy="coeName"
                    required
                    label="COE Name"
                    placeholder="COE Name"
                    maxLength="20"
                    error={nameError !== ""}
                    disabled={!editable}
                    onBlur={e => {
                      const entry = e.target.value.trim();

                      const changedCOE = {
                        id: editCoe.id,
                        name: entry,
                        location: editCoe.location
                      };
                      setEditCoe(changedCOE);
                      e.target.value = entry;
                    }}
                    placeholder="COE Name..."
                    className="contentList"
                    defaultValue={editCoe.name}
                  />
                  {nameError === "" ? null : (
                    <Label pointing color="red">
                      {nameError}
                    </Label>
                  )}
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    data-cy="location"
                    required
                    label="COE Location"
                    placeholder="COE Location"
                    maxLength="30"
                    error={locationError !== ""}
                    disabled={!editable}
                    onBlur={(e, data) => {
                      const entry = e.target.value.trim();

                      const changedCOE = {
                        id: editCoe.id,
                        name: editCoe.name,
                        location: entry
                      };
                      setEditCoe(changedCOE);
                      e.target.value = entry;
                    }}
                    placeholder="COE Location..."
                    className="contentList"
                    defaultValue={editCoe.location}
                  />
                  {locationError === "" ? null : (
                    <Label pointing color="red">
                      {locationError}
                    </Label>
                  )}
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field>
                  <Form.Checkbox
                    id="active"
                    data-cy="activeCheckbox"
                    disabled={!editable}
                    defaultChecked={editCoe.active}
                    label="Active"
                    onChange={(e, data)  => {
                      let newCoe = { ...editCoe };
                      newCoe.active = data.checked;
                      setEditCoe(newCoe);
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Modal.Content>
          {editable ? (
            <Modal.Actions>
              <Button
                icon
                inverted
                color="green"
                size="small"
                onClick={editable ? () => validateForm() : () => closeModal()}
              >
                <Icon name="checkmark" />
                Save
              </Button>
            </Modal.Actions>
          ) : null}
        </Modal>
      )}
    </div>
  );
}
COETab.propTypes = {
  user: PropTypes.object
};

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(COETab);
