import moment from "moment";
import "rc-time-picker/assets/index.css";
import React from "react";
import Timekeeper from "react-timekeeper";
import { Button, Grid, Icon, Label, Modal } from "semantic-ui-react";

const AddScheduleModal = props => {
  return (
    <Modal
      closeIcon
      open={props.visible}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      className="scrolling modal"
      size="small"
      onClose={props.handleCancel}
    >
      <Modal.Header>
        {props.editMode ? "Edit " : "Add to "}
        {props.date + "'s Schedule"}
      </Modal.Header>
      <Modal.Content>
        <Grid columns={2} container stackable textAlign={"center"}>
          <Grid.Row>
            <Grid.Column>
              <div id="timeBoxStart" data-cy="timeBoxStart">
                <h4>Select Start Time</h4>
                <Timekeeper
                   time={moment(props.schedule.start, ["HH:mm Z", "YYYY-MM-DD HH:mm:ssZ"]).local().format(
                    "h:mm a"
                  )}
                  onChange={props.onStartChange}
                  switchToMinuteOnHourSelect
                  config={{
                    TIME_BACKGROUND: "#fff",
                    TIME_DEFAULT_COLOR: "#0a2240",
                    TIME_SELECTED_COLOR: "#db2828",
                    CLOCK_WRAPPER_BACKGROUND: "#d3d3d3",
                    CLOCK_WRAPPER_MERIDIEM_BACKGROUND_COLOR_SELECTED: "#db2828",
                    CLOCK_WRAPPER_MERIDIEM_TEXT_COLOR_SELECTED: "#fff",
                    CLOCK_BACKGROUND: "#0a2240",
                    CLOCK_NUMBER_COLOR: "#fff",
                    CLOCK_HAND_ARM: "#db2828",
                    CLOCK_HAND_CIRCLE_BACKGROUND: "#db2828",
                    useCoarseMinutes: true
                  }}
                />
                {/* This is the red error message on the field */}
                {props.startTimeErrorMessage ? (
                  <Label pointing color="red">
                    {props.startTimeErrorMessage}
                  </Label>
                ) : null}
              </div>
            </Grid.Column>
            <Grid.Column>
              <div id="timeBoxEnd" data-cy="timeBoxEnd">
                <h4>Select End Time</h4>
                <Timekeeper
                  time={moment(props.schedule.stop, ["HH:mm Z", "YYYY-MM-DD HH:mm:ssZ"]).local().format(
                    "hh:mm a"
                  )}
                  onChange={props.onStopChange}
                  switchToMinuteOnHourSelect
                  config={{
                    TIME_BACKGROUND: "#fff",
                    TIME_DEFAULT_COLOR: "#0a2240",
                    TIME_SELECTED_COLOR: "#db2828",
                    CLOCK_WRAPPER_BACKGROUND: "#d3d3d3",
                    CLOCK_WRAPPER_MERIDIEM_BACKGROUND_COLOR_SELECTED: "#db2828",
                    CLOCK_WRAPPER_MERIDIEM_TEXT_COLOR_SELECTED: "#fff",
                    CLOCK_BACKGROUND: "#0a2240",
                    CLOCK_NUMBER_COLOR: "#fff",
                    CLOCK_HAND_ARM: "#db2828",
                    CLOCK_HAND_CIRCLE_BACKGROUND: "#db2828",
                    useCoarseMinutes: true
                  }}
                />
                {/* This is the red error message on the field */}
                {props.endTimeErrorMessage ? (
                  <Label pointing color="red">
                    {props.endTimeErrorMessage}
                  </Label>
                ) : null}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>

      <Modal.Actions>
        {props.editMode ? (
          <Button
            size="small"
            inverted
            color="red"
            icon
            floated={"left"}
            onClick={props.showDeleteModal}
          >
            <Icon name="trash alternate" />
            Delete
          </Button>
        ) : (
          ""
        )}
        <Button
          inverted
          color="green"
          size="small"
          icon
          floated={"left"}
          onClick={props.copiedSchedule}
        >
          <Icon name="copy" />
          Copy
        </Button>
        <Button
          icon
          inverted
          color="green"
          size="small"
          onClick={() => {
            props.verifyTime(props.handleConfirm);
          }}
        >
          <Icon name="checkmark" /> Create
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AddScheduleModal;
