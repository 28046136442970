import { applyMiddleware, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import appReducer from "./appReducers";

const composerEnhancer = compose(
  applyMiddleware(thunkMiddleware),
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export const store = createStore(appReducer, composerEnhancer);
