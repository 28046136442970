import axios from "axios";
import _ from "lodash";
import types from "./types";
import moment from "moment";

export function retrieveTeamList(req) {
  return dispatch => {
    axios.post("/schedule/findTeamSchedule", req).then(res => {
      let temp = _.filter(res.data, user => {
        return user.id !== req.userId;
      });
      const teamList = sortTimes(temp);
      dispatch({
        type: types.TEAMLIST_GET,
        teamList
      });
    });
  };
}

function sortTimes(unsortedTeamList) {
  const comingIn = [];
  const alreadyIn = [];
  const doneToday = [];
  let updateTime = 0;
  
  // Convert to first week of Jan 2000
  let weekdays = ["Saturday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
  const currentTime = moment().local();
  const currentTime2000 = moment(currentTime).set({'year': 2000, 'month': 0, 'date': (weekdays.indexOf(currentTime.format('dddd')))+1 });

  for (const user in unsortedTeamList) {
    let isIn = false;
    let isComingIn = false;
    let times = "";
    let timeEnd = "";
    let timeStart = "";
    // loops through schedules of the user's team
    for (const schedule in unsortedTeamList[user].schedule) {
      
      // this section sets booleans to sort times
      const startTime = unsortedTeamList[user].schedule[schedule].startTime;
      const endTime = unsortedTeamList[user].schedule[schedule].endTime;
      if (
        // coming in next
        moment(currentTime2000)
          .local()
          .isBefore(moment(startTime).local() )
      ) {
        isComingIn = true;
        // If statements to know which start time to use
        if (
          times != "" &&
          times >= moment(currentTime2000).local() &&
          times <=
            moment(startTime)
              .local()
              .format("LT")
        ) {
          times = moment(startTime)
            .local()
            .format("LT");
          timeEnd = moment(endTime)
            .local()
            .format("LT");
          timeStart = moment(startTime)
            .local()
            .format("LT");
        } else if (times == "") {
          times = moment(startTime)
            .local()
            .format("LT");
          timeEnd = moment(endTime)
            .local()
            .format("LT");
          timeStart = moment(startTime)
            .local()
            .format("LT");
        }
      } else if (
        // in the office
        moment(currentTime2000)
          .local()
          .isSameOrAfter(moment(startTime).local()) &&
        moment(currentTime2000)
          .local()
          .isSameOrBefore(moment(endTime).local())
      ) {
        isIn = true;
        times = moment(endTime)
        .local()
        .format("LT");
        timeEnd = moment(endTime)
        .local()
        .format("LT");
        timeStart = moment(startTime)
        .local()
        .format("LT");
      }
    }
    // renders names to page
    if (isComingIn && !isIn) {
      let avatar;
      if (unsortedTeamList[user].userAvatar !== undefined)
        avatar = unsortedTeamList[user].userAvatar.image;
      comingIn.push({
        time:
          unsortedTeamList[user].firstName +
          " " +
          unsortedTeamList[user].lastName +
          " - " +
          timeStart +
          " - " +
          timeEnd,
        startTime: timeStart,
        endTime: timeEnd,
        name:
          unsortedTeamList[user].firstName +
          " " +
          unsortedTeamList[user].lastName,
        avatar: avatar
      });
    } else if (isIn) {
      let avatar;
      if (unsortedTeamList[user].userAvatar !== undefined)
        avatar = unsortedTeamList[user].userAvatar.image;
      alreadyIn.push({
        time:
          unsortedTeamList[user].firstName +
          " " +
          unsortedTeamList[user].lastName +
          " - " +
          timeStart +
          " - " +
          timeEnd,
        startTime: timeStart,
        endTime: timeEnd,
        name:
          unsortedTeamList[user].firstName +
          " " +
          unsortedTeamList[user].lastName,
        avatar: avatar
      });
    } else if (!isComingIn && !isIn) {
      let avatar;
      if (unsortedTeamList[user].userAvatar !== undefined)
        avatar = unsortedTeamList[user].userAvatar.image;
      doneToday.push({
        time:
          unsortedTeamList[user].firstName +
          " " +
          unsortedTeamList[user].lastName,
        startTime: timeStart,
        endTime: timeEnd,
        name:
          unsortedTeamList[user].firstName +
          " " +
          unsortedTeamList[user].lastName,
        avatar: avatar
      });
    }
    // finds diff of current time and start/endtime
    const tempDiff = moment(times)
      .local()
      .diff(moment(currentTime2000).local());
    // ensures tempDiff has value then finds the smallest difference
    if (
      !isNaN(tempDiff) &&
      tempDiff > 0 &&
      (!updateTime || tempDiff <= updateTime)
    ) {
      updateTime = tempDiff;
    }
  }

  for (let i of [comingIn, alreadyIn, doneToday]) {
    //Do the following to all three arrays.
    i.sort((a, b) => {
      //Compare start time first. If they are different, return the difference. It will be positive or negative.
      if (a.startTime !== "" && b.startTime !== "") {
        const comparison = moment(a.startTime)
          .local()
          .diff(moment(b.startTime).local());
        if (comparison !== 0) {
          return comparison;
        }
      }
      //We haven't returned yet, so startTime is equal and we sort by endTime. If they are different, return the difference.
      if (a.endTime !== "" && b.endTime != "") {
        const comparison = moment(a.endTime)
          .local()
          .diff(moment(b.endTime).local());
        if (comparison !== 0) {
          return comparison;
        }
      }
      //We still haven't returned, so start and end times are the same. Sort by name.
      return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
    });
  }

  const teamList = {
    comingInNextList: comingIn,
    inOfficeList: alreadyIn,
    doneForDayList: doneToday,
    updateTime: updateTime
  };

  return teamList;
}
