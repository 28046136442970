import React, { useEffect } from "react";
import { Grid } from "semantic-ui-react";
import "../../../src/styles/schedulePage.css";
import moment from "moment";
import PropTypes from "prop-types";

function TeamDateCol(props) {
  useEffect(() => {}, [props]);

  const sumDayHours = () => {
    //find sum of hours for a particular day using same info as total weekly hours
    let sum = 0;
    if (props.dayInfo !== undefined) {
      for (let i in props.dayInfo) {
        if (props.dayInfo[i].day === props.date) {

          sum += moment(props.dayInfo[i].endTime)
            .local()
            .diff(
              moment(props.dayInfo[i].startTime).local(),
              "minutes"
            );
        }
      }
    }
    return Math.round((sum * 100) / 60) / 100;
  };

  let dayHours = sumDayHours();
  return (
    <Grid.Row className="dateColRowLayout">
      <div className="weekdays">
        {props.date} - {dayHours} Hours
      </div>
    </Grid.Row>
  );
}
TeamDateCol.propTypes = {
  date: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};
export default TeamDateCol;
