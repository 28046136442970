import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import "../styles/errorContainer.css";

function ErrorContainer() {
  return (
    <div>
      <br />
      <h1 className="error404">
        <b>404</b>
      </h1>
      <h4 id="errorNote">The requested resource was not found</h4>
      <p id="errorLink">
        Click <Link to="/home">here</Link> to return home
      </p>
    </div>
  );
}

export default withRouter(ErrorContainer);
