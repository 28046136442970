import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import UserInformation from "../components/accountPage/userInformation";
import "../styles/accountContainer.css";

function AccountContainer() {
  const validateNotEmpty = userInput => {
    return userInput.length === 0 ? false : true;
  };

  const containsWhiteSpace = str => {
    return /\s/g.test(str);
  };

  const emailValidation = email => {
    const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <div>
      <h2 className="title">My Account</h2>
      <UserInformation
        validateNotEmpty={validateNotEmpty}
        containsWhiteSpace={containsWhiteSpace}
        emailValidation={emailValidation}
      />
    </div>
  );
}

export default withRouter(AccountContainer);
