import axios from "axios";
import moment from "moment";
import types from "./types";

export function login(user) {
  return (dispatch, getState) => {
    if (user) {
      dispatch({
        type: types.UPDATE_USER,
        user: user
      });
      const today = moment(new Date()).local().format("YYYY-MM-DD");
      axios
        .post("/Block/getCurrentBlock", {
          coeId: user.coe.id,
          currentDate: today
        })
        .then(res => {
          let currentId = -1;
          let selectedId = -1;
          if (res.data) {
            currentId = res.data.id;
            selectedId = res.data.id;
          }
          dispatch({
            type: types.UPDATE_BLOCK,
            blockData: {
              currentId,
              selectedId
            }
          });
        })
        .catch(err => {
          console.log(err);
        });
    }
  };
}

export function updateUserAccount(userId) {
  const req = {
    id: userId
  };
  return async (dispatch, getState) => {
    const user = await axios.post("/user/findUser", req);
    dispatch({
      type: types.UPDATE_USER,
      user: user.data
    });
  };
}

export function updateSelectedBlock(blockId, currentBlock, userId) {
  return async (dispatch, getState) => {
    const req = {
      id: userId,
      blockId: blockId
    };
    dispatch({
      type: types.UPDATE_USER,
      user: {
        block: {
          currentId: currentBlock,
          selectedId: blockId
        }
      }
    });
  };
}

export function updateTimezone(zone){
  return async (dispatch, getState) => {
    dispatch({
      type: types.UPDATE_TIMEZONE,
      timezone: zone
    });
  };
}

export function changeAccount(accountData) {
  return async (dispatch, getState) => {
    await axios
      .post("/user/updateAccount", accountData)
      .then(async person => {
        dispatch({
          type: types.UPDATE_USER,
          user: person.data
        });
        dispatch({
          type: types.ACCOUNT_STATUS,
          accountStatus: "updatedInformation"
        });
      })
      .catch(err => {
        switch (err.response.data) {
          case "Invalid Original Password":
            dispatch({
              type: types.ACCOUNT_STATUS,
              accountStatus: "invalidOriginalPassword"
            });
            break;
          case "Email already Used":
            dispatch({
              type: types.ACCOUNT_STATUS,
              accountStatus: "invalidUserEmail"
            });
            break;
          case "Error Saving User":
            dispatch({
              type: types.ACCOUNT_STATUS,
              accountStatus: "errorSavingUser"
            });
            break;
          case "Error Saving User Information":
            dispatch({
              type: types.ACCOUNT_STATUS,
              accountStatus: "errorSavingInfo"
            });
            break;
          case "No new information":
            dispatch({
              type: types.ACCOUNT_STATUS,
              accountStatus: "NoNewInfo"
            });
            break;
          case "No new password":
            dispatch({
              type: types.ACCOUNT_STATUS,
              accountStatus: "NoNewPassword"
            });
            break;
        }
      });
  };
}
