import initialState from "../initialState";
import types from "../actions/types";

export default function scheduleReducer(state = initialState.schedule, action) {
  switch (action.type) {
    case types.SCHEDULE_DATA:
      return {
        ...state,
        schedulePageData: action.schedule
      };
    case types.TEAMLIST_GET:
      return {
        ...state,
        teamList: action.teamList
      };

    default:
      return state;
  }
}
