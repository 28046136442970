import React, { useState, useEffect } from "react";
import { Button, Modal, Icon } from "semantic-ui-react";
import _ from "lodash";

function ResetModalConfirmation(props) {
  return (
    <Modal
      closeIcon
      open={props.isModalVisible}
      size="small"
      className="scrolling"
      onClose={() => {
        props.setIsModalVisible(false);
      }}
    >
      <Modal.Header content="Reset Layout"></Modal.Header>
      <Modal.Content>
        <h3>Are you sure you want to reset your home page layout?</h3>
      </Modal.Content>
      <Modal.Actions>
        <Button
          icon
          inverted
          color="red"
          size="small"
          onClick={() => {
            props.setIsModalVisible(false);
          }}
        >
          <Icon name="checkmark" />
          {" No"}
        </Button>
        <Button
          icon
          inverted
          color="green"
          size="small"
          onClick={() => {
            props.handleYes();
          }}
        >
          <Icon name="checkmark" />
          {" Yes"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default ResetModalConfirmation;
