export default {
  UPDATE_USER: "UPDATE_USER",
  UPDATE_BLOCK: "UPDATE_BLOCK",
  LOGOUT_USER: "LOGOUT_USER",
  UPDATE_USER_PERMS: "UPDATE_USER_PERMS",
  UPDATE_TIMEZONE: "UPDATE_TIMEZONE",

  SCHEDULE_DATA: "SCHEDULE_DATA",
  SAVE_SCHEDULE: "SAVE_SCHEDULE",
  HOMEPAGE_SCHEDULE_DATA: "HOMEPAGE_SCHEDULE_DATA",
  DELETE_SCHEDULE: "DELETE_SCHEDULE",
  TEAMLIST_GET: "TEAMLIST_GET",

  ACCOUNT_STATUS: "ACCOUNT_STATUS"
};
