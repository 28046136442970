import axios from "axios";
import config from "Config";
import * as jwt_decode from 'jwt-decode';
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { Sidebar } from "semantic-ui-react";
import useWindowSize from "../components/utilities/windowSize";
import { login } from "../redux-store/actions/userActions";
import "../styles/main.css";
import AccountContainer from "./accountContainer";
import AdminContainer from "./adminContainer";
import ErrorContainer from "./errorContainer";
import HeaderContainer from "./headerContainer";
import HomePageContainer from "./homePageContainer";
import LoginContainer from "./loginContainer";
import MeetingPageContainer from "./meetingPageContainer";
import SchedulePageContainer from "./schedulePageContainer";
import SideBarContainer from "./sideBarContainer";
import TeamContainer from "./teamContainer";
import TechLeadContainer from "./techLeadContainer";

const isMobile = window.innerWidth < 769 ? true : false;
let googleUser = {};

function AppContainer(props) {
  axios.defaults.baseURL = `${config.host}`;
  axios.defaults.withCredentials = true;

  const [user, setUser] = useState(null);
  const [loginStatus, setLoginStatus] = useState("Logging In....");
  const [sidebarVisible, setSidebarVisible] = useState(!isMobile);

  const size = useWindowSize();

  useEffect(() => {
    setupAxiosInterceptors();
    login();
  }, []);

  function setupAxiosInterceptors() {
    axios.interceptors.request.use(
      function(request) {
        const idToken = googleUser.getAuthResponse().id_token;
        const expiresAt = googleUser.getAuthResponse().expires_at;
        let currentTime = new Date().getTime() / 1000;

        if (idToken) {
          if (currentTime < parseInt(expiresAt, 10) / 1000 - 300) {
            request["headers"]["authorization"] = `Bearer ${idToken}`;
            request["headers"]["common"]["Accept"] = "application/json";

            return request;
          } else {
            googleUser.reloadAuthResponse().then(newResponse => {
              let newDecoded = jwt_decode(newResponse.id_token);
              const newDate = new Date(0);
              console.log("Getting new Auth");
              newDate.setUTCSeconds(newDecoded.exp);
              console.log(`JWT Expires at ++++++++++++++++ ${newDate}`);
            });

            request["headers"]["authorization"] = `Bearer ${idToken}`;
            request["headers"]["common"]["Accept"] = "application/json";
            return request;
          }
        }
        // Pass on response to caller
        return request;
      },
      function(error) {
        // Error on anything other than a 200
        // Hand back error response anyway
        return Promise.reject(error);
      }
    );
  }

  function login() {
    gapi.load("auth2", () => {
      gapi.auth2
        .init({
          client_id:
            "367879580774-j44r2e29nascnlpd0abkf8sfn9nmb05k.apps.googleusercontent.com",
          fetch_basic_profile: true,
          hosted_domain: "fenwaygroup.com"
        })
        .then(auth2 => {
          auth2
            .signIn()
            .then(user => {
              googleUser = user;
              axios
                .post("/auth/loginUser", {
                  email: user.getBasicProfile().getEmail()
                })
                .then(res => {
                  //Fills in Redux with User data from Google
                  props.login(res.data);
                  setUser(res.data);
                  checkAvatar(res.data);
                })
                .catch(err => {
                  console.log(err);
                  setLoginStatus(err.response.data);
                });
            })
            .catch(err => {
              setLoginStatus(err.error || err.reason);
            });
        });
    });
  }

  function checkAvatar(user) {
    const req = {
      image: googleUser.getBasicProfile().getImageUrl(),
      id: user.id
    };
    axios.post("/avatar/update", req);
  }

  function toggleSidebar() {
    setSidebarVisible(!sidebarVisible);
  }

  function closeSidebar() {
    setSidebarVisible(false);
  }

  return props.user.email && props.user.block.currentId ? (
    <>
      <HeaderContainer
        closeSidebar={closeSidebar}
        toggleSidebar={toggleSidebar}
      />

      <Sidebar.Pushable>
        <SideBarContainer
          sidebarVisible={sidebarVisible}
          closeSidebar={closeSidebar}
        />
        <Sidebar.Pusher
          dimmed={sidebarVisible && size.width <= 769}
          // opening/closing sidebar changes margin class
          className={sidebarVisible ? "sidebarMargin" : "sidebarNoMargin"}
        >
          <div className="mainLayout">
            <Switch>
              <Route exact path="/" render={() => <Redirect to="/home" />} />
              <Route
                exact
                path="/login"
                render={() => <Redirect to="/home" />}
              />
              <Route path="/home" component={HomePageContainer} />
              <Route path="/team" component={TeamContainer} />
              <Route path="/admin" component={AdminContainer} />
              <Route path="/meetings" component={MeetingPageContainer} />
              <Route path="/schedule" component={SchedulePageContainer} />
              <Route path="/account" component={AccountContainer} />
              <Route path="/techLead" component={TechLeadContainer} />
              <Route path="/*" component={ErrorContainer} />
            </Switch>
          </div>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </>
  ) : (
    <LoginContainer loginStatus={loginStatus} />
  );
}

const mapStateToProps = state => {
  return {
    user: state.user,
    loginStatus: state.loginStatus
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: user => {
      dispatch(login(user));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppContainer);
