import { combineReducers } from "redux";
import scheduleReducer from "./reducers/scheduleReducer";
import userReducer from "./reducers/userReducer";

const appReducer = combineReducers({
  user: userReducer,
  schedule: scheduleReducer
});

export default appReducer;
