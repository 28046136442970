import moment from "moment";
import React, { Component, useState, useEffect } from "react";
import { Button, Grid } from "semantic-ui-react";
import _ from "lodash";

function DateCol(props) {
  const sumDayHours = () => {
    //  find sum of hours for a particular day using same info as total weekly hours
    let sum = 0;
    if (props.dayInfo !== undefined) {
      _.forEach(props.dayInfo, dayInWeek => {
        if (dayInWeek.length !== 0 && dayInWeek[0].day === props.date) {
          _.forEach(dayInWeek, schedule => {
            sum += moment(schedule.endTime)
              .local()
              .diff(
                moment(schedule.startTime).local(),
                "minutes"
              );
          });
        }
      });
    }
    return Math.round((sum * 100) / 60) / 100;
  };

  const dayHours = sumDayHours();

  return (
    <Grid.Row className="dayRow">
      {/* Display the add button if you're in edit mode */}
      {props.locked ? (
        <div>
          <Button
            className="buttonLayout"
            circular
            inverted
            icon="plus"
            size="mini"
            onClick={() => props.showModal("Add", props.date, props.schedule)}
          />{" "}
        </div>
      ) : (
        ""
      )}
      <div data-cy="dayHours" style={{ paddingTop: "3px" }}>
        {props.date} - {dayHours} Hours
      </div>
    </Grid.Row>
  );
}

export default DateCol;
