import { createBrowserHistory } from "history";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import AppContainer from "./containers/appContainer";
import { store } from "./redux-store/store";
import "./styles/main.css";
// Leaving this note behind for future developers
// We were having issues building and pushing this style across
// As of Jan 30 2020 on some machines, building would not include this css otherwise
require("react-datepicker/dist/react-datepicker.css");

const history = createBrowserHistory();

function App() {
  return (
    <Router history={history}>
      <Provider store={store}>
        <AppContainer />
      </Provider>
    </Router>
  );
}

ReactDOM.render(<App />, document.getElementById("app"));
