import React, { Component } from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
// import "../../styles/modalBuilder.css";
import PropTypes from "prop-types";

// Instructions for use!
// Create and render an object like this:
//
// <ModalBuilder
//     title= Text to be displayed in the header
//     content= Things to display in the body, can be a variable containing jsx
//     type= "confirm" for a yes or no, otherwise it will have only an OK button that closes it
//     visible= Whether or not the modal is displayed
//     okFunction= Only needed for "confirm" modals, pass a function from the parent that is to be called when yes is clicked.
//     closeFunction= pass a function from the parent for closing the modal, mostly so you can update this.state
// />
//
// Don't forget to import ModalBuilder from this file

export default class ModalBuilder extends Component {
  closeModal = () => {
    this.props.closeFunction();
  };

  okFunction = () => {
    this.props.okFunction();
  };

  render() {
    return (
      <Modal
        closeIcon
        id="customModal"
        className="scrolling modal"
        size="tiny"
        open={this.props.visible}
        closeOnDocumentClick
        onClose={this.closeModal}
      >
        <Modal.Header>{this.props.title}</Modal.Header>
        <Modal.Content>{this.props.content}</Modal.Content>
        <Modal.Actions>
          {this.props.type === "confirm" ? (
            <Button
              size="small"
              icon
              inverted
              color="red"
              data-cy="noBtn"
              onClick={this.closeModal}
            >
              <Icon name="remove" />
              {" No"}
            </Button>
          ) : null}

          <Button
            icon
            inverted
            color="green"
            size="small"
            data-cy="multiPersonalityBtn"
            onClick={
              this.props.type === "confirm" ? this.okFunction : this.closeModal
            }
          >
            <Icon name="checkmark" />
            {this.props.type === "confirm" ? " Yes" : "OK"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
ModalBuilder.propTypes = {
  type: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.string,
  visible: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  okFunction: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  closeFunction: PropTypes.func
};
