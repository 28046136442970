import React, { useState, useEffect } from "react";
import { Grid } from "semantic-ui-react";
import TeamDateCol from "../teamPage/teamDateCol.jsx";
import TeamScheduleCol from "../teamPage/teamScheduleCol";

function TeamScheduleBuilder(props) {
  const [fullSchedule, setFullSchedule] = useState([]);
  const weekList = ["Saturday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

  useEffect(() => {
    rowBuilder(props);
  }, [props]);

  const rowBuilder = props => {
    //loops through the entire user schedule and pulls each schedule for the specified day
    //it then pushes the schedule into colList and once each day is made pushes its column
    //into fullSchedule
    let fullSchedule = [];
    let dayList = [];
    if (props.schedule) {
      for (let d in weekList) {
        for (let i in props.schedule.schedules) {
          if (props.schedule.schedules[i].day === weekList[d]) {
            dayList.push(props.schedule.schedules[i]);
          }
        }
      }
    }
    if (props.schedule) {
      for (let d in weekList) {
        let colList = [];
        colList.push(
          <TeamDateCol
            date={weekList[d]}
            dayInfo={props.schedule.schedules}
            key={0}
          />
        );
        for (let i in dayList) {
          if (dayList[i].day === weekList[d]) {
            colList.push(
              <TeamScheduleCol key={i + d * 10} schedule={dayList[i]} day={d} />
            );
          }
        }

        fullSchedule[d] = colList;
      }
      setFullSchedule(fullSchedule);
    }
  };

  return (
    <Grid stackable centered divided>
      <Grid.Column className="columnLayout" width="2">
        {fullSchedule[0]}
      </Grid.Column>
      <Grid.Column className="columnLayout" width="2">
        {fullSchedule[1]}
      </Grid.Column>
      <Grid.Column className="columnLayout" width="2">
        {fullSchedule[2]}
      </Grid.Column>
      <Grid.Column className="columnLayout" width="2">
        {fullSchedule[3]}
      </Grid.Column>
      <Grid.Column className="columnLayout" width="2">
        {fullSchedule[4]}
      </Grid.Column>
      <Grid.Column className="columnLayout" width="2">
        {fullSchedule[5]}
      </Grid.Column>
      <Grid.Column className="columnLayout" width="2">
        {fullSchedule[6]}
      </Grid.Column>
    </Grid>
  );
}

export default TeamScheduleBuilder;
