import axios from "axios";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Grid, Icon, Label, Message } from "semantic-ui-react";
import BlockDropDown from "../components/schedulePage/blockDropDown";
import ScheduleBuilder from "../components/schedulePage/scheduleBuilder.jsx";

function SchedulePageContainer(props) {
  const [totalUserHours, setTotalUserHours] = useState(0);
  const [locked, setLocked] = useState(true);
  const [approvalMessage, setApprovalMessage] = useState("approved");
  const [messageVisible, setMessageVisible] = useState(false);
  const [permanentScheduleData, setPermanentScheduleData] = useState([]);
  const [tempScheduleData, setTempScheduleData] = useState([]);

  useEffect(() => findScheduleList(), []);

  const findScheduleList = () => {
    let req = {
      id: props.user.id,
      blockId: props.user.block.selectedId
    };
    axios.post("/tempSchedule/findTempScheduleByUser", req).then(res => {
      setPermanentScheduleData(res.data.newScheduleList);
      setTempScheduleData(res.data.tempScheduleList);
      setApprovalMessage(
        res.data.tempScheduleList[props.user.block.selectedId]
          ? "awaiting"
          : "approved"
      );
    });
  };

  const toggleEdit = () => {
    setLocked(!locked);
  };

  const switchBlock = blockId => {
    setApprovalMessage(tempScheduleData[blockId] ? "awaiting" : "approved");
  };

  const sumHours = newSessionSchedule => {
    let sum = 0;
    _.forEach(newSessionSchedule, dayInWeek => {
      if (dayInWeek.length !== 0) {
        _.forEach(dayInWeek, schedule => {

          sum += moment(schedule.endTime)
            .local()
            .diff(
              moment(schedule.startTime).local(),
              "minutes"
            );
        });
      }
    });

    const hours = Math.round((sum * 100) / 60) / 100;
    setTotalUserHours(hours);
  };

  const handleApprovalMessage = () => {
    let retVal;
    switch (approvalMessage) {
      case "unapproved": {
        retVal = (
          <div>
            <Label className="approvalLabel" size="large" color="blue">
              <Icon name="x" />
              {props.user.userRole.role.toLowerCase() === "associate"
                ? "Unapproved"
                : "Unsaved"}
            </Label>
          </div>
        );
        break;
      }
      case "awaiting": {
        retVal = (
          <div>
            <Label className="approvalLabel" size="large" color="yellow">
              <Icon name="wait" />
              Awaiting Approval
            </Label>
          </div>
        );
        break;
      }
      case "approved": {
        retVal = (
          <div>
            <Label className="approvalLabel" size="large" color="green">
              <Icon name="checkmark" />
              {props.user.userRole.role.toLowerCase() === "associate"
                ? "Approved"
                : "Saved"}
            </Label>
          </div>
        );
        break;
      }
    }
    return retVal;
  };

  return (
    <div>
      <div>
        <h2 className="title">Schedule Page</h2>
      </div>
      <Grid centered stackable columns={3} id="scheduleInputs">
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h4>Status</h4>
            <div>{handleApprovalMessage()}</div>
          </Grid.Column>

          <Grid.Column textAlign="center">
            <h4>Block</h4>
            <BlockDropDown switchBlock={switchBlock} />
          </Grid.Column>
          <Grid.Column textAlign="center">
            <br />
            <Button inverted color="green" onClick={toggleEdit}>
              <Icon name="edit" />
              Edit
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <br />
      <ScheduleBuilder
        locked={!locked}
        totalUserHours={totalUserHours}
        tempScheduleData={tempScheduleData}
        sumHours={sumHours}
        permanentScheduleData={permanentScheduleData}
        canSubmit={approvalMessage === "unapproved"}
        modified={() => setApprovalMessage("unapproved")}
        submitted={() => {
          setApprovalMessage(
            props.user.userRole.role.toLowerCase() === "associate"
              ? "awaiting"
              : "approved"
          );
          setLocked(true);
        }}
      />
      {messageVisible ? (
        <Message
          style={{ display: "table", margin: "0 auto" }}
          positive
          compact
          attached="bottom"
          header="Your request has been submitted"
        />
      ) : (
        ""
      )}
    </div>
  );
}
const mapStateToProps = state => {
  return {
    user: state.user
  };
};

SchedulePageContainer.propTypes = {
  user: PropTypes.object
};

export default connect(mapStateToProps)(SchedulePageContainer);
