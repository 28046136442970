import axios from "axios";
import _ from "lodash";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Icon, Modal, Popup, Table } from "semantic-ui-react";
import ModalBuilder from "../adminPage/modalBuilder.jsx";
import TeamScheduleBuilder from "../teamPage/teamScheduleBuilder.jsx";

function ApprovalSection(props) {
  const [userList, setUserList] = useState([]);
  const [open, setOpen] = useState(false);
  const [approveAll, setApproveAll] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [sortedProperty, setSortedProperty] = useState({
    key: "name",
    direction: "asc"
  });

  useEffect(() => {
    getUser();
  }, [props.userList]);

  const getUser = () => {
    let userIds = _.map(props.userList, user => {
      return user.id;
    });

    axios
      .post("/tempSchedule/getAllTempSchedules", { users: userIds })
      .then(res => {
        for (let i of res.data) {
          i.schedule = JSON.parse(i.schedule);
        }
        setUserList(res.data);
        setSortedProperty(sortedProperty);
      });
  };

  const getTotalHours = user => {
    if (user.schedule) {
      let hours = 0;
      let diff = 0;
      for (let i of ["Saturday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"]) {
        for (let j of user.schedule[i]) {
          diff = moment(j.endTime)
          .local()
          .diff(moment(j.startTime).local(), "minutes");
          hours += diff;
        }
      }
      hours /= 60.0;
      return Math.round(hours * 100) / 100;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    sortBy();
  }, [sortedProperty]);

  const sortBy = () => {
    switch (sortedProperty.key) {
      case "name":
        setUserList(
          _.orderBy(
            userList,
            user => {
              return `${user.firstName} ${user.lastName}`;
            },
            [sortedProperty.direction]
          )
        );
        break;
      case "totalhours":
        setUserList(
          _.orderBy(
            _.map(userList, user => {
              user.totalHours = getTotalHours(user);
              return user;
            }),
            ["totalHours"],
            [sortedProperty.direction]
          )
        );
        break;
      case "block":
        setUserList(
          _.orderBy(userList, ["blockName"], [sortedProperty.direction])
        );
        break;
      default:
        setUserList(
          _.orderBy(
            userList,
            user => {
              return `${user.firstName} ${user.lastName}`;
            },
            [sortedProperty.direction]
          )
        );
        break;
    }
  };

  const hideModal = () => {
    setOpen(false);
    // Function to notify user of schedule approval
  };

  // Function to notify user of schedule approval
  // type = true for approval, type = false for denial
  const notifyUser = (user, approved) => {
    // Information to be sent with notification post
    const req = {
      id: user,
      message: approved
        ? "Your schedule has been approved."
        : "Your schedule has been denied."
    };
    axios.post("/notification/createNotification", req).then(res => {
      //   Send Email
    });
  };

  const convertFromTempSchedule = schedule => {
    //Converts a temp schedule into an array of objects formatted {startTime, endTime, day}
    if (schedule) {
      let scheduleArray = [];
      for (let i of ["Saturday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"]) {
        for (let j of schedule[i]) {
          j.day = i;
          scheduleArray.push(j);
        }
      }
      return scheduleArray;
    } else {
      return {};
    }
  };

  let reverseDirection = direction => {
    switch (direction) {
      case "asc":
        return "desc";
      case "desc":
        return "asc";
      default:
        return "asc";
    }
  };

  let convertDirectionSyntax = direction => {
    switch (direction) {
      case "asc":
        return "ascending";
      case "desc":
        return "descending";
      default:
        return "ascending";
    }
  };

  let key = 0;
  return (
    <div>
      <Table
        sortable
        celled
        id="approvalTable"
        verticalAlign="middle"
        textAlign="center"
        data-cy="approvalTable"
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell disabled>
              <h3>Approve Schedules</h3>
            </Table.HeaderCell>
            <Table.HeaderCell
              colSpan="2"
              className={userList.length <= 0 ? "disableCursorPointer" : ""}
            >
              <Button
                inverted
                color="green"
                disabled={userList.length === 0}
                disabled={!(userList.length > 0)}
                id="approveAll"
                data-cy="approveAll"
                className={
                  userList.length <= 0
                    ? "buttonsWidth disableCursorPointer"
                    : "buttonsWidth"
                }
                onClick={() => {
                  setApproveAll(true);
                }}
              >
                Approve All
              </Button>
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell
              className="columnHeader"
              onClick={() => {
                setSortedProperty({
                  key: "name",
                  direction:
                    sortedProperty.key !== "name"
                      ? "asc"
                      : reverseDirection(sortedProperty.direction)
                });
              }}
              sorted={
                sortedProperty.key === "name"
                  ? convertDirectionSyntax(sortedProperty.direction)
                  : undefined
              }
            >
              Name
            </Table.HeaderCell>
            <Table.HeaderCell
              className="columnHeader"
              onClick={() => {
                setSortedProperty({
                  key: "totalhours",
                  direction:
                    sortedProperty.key !== "totalhours"
                      ? "asc"
                      : reverseDirection(sortedProperty.direction)
                });
              }}
              sorted={
                sortedProperty.key === "totalhours"
                  ? convertDirectionSyntax(sortedProperty.direction)
                  : undefined
              }
            >
              {" "}
              Total Hours{" "}
            </Table.HeaderCell>
            <Table.HeaderCell
              onClick={() => {
                setSortedProperty({
                  key: "block",
                  direction:
                    sortedProperty.key !== "block"
                      ? "asc"
                      : reverseDirection(sortedProperty.direction)
                });
              }}
              sorted={
                sortedProperty.key === "block"
                  ? convertDirectionSyntax(sortedProperty.direction)
                  : undefined
              }
            >
              {" "}
              Block{" "}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {userList.length !== 0 ? (
            _.map(userList, user => (
              <Popup
                key={key++}
                trigger={
                  <Table.Row
                    className="rowLayout"
                    onClick={() => {
                      setOpen(true);
                      setSelectedUser(user);
                    }}
                  >
                    <Table.Cell>
                      {user.firstName + " " + user.lastName}
                    </Table.Cell>
                    <Table.Cell>{getTotalHours(user)}</Table.Cell>
                    <Table.Cell>{user.blockName}</Table.Cell>
                  </Table.Row>
                }
                position="right center"
              >
                Click to view and approve schedule
              </Popup>
            ))
          ) : (
            <Table.Row>
              <Table.Cell colSpan="3">
                There are no schedules that require approval at this time
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      {approveAll ? (
        <ModalBuilder
          closeIcon
          title="Approve All"
          content="Are you sure you want to approve all schedules?"
          type="confirm"
          visible
          okFunction={() => {
            for (let i of userList) {
              notifyUser(i.userId, true);
            }
            axios
              .post("tempSchedule/approveAllSchedules", {
                userIds: userList.map(item => item.userId)
              })
              .then(() => {
                setApproveAll(false);
                getUser();
              });
          }}
          closeFunction={() => {
            setApproveAll(false);
          }}
        />
      ) : null}
      {/* View Approval/Deny Modal */}
      <Modal
        closeIcon
        className="scrolling modal"
        open={open}
        closeOnDocumentClick
        onClose={hideModal}
        size="large"
      >
        <Modal.Header>
          {selectedUser.firstName + " " + selectedUser.lastName + "'s"} schedule
          for the {selectedUser.blockName} block - Total Hours:{" "}
          {getTotalHours(selectedUser)}
        </Modal.Header>
        <Modal.Content>
          <TeamScheduleBuilder
            schedule={{
              schedules: convertFromTempSchedule(selectedUser.schedule)
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            icon
            inverted
            color="red"
            size="small"
            floated="left"
            data-cy="denyBtn"
            onClick={() => {
              notifyUser(selectedUser.userId, false);
              axios
                .post("tempSchedule/denySchedule", {
                  user: selectedUser.userId,
                  block: selectedUser.blockId
                })
                .then(() => {
                  setOpen(false);
                  setSelectedUser({});
                  getUser();
                });
            }}
          >
            <Icon name="remove" />
            Deny
          </Button>
          <Button
            icon
            inverted
            color="green"
            size="small"
            data-cy="approveBtn"
            onClick={() => {
              notifyUser(selectedUser.userId, true);
              axios
                .post("tempSchedule/approveSchedule", {
                  user: selectedUser.userId,
                  block: selectedUser.blockId
                })
                .then(() => {
                  setOpen(false);
                  setSelectedUser({});
                  getUser();
                });
            }}
          >
            <Icon name="checkmark" />
            Approve
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}

export default withRouter(connect(null)(ApprovalSection));
