const initialState = {
  user: {
    firstName: "",
    lastName: "",
    email: "",
    userRole: {},
    accountStatus: "",
    block: {},
    timezone: {}
  },
  schedule: {
    schedulePageData: [],
    teamList: {}
  }
};

export default initialState;
