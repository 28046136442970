import moment from "moment";

const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
];

export const getSchedulePopupText = (schedule) => {
    // Takes in an individual schedule for one day.
    // Returns a string like: "9:00 PM Tue - 4:00 AM Wed"
    const startDay = daysOfWeek[moment(schedule.startTime).local().day()].substring(0, 3);
    const startTime = moment(schedule.startTime).local().format("LT");
    const endDay = daysOfWeek[moment(schedule.endTime).local().day()].substring(0, 3);
    const endTime = moment(schedule.endTime).local().format("LT");

    return [startTime, startDay, "-", endTime, endDay].join(" ");
}

export const isTimeOnDifferentDay = (schedule, location) => {
    // Takes in an individual schedule for one day.
    // Returns true if either the start or end time is
    // outside the 12:00AM-11:59PM range of the current local day.

    let startDayIndex = moment(schedule.startTime).local().day();
    let endDayIndex = moment(schedule.endTime).local().day();

    let comparisonDay = "";

    switch (location) {
        case "start":
            comparisonDay = daysOfWeek[startDayIndex];
            break;
        case "end":
            comparisonDay = daysOfWeek[endDayIndex];
            break;
    }

    if (schedule.day !== comparisonDay) {
        return true;
    }

    return false;
}