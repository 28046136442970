import { Menu, Button, Icon } from "semantic-ui-react";
import React, { useState } from "react";
import "../styles/mobileTabContainer.css";
import _ from "lodash";
import PropTypes from "prop-types";

function MobileTabContainer(props) {
  const [iterator, setIterator] = useState(0);

  const handleMenuChange = (e, data) => {
    for (const i in props.panes) {
      if (props.panes[i] === data.name) {
        setIterator(Number.parseInt(i));
      }
    }
  };

  let tabCount = 0;
  const mobilePanes = [];
  if (props.width > 415) tabCount = props.panes.length;
  else if (props.width >= 330 && props.width <= 415) tabCount = 4;
  else tabCount = 3;
  let startIndex = 0;
  let endIndex = 0;
  if (props.panes.length - tabCount < iterator - 1) {
    startIndex = props.panes.length - tabCount;
    endIndex = props.panes.length;
  } else {
    startIndex = iterator - 1;
    if (startIndex < 0) startIndex++;
    endIndex = startIndex + tabCount;
  }
  for (let i = startIndex; i < endIndex; i++) {
    mobilePanes.push(props.panes[i]);
  }

  return (
    <div id="mobilePanes">
      {props.width < 769 ? (
        <div>
          <Button
            onClick={() => {
              if (iterator) {
                setIterator(iterator - 1);
              }
            }}
          >
            <Icon name="angle left" size="large" />
          </Button>
          <Button
            className="rightButton"
            onClick={() => {
              if (iterator < props.panes.length - 1) {
                setIterator(iterator + 1);
              }
            }}
          >
            <Icon name="angle right" size="large" />
          </Button>
        </div>
      ) : null}
      <Menu tabular style={{ position: "fixed", backgroundColor: "#eeeeee" }}>
        {_.map(mobilePanes, itemName => {
          return (
            <Menu.Item
              name={itemName}
              key={itemName}
              active={props.panes[iterator] === itemName}
              onClick={handleMenuChange}
            />
          );
        })}
      </Menu>{" "}
      <div id="pageComponent">{props.componentList[iterator]}</div>
    </div>
  );
}

MobileTabContainer.propTypes = {
  panes: PropTypes.array,
  width: PropTypes.number,
  componentList: PropTypes.array
};

export default MobileTabContainer;
