import axios from "axios";
import _ from "lodash";
import React, { useEffect, useState, Fragment } from "react";
import RGL, { WidthProvider } from "react-grid-layout";
import { connect } from "react-redux";
import { Button, Icon, Popup } from "semantic-ui-react";
import "../../node_modules/react-grid-layout/css/styles.css";
import "../../node_modules/react-resizable/css/styles.css";
import { retrieveTeamList } from "../redux-store/actions/scheduleActions";
import WidgetToolbar from "../components/homePage/widgetToolbar";
import {
  Clock,
  EventSchedule,
  InNext,
  InOffice,
  MySchedule,
  OutOfOffice,
  MeetingWidget,
  Twitter
} from "../components/homePage/widgets";
import "../styles/homePage.css";
import ResetModalConfirmation from "../components/homepage/modals/confirmation";
import moment from 'moment-timezone';

var isMounted = false;

const ReactGridLayout = WidthProvider(RGL);
function HomePageContainer(props) {
  // layout is an array of objects, see the demo for more complete usage
  const [layout, setLayout] = useState([]);
  const [renderedComponents, setRenderedComponents] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isResetLayoutModalVisible, setIsResetLayoutModalVisible] = useState(
    false
  );
  const [refreshTimeout, setRefreshTimeout] = useState(null);
  const [clockConfigVisible, setClockConfigVisible] = useState(false)
  // state variables for the config to be passed
  
  

  const componentListDefault = {
    meetingWidget: {
      html: <MeetingWidget />,
      key: "meetingWidget",
      text: "Meetings",
      value: "meetingWidget",
      configurable: false
    },
    mySchedule: {
      key: "mySchedule",
      html: <MySchedule />,
      text: "My Schedule",
      value: "mySchedule",
      configurable: false

    },
    eventSchedule: {
      key: "eventSchedule",
      html: <EventSchedule currentUser={props.user.id} />,
      text: "Upcoming Events",
      value: "eventSchedule",
      configurable: false

    },
    twitter: {
      key: "twitter",
      html: <Twitter />,
      text: "Twitter",
      value: "twitter"
    },
    clock: {
      html: <Clock 
      configVisible={clockConfigVisible}
      />,
      key: "clock",
      text: "Clock",
      value: "clock",
      configurable: true,
      
    },
    inNext: {
      key: "inNext",
      html: (
        <InNext
          comingInNextList={props.schedule.teamList.comingInNextList || []}
        />
      ),
      text: "In Next",
      value: "inNext",
      configurable: false

    },
    inOffice: {
      key: "inOffice",
      html: (
        <InOffice inOfficeList={props.schedule.teamList.inOfficeList || []} />
      ),
      text: "In Office",
      value: "inOffice",
      configurable: false

    },
    outOfOffice: {
      key: "outOfOffice",
      html: (
        <OutOfOffice
          doneForDayList={props.schedule.teamList.doneForDayList || []}
        />
      ),
      text: "Out Of Office",
      value: "outOfOffice",
      configurable: false
    }
  };

  

  const layoutListDefault = [
    {
      w: 3,
      h: 3,
      x: 0,
      y: 0,
      i: "mySchedule",
      minW: 2,
      minH: 3,
      moved: false,
      static: false
    },
    {
      w: 5,
      h: 8,
      x: 0,
      y: 0,
      i: "teamWhereabouts",
      minW: 4,
      minH: 6
    },
    {
      w: 3,
      h: 2,
      x: 0,
      y: 0,
      i: "eventSchedule",
      minW: 2,
      minH: 1,
      moved: false,
      static: false
    },
    {
      w: 2,
      h: 2,
      x: 0,
      y: 0,
      i: "twitter",
      minW: 2,
      minH: 1,
      moved: false,
      static: false
    },
    {
      w: 2,
      h: 2,
      x: 0,
      y: 0,
      i: "meetingWidget",
      minW: 2,
      minH: 1,
      moved: false,
      static: false
    },
    {
      w: 3,
      h: 3,
      x: 0,
      y: 0,
      i: "clock",
      minW: 3,
      minH: 3,
    },
    {
      w: 4,
      h: 3,
      x: 0,
      y: 0,
      i: "inNext",
      minW: 2,
      minH: 2
    },
    {
      w: 4,
      h: 3,
      x: 0,
      y: 0,
      i: "inOffice",
      minW: 2,
      minH: 2
    },
    {
      w: 4,
      h: 3,
      x: 0,
      y: 0,
      i: "outOfOffice",
      minW: 2,
      minH: 2
    }
  ];

  useEffect(() => {
    isMounted = true;
    getTeamSchedule();
    return () => {
      isMounted = false;
      clearTimeout(refreshTimeout);
    };
  }, []);

  useEffect(() => {
    loadLayout(); // Once teamlist is loading from Redux
  }, [props.schedule.teamList]);

  useEffect(() => {
    let updateTime = props.schedule.teamList.updateTime;
    if (updateTime && updateTime > 0) {
      // at end of day updateTime is null and manual refresh is required
      setRefreshTimeout(setTimeout(() => getTeamSchedule(), updateTime));
    }
  }, [props.schedule.teamList.updateTime]);

  useEffect(() => {
    loadLayout();
  }, [clockConfigVisible])

  function loadLayout() {
    let req = {
      userId: props.user.id
    };
    axios.post("/lookup/getLayout", req).then(res => {
      if (window.innerWidth < 787) {
        res.data.layout = _.forEach(res.data.layout, singleLayout => {
          singleLayout.w = 12;
        });
      }
      if (isMounted) {
        findComponents(res.data.layout);
        setLayout(res.data.layout);
      }
    });
  }

  function findComponents(localLayout) {
    let newRenderedComponents = {};
    _.forEach(localLayout, singleLayout => {
      newRenderedComponents[singleLayout.i] =
        componentListDefault[singleLayout.i];
    });
    if (isMounted) {
      setRenderedComponents(newRenderedComponents);
    }
  }

  function saveLayout() {
    if (isMounted) {
      setIsEditMode(true);
    }
    let req = {
      userId: props.user.id,
      layout: layout
    };
    axios.post("/lookup/saveLayout", req);
  }

  function deleteLayout() {
    if (isMounted) {
      let req = { userId: props.user.id };
      axios.post("/lookup/deleteLayout", req).then(res => {
        if (window.innerWidth < 787) {
          res.data.layout = _.forEach(res.data.layout, singleLayout => {
            singleLayout.w = 12;
          });
        }
        if (isMounted) {
          findComponents(res.data.layout);
          setLayout(res.data.layout);
          setIsResetLayoutModalVisible(false);
        }
      });
    }
  }

  function removeComponent(event) {
    let newRenderedComponents = _.clone(renderedComponents);
    delete newRenderedComponents[event];
    if (isMounted) {
      setLayout(
        _.filter(layout, singleLayout => {
          return singleLayout.i !== event;
        })
      );
      setRenderedComponents(newRenderedComponents);
    }
  }

  function onLayoutChange(newLayout) {
    if (isMounted) {
      setLayout(newLayout);
    }
  }

  function addComponent(key) {
    if (renderedComponents[key] == undefined) {
      let newRenderedComponentList = renderedComponents;
      newRenderedComponentList[key] = componentListDefault[key];

      if (isMounted) {
      }
      let newLayoutList = _.clone(layout);
      let layoutToAdd = _.find(layoutListDefault, singleLayout => {
        return singleLayout.i === key;
      });
      if (isMounted) {
        setRenderedComponents(newRenderedComponentList);
        newLayoutList.push(layoutToAdd);

        setLayout(newLayoutList);
      }
    }
  }

  function getTeamSchedule() {
    // Project variable is only defined if a project is selected
    let projectList = props.user.projects;
    // // List will default to all the user's projects unless given a specific project
    const days = [
      "Sunday", // index 0
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday" // index 6
    ];
    const today = days[new Date().getDay()];
    const req = {
      id: projectList.id,
      projects: projectList,
      day: today,
      blockId: props.user.block.currentId,
      userId: props.user.id
    };
    // Retrieves TeamList and stores all three lists in redux
    props.retrieveTeamList(req);
  }

  function toggleConfig(component){
    if(component.value == "clock"){
      setClockConfigVisible(!clockConfigVisible)
    }
    else{
      console.log("Add config visible attribute to homepage and toggleConfig function")
    }
  }

  return (
    <Fragment>
      <ReactGridLayout
        items={20}
        layout={layout}
        cols={12}
        rowHeight={100}
        onLayoutChange={onLayoutChange}
        isDraggable={isEditMode}
        isResizable={isEditMode}
      >
        {_.map(renderedComponents, component => (
          <div key={component.key} className="paper">
            <div
              className={isEditMode ? "homePageComponentHeaderEditing" : null}
            >
              {isEditMode ? (
                <div className="widgetHeader">
                  {component.configurable ? //Adds a gear icon if the component is configurable
                    <Button
                    icon
                    className="cogBtn"
                    onClick={() => toggleConfig(component)}
                    >
                    <Icon name='cog' />
                    </Button> : null}
                  <strong className="editWidgetTitle">{component.text}</strong>
                  <button
                    className="closeButton"
                    onClick={() => {
                      removeComponent(component.key);
                    }}
                  >
                    <strong>X</strong>
                  </button>
                  
                </div>
              ) : null}
            </div>
            <div className="homePageContents">{component.html}</div>
          </div>
        ))}
      </ReactGridLayout>

      {isEditMode ? null : (
        <div className="homePagePopupContainer">
          <Popup
            content="Manage Widgets"
            position="top center"
            trigger={
              <Icon
                name="edit"
                size="large"
                id="homePagePopupBtn"
                onClick={() => {
                  if (isMounted) {
                    setIsEditMode(true);
                  }
                }}
              />
            }
          />
        </div>
      )}

      <div
        className={
          isEditMode
            ? "homePageLayoutActions animated active"
            : "homePageLayoutActions noHeight"
        }
      >
        <WidgetToolbar
          renderedComponentList={renderedComponents}
          addComponent={addComponent}
          componentListDefault={componentListDefault}
        />
        <br />
        <Button
          inverted
          color="green"
          onClick={() => {
            saveLayout();
            if (isMounted) {
              setIsEditMode(false);
              setClockConfigVisible(false);
            }
          }}
        >
          <Icon name="save" />
          Save Layout
        </Button>
        <Button
          inverted
          color="red"
          onClick={() => {
            loadLayout();
            setIsEditMode(false);
            setClockConfigVisible(false);
          }}
        >
          <Icon name="cancel" />
          Cancel
        </Button>
        <Button
          style={{ backgroundColor: "white", border: "grey 2px solid" }}
          onClick={() => {
            setIsResetLayoutModalVisible(true);
          }}
        >
          <Icon name="sync" />
          Reset to Default
        </Button>
      </div>

      <ResetModalConfirmation
        handleYes={deleteLayout}
        isModalVisible={isResetLayoutModalVisible}
        setIsModalVisible={setIsResetLayoutModalVisible}
      />
    </Fragment>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    retrieveTeamList: req => {
      dispatch(retrieveTeamList(req));
    }
  };
};

const mapStateToProps = state => {
  return {
    user: state.user,
    schedule: state.schedule
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePageContainer);
