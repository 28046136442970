import React, { useEffect } from "react";
import "../../../styles/homePage.css";
import { Sticky } from "semantic-ui-react";

function TwitterWidget() {
  useEffect(() => {
    twttr.widgets.createTimeline(
      {
        sourceType: "profile",
        screenName: "Fenway_Group"
      },
      document.getElementById("twitterFeed"),
      {
        chrome: "noheader noborders"
      }
    );
  });

  return (
    <div>
      <h2 className="title" style={{ position: "sticky" }}>
        Twitter
      </h2>
      <div id="twitterFeed"></div>
    </div>
  );
}

export default TwitterWidget;
