import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { List } from "semantic-ui-react";
import "../../../styles/homePage.css";

function MeetingWidget() {
  const [eventList, setEventList] = useState([]);
  let isLoaded = useRef(true);

  useEffect(() => {
    gapi.load("client:auth2", initGoogle);
    return () => {
      isLoaded.current = false;
    };
  }, []);
  // Gets today's google calendar events
  const initGoogle = () => {
    // let scope = "https://www.googleapis.com/auth/calendar.readonly";
    // let clientId =
    //   "367879580774-n74k2tgf0mjofjtksahq7tn3oi5tse68.apps.googleusercontent.com";
    let apiKey = "AIzaSyBbMhiwAiSCHbb5unwsM_hdDbcp4R26FI0";
    let discoverDoc = [
      "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
    ];
    if (isLoaded.current) {
      gapi.client
        .init({
          apiKey: apiKey,
          // clientId: clientId,
          // scope: scope,
          discoveryDocs: discoverDoc
        })
        .then(() => {
          // Start of the Day
          var start = new Date();
          start.setHours(0, 0, 0, 0);

          // End of the Day
          var end = new Date();
          end.setHours(23, 59, 59, 999);
          todayMeetingBuilder(start, end);
        });
    }
  };

  const todayMeetingBuilder = (start, end) => {
    let gEvents = [];
    if (isLoaded.current) {
      gapi.auth2.getAuthInstance().then(() => {
        try {
          gapi.client.calendar.events
            .list({
              calendarId: "primary",
              showDeleted: false,
              singleEvents: true,
              orderBy: "startTime",
              timeMin: start.toISOString(),
              timeMax: end.toISOString()
            })
            .then(response => {
              response.result.items.map(event => {
                gEvents.push({
                  title: event.summary,
                  start: moment(event.start.dateTime).local().format("LT"),
                  end: moment(event.end.dateTime).local().format("LT"),
                  googleEvent: true,
                  description: event.description,
                  allDay: event.start.dateTime ? false : true
                });
              });
              if (isLoaded.current) {
                setEventList(gEvents);
              }
            });
        } catch (err) {
          console.log(err);
        }
      });
    }
  };

  return (
    <div>
      <h2 className="title">Today's Meetings</h2>
      <List>
        {eventList.length > 0 ? (
          eventList.map(event => {
            return (
              <List.Item key={event.title}>
                {!event.allDay && (
                  <List.Content>
                    <h3>{event.title}</h3>
                    {event.start} - {event.end}
                  </List.Content>
                )}
              </List.Item>
            );
          })
        ) : (
          <div>There are no more meetings for today</div>
        )}
      </List>
    </div>
  );
}

export default MeetingWidget;
