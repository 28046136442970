import React, { useState, useEffect } from "react";
import "../../src/styles/teamPage.css";
import TeamScheduleBuilder from "../components/teamPage/teamScheduleBuilder.jsx";
import { Dropdown, Input, Grid, Message } from "semantic-ui-react";
import axios from "axios";
import _ from "lodash";
import { CSVLink } from "react-csv";
import moment from "moment-timezone";
import { AvatarComponent } from "../components/utilities/avatarComponent";

function TeamContainer() {
  const [projectList, setProjectList] = useState([]);
  const [project, setProject] = useState({ id: [], name: [] });

  const [block, setBlock] = useState({
    id: [],
    name: "Block",
    list: []
  });

  const [list, setList] = useState({
    techList: [],
    userList: [],
    filteredTechList: [],
    filteredUserList: [],
    scheduleList: []
  });

  const [data, setData] = useState([]);
  const [message, setMessage] = useState(null);

  const [pageSchedules, setPageSchedules] = useState({
    techSchedules: [],
    teamSchedules: []
  });

  useEffect(() => {
    getProjectList();
  }, []);

  useEffect(() => {
    getProjectUserList();
  }, [project.id]);

  useEffect(() => {
    if (
      (list.techList.length,
      list.userList.length,
      list.filteredTechList.length,
      list.filteredUserList.length,
      list.scheduleList.length !== 0)
    ) {
      getUserBlockList();
    }
  }, [list]);

  useEffect(() => {
    setTeamPage();
    prettySchedules();
  }, [
    list.techList,
    list.filteredTechList,
    list.filteredUserList,
    list.scheduleList
  ]);

  function getProjectList() {
    axios
      .get("/project/findProjectList/active")
      .then(res => {
        const allProj = { key: 0, text: "All Projects", value: 0 };
        let projectList = _.orderBy(
          _.map(res.data, project => {
            return {
              key: project.id,
              text: `${project.client.clientName} - ${project.projectName}`,
              value: project.id
            };
          }),
          ["text"],
          ["asc"]
        );
        projectList.unshift(allProj);
        setProjectList(projectList);
      })
      .catch(err => {
        console.log(err);
      });
  }

  function allOptionHandler(optionList) {
    // this will return data in the format for the dropDown Handler optionList
    if (optionList.value[0] === 0 && optionList.value.length !== 1) {
      optionList.value = optionList.value.slice(1);
    } else if (optionList.value[optionList.value.length - 1] == 0) {
      optionList.value = [0];
    }
    return optionList;
  }

  function dropDownHandler(optionList) {
    var selectedProjects = [];
    optionList.value.map(project => {
      const match = projectList.filter(projectId => projectId.value == project);
      selectedProjects.push(match[0].text);
    });
    setProject({ id: optionList.value, name: selectedProjects });
  }

  function getProjectUserList() {
    const req = {
      projectId: []
    };
    if (project.id[0] !== 0) {
      req.projectId = project.id;
    } else {
      for (let i in projectList) {
        if (projectList[i].value !== 0) {
          req.projectId.push(projectList[i].value);
        }
      }
    }
    if (project.id.length !== 0) {
      axios.post("/project/getUsersInProject", req).then(res => {
        console.log(req);
        console.log(res);
        setMessage(res.data.message ? res.data.message : null);
        let initSchedules = res.data.scheduleList;
        let usersX = res.data.userList;
        usersX = _.orderBy(usersX, ["lastName"], ["asc"]);
        axios.post("/role/findTeamTechLead", req).then(res => {
          const uList = _.differenceBy(usersX, res.data, "id"); //Finds users that aren't Tech Leads
          const tList = _.difference(usersX, uList); //Finds users that are Tech Leads
          let sortedUserList = [];
          let emptyUserList = [];
          let sortedLeadList = [];
          let emptyLeadList = [];

          for (let i = 0; i < uList.length; i++) {
            uList[i].scheduledHours =
              getUserHours(
                _.find(initSchedules, s => {
                  return s.id === uList[i].id;
                })
              ) || 0;
            if (uList[i].scheduledHours > 0) {
              sortedUserList.push(uList[i]);
            } else {
              emptyUserList.push(uList[i]);
            }
          }

          for (let i = 0; i < tList.length; i++) {
            tList[i].scheduledHours =
              getUserHours(
                _.find(initSchedules, s => {
                  return s.id === tList[i].id;
                })
              ) || 0;
            if (tList[i].scheduledHours > 0) {
              sortedLeadList.push(tList[i]);
            } else {
              emptyLeadList.push(tList[i]);
            }
          }
          sortedUserList = _.union(sortedUserList, emptyUserList);
          sortedLeadList = _.union(sortedLeadList, emptyLeadList);
          setList({
            techList: sortedLeadList,
            userList: sortedUserList,
            filteredTechList: sortedLeadList,
            filteredUserList: sortedUserList,
            scheduleList: initSchedules
          });
        });
        setBlock(prevState => {
          return { ...prevState, name: "Current Block(s)" };
        });
      });
    } else {
      setList({
        techList: [],
        userList: [],
        filteredTechList: [],
        filteredUserList: [],
        scheduleList: []
      });
      setBlock({
        id: [],
        name: "Block",
        list: []
      });
    }
  }

  function getUserBlockList() {
    let req = list.userList.concat(list.techList);
    axios
      .post("/block/getBlocksFromUsers", req)
      .then(res => {
        const currentBlockList = _.filter(res.data, block => {
          return moment(block.endDate).isAfter(moment().subtract(1, "days"));
        });
        const blockOptionList = _.map(currentBlockList, block => {
          return { key: block.id, text: block.name, value: block.id };
        });
        setBlock(prevState => {
          return { ...prevState, list: blockOptionList };
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  function getScheduleList(block) {
    const blockSelection = _.find(block.options, o => {
      return o.value === block.value;
    });
    const req = {
      userList: list.userList.concat(list.techList),
      blockId: blockSelection.value
    };
    axios
      .post("/schedule/getTeamPage", req)
      .then(res => {
        setList(prevState => {
          return { ...prevState, scheduleList: res.data };
        });
        setBlock({
          id: blockSelection.value,
          name: blockSelection.text
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  function setTeamPage() {
    let schedule = {};
    let techArray = [];
    let teamArray = [];
    techArray = _.map(list.filteredTechList, tech => {
      schedule = _.find(list.scheduleList, s => {
        return s.id === tech.id;
      });
      return (
        <div key={tech.id} className="teamScheduleBlock">
          <label className="userHeader">
            <div className="userName">
              <AvatarComponent
                image={tech.userAvatar ? tech.userAvatar.image : undefined}
              />
              {tech.firstName +
                " " +
                tech.lastName +
                ": " +
                (getUserHours(
                  _.find(list.scheduleList, s => {
                    return s.id === tech.id;
                  })
                ) || 0)}
              {" hours"}
            </div>
          </label>
          <div id="scheduleBlock">
            <TeamScheduleBuilder schedule={schedule} />
          </div>
        </div>
      );
    });
    teamArray = _.map(list.filteredUserList, user => {
      schedule = _.find(list.scheduleList, s => {
        return s.id === user.id;
      });
      return (
        <div key={user.id} className="teamScheduleBlock">
          <label className="userHeader">
            <div className="userName">
              <AvatarComponent
                image={user.userAvatar ? user.userAvatar.image : undefined}
              />
              {user.firstName +
                " " +
                user.lastName +
                ": " +
                (getUserHours(
                  _.find(list.scheduleList, s => {
                    return s.id === user.id;
                  })
                ) || 0)}
              {" hours"}
            </div>
          </label>
          <div className="scheduleBlock">
            <TeamScheduleBuilder schedule={schedule} />
          </div>
        </div>
      );
    });
    setPageSchedules({
      techSchedules: techArray,
      teamSchedules: teamArray
    });
  }

  function getUserHours(user) {
    let hours = 0;
    let diff = 0;
    if (user) {
      for (const i in user.schedules) {
        diff = moment(user.schedules[i].endTime)
          .local()
          .diff(
            moment(user.schedules[i].startTime).local(),
            "minutes"
          );
        hours = hours + diff;
      }
      hours = hours / 60.0;
      return Math.round(hours * 100) / 100;
    }
  }

  function getTotalHours() {
    let hours = 0;
    let diff = 0;
    if (list.scheduleList) {
      for (const i in list.scheduleList) {
        for (const j in list.scheduleList[i].schedules) {
          diff = moment(list.scheduleList[i].schedules[j].endTime).local()
            .diff(
              moment(
                list.scheduleList[i].schedules[j].startTime).local(),
              "minutes"
            );
          hours = hours + diff;
        }
      }
      hours = hours / 60.0;
      return Math.round(hours * 100) / 100;
    }
  }

  function filter(text) {
    const lowerText = text.toLowerCase();
    setList(prevState => {
      return {
        ...prevState,
        filteredTechList: list.techList.filter(user => {
          return (user.firstName + " " + user.lastName)
            .toLowerCase()
            .includes(lowerText);
        }),
        filteredUserList: list.userList.filter(user => {
          return (user.firstName + " " + user.lastName)
            .toLowerCase()
            .includes(lowerText);
        })
      };
    });
  }

  function prettySchedules() {
    const csvData = [];
    let rowArr = [];
    let header = [
      "First Name",
      "Last Name",
      "Email",
      "Saturday",
      "",
      "Sunday",
      "",
      "Monday",
      "",
      "Tuesday",
      "",
      "Wednesday",
      "",
      "Thursday",
      "",
      "Friday",
      ""
    ];
    let abbrs = {
      EST: "Eastern Standard Time",
      EDT: "Eastern Standard Time",
      CST: "Central Standard Time",
      CDT: "Central Standard Time",
      MST: "Mountain Standard Time",
      MDT: "Mountain Standard Time",
      PST: "Pacific Standard Time",
      PDT: "Pacific Standard Time"
    };
    const timezone =
      abbrs[
        moment
          .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
          .local()
          .format("zz")
      ];
    rowArr.push("Project:");
    rowArr.push(project.name);
    rowArr.push("");
    rowArr.push("Timezone:");
    rowArr.push(timezone);
    for (let i = 0; i < 9; i++) {
      rowArr.push("");
    }
    csvData.push(rowArr);
    csvData.push(header);

    const scheduleListJSON = JSON.parse(JSON.stringify(list.scheduleList));

    _.map(scheduleListJSON, user => {
      rowArr = [];
      let pushCount = 0;
      rowArr.push(user.firstName);
      rowArr.push(user.lastName);
      rowArr.push(user.email);

      if (_.isEmpty(user.schedules) == true) {
        rowArr.push("", "", "", "", "", "", "", "", "", "");
        csvData.push(rowArr);
      }
      while (_.isEmpty(user.schedules) !== true) {
        if (pushCount > 0) {
          rowArr = [];
          for (let i = 0; i < 3; i++) {
            rowArr.push("");
          }
        }
        //Loop through each day and print out the schedules
        let weekList = ["Saturday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
        let daySchedule = {};
        for (let day of weekList) {
          daySchedule = _.find(user.schedules, _.matchesProperty("day", day));
          if (daySchedule !== undefined) {
            rowArr.push(
              moment(daySchedule.startTime)
                .local()
                .format("LT")
            );
            rowArr.push(
              moment(daySchedule.endTime)
                .local()
                .format("LT")
            );
            _.remove(user.schedules, daySchedule);
          } else {
            rowArr.push("");
            rowArr.push("");
          }
        }
        pushCount += 1;
        csvData.push(rowArr);
      }
      csvData.push([]);
    });
    setData(csvData);
  }

  return (
    <div>
      <h2 className="title">Team Schedules</h2>
      <Grid id="teamGrid" centered stackable columns={3}>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h4>Project</h4>
            <Dropdown
              label=""
              placeholder="Select a Project"
              id="projectDropdown"
              className=".ui.dropdown meetingDropdownSize"
              multiple
              scrolling
              selection
              floating
              value={project.id}
              options={projectList}
              onChange={(e, data) => {
                dropDownHandler(allOptionHandler(data));
              }}
            />
          </Grid.Column>
          <Grid.Column textAlign="center">
            <h4>Block</h4>
            <Dropdown
              id="blockDropdown"
              className=".ui.dropdown"
              text={block.name}
              disabled={block.name === "Block"}
              onChange={(e, data) => getScheduleList(data)}
              options={block.list}
              scrolling
              selectOnBlur={false}
            />
          </Grid.Column>
          <Grid.Column textAlign="center">
            <h4>Filter</h4>
            <Input
              icon="search"
              id="teamScheduleFilter"
              style={{ width: "100%" }}
              placeholder="Filter By"
              maxLength="30"
              onChange={e => filter(e.target.value)}
              disabled={block.name === "Block"}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {block.name !== "Block" ? (
        <div>
          <h3 id="totalHours"> Total Team Hours: {getTotalHours()} </h3>
          <CSVLink
            id="downloadSchedules"
            className="ui medium button"
            style={
              block.name === "Block"
                ? {
                    opacity: ".45",
                    cursor: "not-allowed"
                  }
                : {
                    opacity: "1",
                    cursor: "pointer"
                  }
            }
            disabled={block.name === "Block"}
            data={data}
            filename={project.name + " - " + block.name + " Schedule.csv"}
          >
            Download Schedules
          </CSVLink>
          <br />
          {message ? (
            <Message
              style={{ display: "table", margin: "0 auto" }}
              negative
              compact
              content={message}
            ></Message>
          ) : null}
          <div>
            {pageSchedules.techSchedules.length > 0 ? ( // Checks if techlead is in list, and renders conditionally
              <div>
                <h3 className="sectionHeader">Tech Lead(s)</h3>
                {pageSchedules.techSchedules}
              </div>
            ) : null}
          </div>
          {pageSchedules.teamSchedules.length > 0 ? ( // if the list doesn't just consist of the techlead
            <div>
              <h3 className="sectionHeader" style={{ marginTop: "35px" }}>
                Team
              </h3>
              {pageSchedules.teamSchedules}
              <br />
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

export default TeamContainer;
