import React, { Component, useState, useEffect } from "react";
import UserTab from "../components/adminPage/userTab";
import ProjectTab from "../components/adminPage/projectTab";
import ClientTab from "../components/adminPage/clientTab";
import BlockTab from "../components/adminPage/blockTab";
import COETab from "../components/adminPage/coeTab";
import HolidayTab from "../components/adminPage/holidayTab";
import BulkTab from "../components/adminPage/bulkTab";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { Message } from "semantic-ui-react";
import MobileTabContainer from "./mobileTabContainer";
const AdminPage = props => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
  }, []);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const panes = []; // pushes on only panes that the user has permission to view
  const componentList = [];
  if (props.user.userRole.adminClientsPerm > 0) {
    panes.push("Clients");
    componentList.push(<ClientTab />);
  }
  if (props.user.userRole.adminCOEPerm > 0) {
    panes.push("COE");
    componentList.push(<COETab />);
  }
  if (props.user.userRole.adminUsersPerm > 0) {
    panes.push("Users");
    componentList.push(<UserTab />);
  }
  if (props.user.userRole.adminProjectsPerm > 0) {
    panes.push("Projects");
    componentList.push(<ProjectTab />);
  }

  if (props.user.userRole.adminBlocksPerm > 0) {
    panes.push("Blocks");
    componentList.push(<BlockTab />);
  }
  if (props.user.userRole.adminHolidayPerm > 0) {
    panes.push("Holidays");
    componentList.push(<HolidayTab />);
  }
  if (props.user.userRole.role === "Admin") {
    panes.push("Bulk Update");
    componentList.push(<BulkTab />);
  }

  if (props.user.userRole.adminUsersPerm == 1) {
    return (
      <div>
        <div
          className="ui message"
          style={{
            fontSize: "3 em",
            textAlign: "center",
            fontWeight: "bold"
          }}
        >
          NOTE: You only have view permissions on admin pages.
        </div>
        <div>
          <MobileTabContainer
            width={width}
            panes={panes}
            componentList={componentList}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <MobileTabContainer
          width={width}
          panes={panes}
          componentList={componentList}
        />
      </div>
    );
  }
};

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

AdminPage.propTypes = {
  user: PropTypes.object
};

export default withRouter(connect(mapStateToProps)(AdminPage));
