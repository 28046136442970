import React, { Component } from "react";
import {
  Button,
  Header as SemanticHeader,
  Icon,
  Modal
} from "semantic-ui-react";
import _ from "lodash";

const MaxHourModal = props => {
  return (
    <div>
      <Modal
        open={props.maxHourModalVisible}
        onClose={props.handleMaxHourClose}
        size="small"
        className="scrolling conflictModal"
      >
        <SemanticHeader>Over Maximum Hours</SemanticHeader>
        <Modal.Content>
          <h3>
            You have scheduled more than your planned hours. Please click Cancel
            and adjust to match your planned hours.
          </h3>
        </Modal.Content>
        <Modal.Actions>
          <Button
            style={{ backgroundColor: "#CE0E2D", color: "white" }}
            size="small"
            icon
            primary
            onClick={props.handleMaxHourClose}
          >
            <Icon name="remove" />
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default MaxHourModal;
