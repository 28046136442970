import axios from "axios";
import _ from "lodash";
import React, { useState, useEffect } from "react";
import {
  Button,
  Icon,
  Input,
  Checkbox,
  Modal,
  Table,
  Form,
  Label
} from "semantic-ui-react";
import "../../styles/adminTabs.css";
import ModalBuilder from "./modalBuilder";
import { connect } from "react-redux";
import PropTypes from "prop-types";

function ClientTab(props) {
  const [addClientModalVisible, setAddClientModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [newClientName, setNewClientName] = useState("");
  const [clientList, setClientList] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteClientId, setDeleteClientId] = useState(
    props.user.userRole.adminClientsPerm
  );
  const [deleteClientName, setDeleteClientName] = useState(null);
  const [clientAssignedToProject, setClientAssignedToProject] = useState(false);
  const [editable, setEditable] = useState(
    props.user.userRole.adminClientsPerm > 1
  );
  const [filterBy, setFilterBy] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const [displayedList, setDisplayedList] = useState([]);
  const [showInactiveChecked, setShowInactiveChecked] = useState(false);
  const [sortAscending, setSortAscending] = useState(true);
  const [clientEdit, setClientEdit] = useState({});
  const [modal, setModal] = useState("");
  const [nameError, setNameError] = useState("");

  useEffect(() => {
    getClientList();
  }, []);

  useEffect(() => {
    if (sortAscending) {
      filter(filterBy);
    } else {
      sort(sortAscending);
    }
  }, [clientList]);

  useEffect(() => {
    updateDisplayedClients();
  }, [showInactiveChecked, filteredList]);

  const handleSubmit = () => {
    if (newClientName === "") {
      setErrorMessage("Please Enter Client Name");
    } else {
      const req = {
        clientName: newClientName
      };
      axios.post("/client/addClient", req).then(res => {
        if (res.data === "Client Created") {
          setAddClientModalVisible(false);
          getClientList();
        }
        if (res.data !== "Client Created") {
          setErrorMessage(res.data);
        }
      });
    }
  };

  const handleDelete = () => {
    axios.post("/client/deleteClient", { id: deleteClientId }).then(res => {
      if (res.status === 204) {
        // if the client is assigned to project, delete will be prevented
        setClientAssignedToProject(true);
        setDeleteModalVisible(false);
      }
      getClientList();
    });
    hideModal();
  };

  const editClient = client => {
    setClientEdit(client);
    setModal("editClient");
  };

  const showModal = () => {
    setAddClientModalVisible(true);
  };

  const closeModal = () => {
    setModal(null);
    setNameError("");
  };

  const hideModal = () => {
    setAddClientModalVisible(false);
    setDeleteModalVisible(false);
    setClientAssignedToProject(false);
    setErrorMessage("");
    setNewClientName("");
  };

  // populates table with db data
  const getClientList = () => {
    axios.get("/client/getClientList").then(res => {
      setClientList(res.data);
      setDisplayedList(res.data);
    });
  };

  const changeClient = req => {
    axios.post("/client/changeClient", req).then(res => {
      getClientList();
    });
  };

  const showDeleteModal = client => {
    setDeleteModalVisible(true);
    setDeleteClientId(client.id);
    setDeleteClientName(client.clientName);
  };

  const sort = sortASC => {
    const newClientList = clientList;
    newClientList.sort((a, b) => {
      return a.clientName.toLowerCase() > b.clientName.toLowerCase() ? 1 : -1;
    });
    if (!sortASC) newClientList.reverse();
    setClientList(newClientList);
    filter(filterBy);
  };

  const filter = text => {
    if (text === "") {
      setFilterBy("");
      setFilteredList(clientList);
    } else {
      setFilterBy(text);
      setFilteredList(
        clientList.filter(item => {
          return item.clientName.toLowerCase().includes(text.toLowerCase());
        })
      );
    }
  };

  function updateDisplayedClients() {
    if (showInactiveChecked) setDisplayedList(filteredList)
    else {
      let activeList = filterActiveClients(filteredList);
      setDisplayedList(activeList);
    }
  }

  function filterActiveClients(clients) {
    return clients.filter( client => client.active );
  }

  const handleModalSave = () => {
    setNameError("");
    if (editable) {
      let validForm = true;
      if (clientEdit.clientName === "") {
        setNameError("Enter Client Name");
        validForm = false;
      } else if (
        _.find(clientList, client => {
          return (
            client.id !== clientEdit.id &&
            client.clientName === clientEdit.clientName
          );
        })
      ) {
        setNameError("Client Already Exists");
        validForm = false;
      }

      if (validForm) {
        axios.post("/client/changeClient", clientEdit).then(res => {
          const newList = clientList.slice();
          for (const i in newList) {
            if (res.data.id == newList[i].id) {
              newList[i] = res.data;
              setClientList(newList);
              break;
            }
          }
          closeModal();
        });
      }
    } else {
      closeModal();
    }
  };

  return (
    <div>
      <div>
        <h2 className="title">Client Table</h2>
      </div>
      <br />
      <div className="aboveTable" id="clientTableHeader">
        <Button
          inverted
          color="green"
          onClick={showModal}
          size="medium"
          className="ui.medium.button"
          disabled={!editable}
        >
          Add Client
        </Button>
        <Checkbox
          label="Show inactive clients"
          checked={showInactiveChecked}
          onChange={() => setShowInactiveChecked(!showInactiveChecked)}
        />
        <Input
          icon="search"
          placeholder="Filter By"
          maxLength="30"
          onChange={e => filter(e.target.value)}
        />
      </div>
      <br />

      {/* Add Client Modal */}
      <Modal
        id="clientModal"
        closeIcon
        open={addClientModalVisible}
        size="tiny"
        className="scrolling modal"
        closeOnDocumentClick
        onClose={hideModal}
      >
        <Modal.Header>Add Client</Modal.Header>
        <Modal.Content>
          <Form size="small">
            <Form.Group widths="equal">
              <Form.Field>
                <Form.Input
                  required
                  label="New Client Name"
                  placeholder="New Client Name"
                  maxLength="30"
                  error={errorMessage !== ""}
                  onBlur={e => setNewClientName(e.target.value.trim())}
                  onChange={() => setErrorMessage("")}
                />
                {errorMessage === "" ? null : (
                  <Label pointing color="red">
                    {errorMessage}
                  </Label>
                )}
              </Form.Field>
              <Form.Field />
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            icon
            inverted
            color="green"
            size="small"
            onClick={handleSubmit}
          >
            <Icon name="checkmark" /> Create
          </Button>
        </Modal.Actions>
      </Modal>

      {/* Delete Modal */}
      <Modal
        closeIcon
        id="deleteModal"
        className="scrolling modal"
        size="tiny"
        open={deleteModalVisible}
        closeOnDocumentClick
        onClose={hideModal}
      >
        <Modal.Header>Delete Client</Modal.Header>
        <Modal.Content>
          <p>
            Are you sure you want to delete{" "}
            <span className="boldText">{deleteClientName}</span>?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            size="small"
            icon
            inverted
            color="red"
            data-cy="noBtn"
            onClick={hideModal}
          >
            <Icon name="remove" />
            {" No"}
          </Button>
          <Button
            icon
            inverted
            color="green"
            size="small"
            onClick={handleDelete}
          >
            <Icon name="checkmark" />
            {" Yes"}
          </Button>
        </Modal.Actions>
      </Modal>

      {/* Error:Projects assiciated with client */}
      <ModalBuilder
        closeIcon
        title="Error"
        content="There is one or more projects associated to this client preventing you from deleting. If you wish to delete, you must delete the project(s) or associate to another client."
        type="notification"
        visible={clientAssignedToProject}
        closeFunction={hideModal}
      />

      {/* Client Table */}
      <Table
        sortable
        celled
        id="table"
        verticalAlign="middle"
        textAlign="center"
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              className="columnHeader"
              sorted={sortAscending ? "ascending" : "descending"}
              onClick={() => {
                sort(!sortAscending);
                setSortAscending(!sortAscending);
              }}
            >
              Client
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(displayedList, client => (
            <Table.Row
              key={client.id}
              className="rowLayout"
              onClick={() => editClient(client)}
            >
              <Table.Cell>{client.clientName}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      {modal !== "editClient" ? null : (
        <Modal
          closeIcon
          id="editClientModal"
          className="ui dimmable dimmed scrolling modal editModal"
          size="tiny"
          open
          closeOnDocumentClick
          onClose={closeModal}
        >
          <Modal.Header>
            {editable ? "Edit Client" : "View Client"}
          </Modal.Header>
          <Modal.Content>
            <Form size="small" className="login-form">
              <Form.Group widths="equal">
                <Form.Field>
                  <Form.Input
                    disabled={!editable}
                    data-cy="clientName"
                    required
                    label="Client Name"
                    maxLength="50"
                    defaultValue={clientEdit.clientName}
                    error={nameError !== ""}
                    onChange={(e, data) => {
                      const newClient = { ...clientEdit };
                      newClient.clientName = data.value;
                      setClientEdit(newClient);
                      setNameError("");
                    }}
                  />
                  {nameError === "" ? null : (
                    <Label pointing color="red">
                      {nameError}
                    </Label>
                  )}
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field>
                  <Form.Checkbox
                    id="active"
                    data-cy="activeCheckbox"
                    disabled={!editable}
                    defaultChecked={clientEdit.active}
                    label="Active"
                    onChange={(e, data)  => {
                      let newClient = { ...clientEdit };
                      newClient.active = data.checked;
                      setClientEdit(newClient);
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Modal.Content>
          {editable ? (
            <Modal.Actions>
              <Button
                icon
                inverted
                color="green"
                size="small"
                onClick={() => {
                  handleModalSave();
                }}
              >
                <Icon name="checkmark" />
                Save
              </Button>
            </Modal.Actions>
          ) : null}
        </Modal>
      )}
    </div>
  );
}

ClientTab.propTypes = {
  user: PropTypes.object
};
const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(ClientTab);
