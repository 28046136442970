import React from "react";
import "../../styles/schedulePage.css";

const TotalHours = props => {
  function remainingHours() {
    const hours = Math.floor(100*(props.maxUserHours - props.totalUserHours))/100;
    if (hours > 0) {
      return <h3 className="hoursRemaining">Hours Remaining: {hours}</h3>;
    } else if (hours < 0) {
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h3 className="hoursRemaining">Hours Remaining: </h3>{" "}
          <h3 className="hoursRemaining" id="exceededHours">
            {hours}
          </h3>
        </div>
      );
    }
  }

  return (
    <div>
      <h3 id="totalHours" data-cy="totalHours">
        Hours Scheduled: {props.totalUserHours}
      </h3>
      {remainingHours()}
    </div>
  );
};

export default TotalHours;
