import moment from "moment";
import React from "react";
import { Button, Icon, Modal } from "semantic-ui-react";

const DeleteScheduleModal = props => {
  return (
    <Modal
      closeIcon
      id="deleteModal"
      open={props.deleteModalVisible}
      className="scrolling modal"
      size="tiny"
      closeOnDocumentClick
      onClose={props.handleDeleteClose}
    >
      <Modal.Header>Delete Schedule</Modal.Header>
      <Modal.Content>
        <p>
          Are you sure you want to delete{" "}
          <span className="boldText">
            {props.schedule.day} from{" "}
            {moment(props.schedule.start)
              .local()
              .format("LT")}{" "}
            -{" "}
            {moment(props.schedule.stop)
              .local()
              .format("LT")}{" "}
          </span>
          ?
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          size="small"
          icon
          inverted
          color="red"
          data-cy="noBtn"
          onClick={props.handleDeleteClose}
        >
          <Icon name="remove" />
          {" No"}
        </Button>
        <Button
          icon
          inverted
          color="green"
          size="small"
          onClick={props.handleScheduleDelete}
        >
          <Icon name="checkmark" />
          {" Yes"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default DeleteScheduleModal;
