import axios from "axios";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Button, Grid, Message } from "semantic-ui-react";
import shortid from "shortid";
import DateCol from "../schedulePage/dateCol";
import AddEditModal from "./modals/AddEditModal";
import ConflictModal from "./modals/ConflictModal";
import DeleteScheduleModal from "./modals/DeleteScheduleModal";
import MaxHourModal from "./modals/MaxHourModal";
import PasteButton from "./pasteButton";
import ScheduleCol from "./scheduleCol";
import TotalHours from "./totalHours";
import Hotkeys from "react-hot-keys";

class ScheduleBuilder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hoveredSchedule: null,
      hoveredDate: null,
      fullSchedule: [],
      visible: false,
      editMode: false,
      pasteMode: false,
      pasteShow: false,
      deleteModalVisible: false,
      maxHourModalVisible: false,
      conflictModalVisible: false,
      conflictScheduleArray: [],
      conflictModalMessage: "",
      date: "",
      schedule: {
        id: "",
        start: "",
        stop: ""
      },
      copiedSchedule: {
        id: "",
        start: "",
        stop: ""
      },
      startTimeErrorMessage: "",
      endTimeErrorMessage: "",
      weekList: ["Saturday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
      totalUserHours: this.props.totalUserHours,
      sessionSchedule: [[], [], [], [], [], [], []]
    };
  }

  saveKeyDown() {
    //copies the currently hovered schedule
    if (this.state.hoveredSchedule != null && this.props.locked)
      this.setState(
        {
          copiedSchedule: {
            id: this.state.hoveredSchedule.id,
            start: this.state.hoveredSchedule.startTime,
            stop: this.state.hoveredSchedule.endTime
          },
          pasteShow: true
        },
        () => {
          this.rowBuilder();
        }
      );
  }

  pasteKeyDown() {
    // Paste the copied schedule over the currently hovered shift with ctrl-v
    if (
      this.props.locked &&
      this.state.pasteShow &&
      this.state.hoveredSchedule !== null
    ) {
      let dayIndex = _.indexOf(this.state.weekList, this.state.hoveredSchedule.day);
      let sessionCopy = _.clone(this.state.sessionSchedule);
      sessionCopy[dayIndex] = _.filter(sessionCopy[dayIndex], shift => shift.id !== this.state.hoveredSchedule.id)
      this.setState({sessionSchedule: sessionCopy})
      this.pasteSchedule(this.state.hoveredSchedule.day);
    }
  }

  hoverHandler = sched => {
    // Used to save a schedule that is being hovered with the copy hotkey
    this.setState({
      hoveredSchedule: sched
    });
  };

  componentDidMount() {
    this.chooseSchedule();
  }

  componentDidUpdate(prevProps, nextState) {
    if (
      prevProps.user.block.selectedId !== this.props.user.block.selectedId ||
      !_.isEqual(
        this.props.permanentScheduleData,
        prevProps.permanentScheduleData
      ) ||
      !_.isEqual(this.props.tempScheduleData, prevProps.tempScheduleData)
    ) {
      this.chooseSchedule();
    }
    if (this.props.locked !== prevProps.locked) {
      this.rowBuilder();
    }
  }

  chooseSchedule = () => {
    let localSchedule = _.find(
      _.cloneDeep(this.props.tempScheduleData),
      block => {
        return block[0].block.id == this.props.user.block.selectedId;
      }
    );

    if (localSchedule !== undefined) {
      // If you change this you will have to change the structure of the entire temp schedule code to match and work correctly.
      let parsedSchedule = JSON.parse(localSchedule[0].schedule);
      localSchedule = [];
      _.map(parsedSchedule, (dayOfWeek, index) => {
        dayOfWeek.map(schedule => {
          if (!schedule.id) {
            schedule.id = shortid.generate();
            schedule.day = index;
          }
        });
        localSchedule.push(dayOfWeek);
      });

      this.setState(
        {
          sessionSchedule: localSchedule
        },
        () => {
          this.rowBuilder();
          this.props.sumHours(this.state.sessionSchedule);
        }
      );
    } else {
      localSchedule = _.find(
        _.cloneDeep(this.props.permanentScheduleData),
        block => {
          return block.blockId == this.props.user.block.selectedId;
        }
      );
      if (localSchedule !== undefined) {
        this.setState({ sessionSchedule: localSchedule.scheduleList }, () => {
          this.rowBuilder();
          this.props.sumHours(this.state.sessionSchedule);
        });
      } else {
        this.setState({ sessionSchedule: [[], [], [], [], [], [], []] }, () => {
          this.rowBuilder();
          this.props.sumHours(this.state.sessionSchedule);
        });
      }
    }
  };

  verifyTime = callback => {
    const startTime = this.state.schedule.start;
    const stopTime = this.state.schedule.stop;
    let startTimeErrorMessage = "";
    let endTimeErrorMessage = "";
    const interval = Math.abs(
      moment(stopTime)
        .local()
        .diff(moment(startTime).local(), "minutes")
    );
    if (interval < 90 && interval >= 0) {
      endTimeErrorMessage =
        "Selected interval must be at least 90 minutes long.";
    }
    if (
      moment(startTime)
        .local()
        .isAfter(moment(stopTime).local())
        && moment(stopTime).format("hh:mm a") != "12:00 am"
    ) {
      startTimeErrorMessage = "Start time cannot come after end time.";
    }   
    this.setState(
      {
        startTimeErrorMessage: startTimeErrorMessage,
        endTimeErrorMessage: endTimeErrorMessage
      },
      () => {
        callback();
      }
    );
  };

  handleConfirm = () => {
    if (
      this.state.startTimeErrorMessage != "" ||
      this.state.endTimeErrorMessage != ""
    ) {
      this.setState({
        visible: true
      });
    }
    if (this.state.conflictModalVisible) {
      this.setState({
        visible: false
      });
    } else if (
      this.state.startTimeErrorMessage == "" &&
      this.state.endTimeErrorMessage == ""
    ) {
      this.setState(
        {
          visible: false
        },
        () => {
          this.getConflictArray();
          this.props.modified();
        }
      );
    }
  };

  getConflictArray = () => {
    let newSessionSchedule = _.clone(this.state.sessionSchedule);
    let dayIndex = _.indexOf(this.state.weekList, this.state.date);

    //To determine if you are editing
    let currentSchedule = _.find(this.state.sessionSchedule[dayIndex], {
      id: this.state.schedule.id
    });

    let listToDelete = _.filter(newSessionSchedule[dayIndex], schedule => {
      return (
        (moment(this.state.schedule.start)
          .local()
          .diff(moment(schedule.startTime).local()) >=
          0 &&
          moment(this.state.schedule.start)
            .local()
            .diff(moment(schedule.endTime).local()) <=
            0) ||
        (moment(this.state.schedule.stop)
          .local()
          .diff(moment(schedule.startTime).local()) >=
          0 &&
          moment(this.state.schedule.stop)
            .local()
            .diff(moment(schedule.endTime).local()) <=
            0) ||
        ((moment(this.state.schedule.start)
          .local()
          .diff(moment(schedule.startTime).local()) <=
          0 &&
          moment(this.state.schedule.stop)
            .local()
            .diff(moment(schedule.endTime).local()) >=
            0) ||
          (moment(this.state.schedule.start)
            .local()
            .diff(moment(schedule.startTime).local()) >=
            0 &&
            moment(this.state.schedule.stop)
              .local()
              .diff(moment(schedule.endTime).local()) <=
              0))
      );
    });

    if (
      currentSchedule !== undefined &&
      !this.state.pasteMode &&
      this.state.editMode
    ) {
      listToDelete = _.filter(listToDelete, schedule => {
        return schedule.id !== currentSchedule.id;
      });
    }

    if (
      listToDelete.length === 0 || //If you are adding //(listToDelete.length > 0 &&
      (this.state.editMode === false &&
        this.state.pasteMode &&
        listToDelete.length === 0) //To determine if you are pasting
    ) {
      this.postTime();
    } else {
      this.showConflictModal(listToDelete);
    }
  };

  postTime = () => {
    //If you are adding or pasting
    let dayIndex = _.indexOf(this.state.weekList, this.state.date);
    let newSessionSchedule = _.cloneDeep(this.state.sessionSchedule);

    if (!this.state.editMode || this.state.pasteMode) {
      newSessionSchedule[dayIndex].push({
        id: shortid.generate(),
        startTime: this.state.schedule.start,
        endTime: this.state.schedule.stop,
        day: this.state.date
      });
    } else {
      // Find the schedule. Update its data. At the end update the state
      newSessionSchedule[dayIndex] = _.map(
        newSessionSchedule[dayIndex],
        schedule => {
          // Return the one we want to update
          if (schedule.id === this.state.schedule.id) {
            return {
              id: schedule.id,
              startTime: this.state.schedule.start,
              endTime: this.state.schedule.stop,
              day: this.state.date
            };
            // Return the original
          } else {
            return schedule;
          }
        }
      );
    }
    newSessionSchedule[dayIndex].sort((a, b) => {
      return moment(a.startTime)
        .local()
        .diff(moment(b.startTime).local());
    });

    this.setState({ sessionSchedule: newSessionSchedule }, () => {
      this.rowBuilder();
      this.props.sumHours(this.state.sessionSchedule);
    });
  };

  pasteSchedule = day => {
    const startDayOffset = 1;
    const stopDayOffset = (moment(this.state.copiedSchedule.stop).format("hh:mm a") === "12:00 am") ? 2 : 1;
    this.setState(
      prevState => ({
        schedule: {
          ...prevState.copiedSchedule,
          start: moment(this.state.copiedSchedule.start).set('date', _.indexOf(this.state.weekList, day) + startDayOffset),
          stop: moment(this.state.copiedSchedule.stop).set('date', _.indexOf(this.state.weekList, day) + stopDayOffset),
          id: this.state.copiedSchedule.id,
          day: day
        },
        date: day,
        editMode: false,
        pasteMode: true
      }),
      () => {
        this.verifyTime(this.handleConfirm);
      }
    );
  };

  submitScheduleForApproval = () => {
    //I have structured tempSchedules differently than regular schedules. NZ
    //If you want to know how and why, go to TempScheduleController.ts
    if (
      this.props.totalUserHours > this.props.user.maxHours &&
      this.props.user.userRole.role.toLowerCase() === "associate"
    ) {
      this.showMaxHourModal();
    } else {
      let tempSchedule = {
        Saturday: [],
        Sunday: [],
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: []
      };

      //Converting schedules into the tempSchedule format
      for (let k in this.state.sessionSchedule) {
        for (let j of this.state.sessionSchedule[k]) {
          tempSchedule[this.state.weekList[k]].push({
            startTime: moment(j.startTime, ["hh:mm Z", "YYYY-MM-DD HH:mm:ssZ"])
              .local()
              .format("YYYY-MM-DD HH:mm:ssZ"),
            endTime: moment(j.endTime, ["hh:mm Z", "YYYY-MM-DD HH:mm:ssZ"])
              .local()
              .format("YYYY-MM-DD HH:mm:ssZ")
          });
        }
      }

      //Send temp schedule to be saved.
      let scheduleReq = {
        user: this.props.user.id,
        block: this.props.user.block.selectedId,
        schedule: JSON.stringify(tempSchedule)
      };

      axios.post("tempSchedule/createTempSchedule", scheduleReq).then(res => {
        this.props.submitted();
      });

      //Send notification to tech lead.
      const projectList = [];
      for (const i in this.props.user.projects) {
        projectList.push(this.props.user.projects[i].id);
      }
      const message =
        this.props.user.firstName +
        " " +
        this.props.user.lastName +
        " has requested schedule approval.";
      const req = {
        id: projectList,
        message: message
      };

      this.setState({pasteShow: false});
      this.rowBuilder();
      // axios.post("notification/createTechLeadNotification", req).then(res => {
      //   socket.emit("updateTechNotifications", res);
      // });
    }
  };

  rowBuilder = () => {
    // loops through the entire user schedule and pulls each schedule for the specified day
    // it then pushes the schedule into colList and once each day is made pushes its column
    // into fullSchedule
    const fullSchedule = [];
    let newKey = 0;
    for (let d in this.state.weekList) {
      const colList = [];
      colList.push(
        <DateCol
          date={this.state.weekList[d]}
          schedule={this.state.schedule}
          dayInfo={this.state.sessionSchedule}
          locked={this.props.locked}
          showModal={this.showModal}
          key={newKey++}
        />
      );
      for (const i in this.state.sessionSchedule[d]) {
        colList.push(
          <ScheduleCol
            hoverHandler={this.hoverHandler}
            key={newKey++}
            date={this.state.weekList[d]}
            singleSchedule={this.state.sessionSchedule[d][i]}
            locked={this.props.locked}
            verifyTime={this.verifyTime}
            handleConfirm={this.handleConfirm}
            day={d}
            showModal={this.showModal}
          />
        );
      }
      //Paste button after schedules
      colList.push(
        <PasteButton
          locked={this.props.locked}
          pasteShow={this.state.pasteShow}
          key={newKey++}
          date={this.state.weekList[d]}
          pasteSchedule={this.pasteSchedule}
        />
      );
      fullSchedule[d] = colList;
    }
    this.setState({ fullSchedule });
  };

  /** ---------------------------------MODAL CODE------------------------------------ */

  //Calls when changing the start time
  onStartChange = time => {
    let timeStart = moment(time.formatted, ["HH:mm Z", "h:mm a", "h:m a"]);

    // Convert to first week of Jan 2000
    let weekdays = ["Saturday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
    timeStart = moment(timeStart).set({'year': 2000, 'month': 0, 'date': (weekdays.indexOf(this.state.date))+1 });

    this.setState(prevState => ({
      schedule: {
        ...prevState.schedule,
        start: timeStart
      },
      startTimeErrorMessage: ""
    }));
  };

  //Calls when changing the stop time
  onStopChange = time => {
    let timeStop = moment(time.formatted, ["HH:mm Z", "h:m a", "h:m a"]);

    // Convert to first week of Jan 2000
    let weekdays = ["Saturday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
    timeStop = moment(timeStop).set({'year': 2000, 'month': 0, 'date': (weekdays.indexOf(this.state.date))+1 });

    // add a day if endTime is midnight
    if (timeStop.format("hh:mm a") == "12:00 am"){
      timeStop = timeStop.add(1, "day");
    }

    // Add 1 second to stop time for round number differences
    timeStop = timeStop.add(1, "second");
    
    this.setState(prevState => ({
      schedule: {
        ...prevState.schedule,
        stop: timeStop
      },
      startTimeErrorMessage: ""
    }));
  };

  copiedSchedule = () => {
    this.setState(
      {
        copiedSchedule: {
          start: this.state.schedule.start,
          stop: this.state.schedule.stop,
          id: this.state.schedule.id
        },
        visible: false,
        editMode: false,
        pasteShow: true
      },
      () => {
        this.rowBuilder();
      }
    );
  };

  showModal = (type, date, schedule) => {
    // Convert to first week of Jan 2000
    let weekdays = ["Saturday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
    let startTime = moment().set({'year': 2000, 'month': 0, 'date': (weekdays.indexOf(date))+1, 'hour': 8, 'minute': 0, 'second': 0 });
    let stopTime = moment().set({'year': 2000, 'month': 0, 'date': (weekdays.indexOf(date))+1, 'hour': 17, 'minute': 0, 'second': 1 });

    // add a day if endTime is midnight
    if (moment(stopTime).format("hh:mm a") == "12:00 am"){
      stopTime = moment(stopTime).local().add(1, "day");
    }

    type === "Edit"
      ? this.setState({
          editMode: true,
          visible: true,
          pasteMode: false,
          date,
          schedule
        })
      : this.setState(prevState => ({
          editMode: false,
          visible: true,
          pasteMode: false,
          date,
          schedule: {
            ...prevState.schedule,
            start: startTime,
            stop: stopTime
          }
        }));
  };

  showConflictModal = conflictArray => {
    // update our State with the results of getConflictArray()
    this.setState({
      conflictScheduleArray: conflictArray
    });
    // getConflictArray() found exactly 1 conflicting schedule
    if (conflictArray.length === 1) {
      this.setState({
        conflictModalVisible: true,
        conflictModalMessage:
          "There is a conflict with the specified schedule, this conflict is:"
      });
    } else {
      // getConflictArray() found more than 1 conflicting schedule
      this.setState({
        conflictModalVisible: true,
        conflictModalMessage:
          "There are conflicts with the specified schedule, these conflicts are:"
      });
    }
  };

  showMaxHourModal = () => {
    this.setState({
      maxHourModalVisible: true,
      visible: false
    });
  };

  showDeleteModal = () => {
    this.setState({
      visible: false,
      editMode: false,
      deleteModalVisible: true
    });
  };

  handleConflictDelete = () => {
    let dayIndex = _.indexOf(this.state.weekList, this.state.date);
    let newSessionSchedule = _.clone(this.state.sessionSchedule);

    newSessionSchedule[dayIndex] = _.filter(newSessionSchedule[dayIndex], o => {
      return (
        _.findIndex(this.state.conflictScheduleArray, schedule => {
          return schedule.id === o.id;
        }) === -1
      );
    });

    this.setState(
      {
        sessionSchedule: newSessionSchedule,
        conflictModalVisible: false
      },
      () => {
        this.postTime();
      }
    );
  };

  handleScheduleDelete = () => {
    let dayIndex = _.indexOf(this.state.weekList, this.state.date);
    let newSessionSchedule = { ...this.state.sessionSchedule };
    newSessionSchedule[dayIndex] = _.filter(
      newSessionSchedule[dayIndex],
      schedule => {
        return this.state.schedule.id !== schedule.id;
      }
    );

    this.setState(
      {
        visible: false,
        editMode: false,
        deleteModalVisible: false,
        sessionSchedule: newSessionSchedule
      },
      () => {
        this.rowBuilder();
        this.props.sumHours(this.state.sessionSchedule);
        this.props.modified();
      }
    );
  };

  handleCancel = e => {
    if (e.target.tagName !== "LI" && e.target.tagName !== "INPUT") {
      //This prevents clicking on the timepickers from closing the modal
      this.setState({
        visible: false,
        editMode: false,
        startTimeErrorMessage: "",
        endTimeErrorMessage: ""
      });
    }
  };

  handleConflictClose = () => {
    this.setState({ conflictModalVisible: false });
  };

  handleDeleteClose = () => {
    this.setState({ visible: false, deleteModalVisible: false });
  };

  // executed when a user selects cancel on the maxHour modal
  handleMaxHourClose = () => {
    this.setState({
      maxHourModalVisible: false
    });
  };

  render() {
    return (
      <Fragment>
        <Hotkeys keyName="ctrl+c" onKeyDown={this.saveKeyDown.bind(this)} />
        <Hotkeys keyName="ctrl+v" onKeyDown={this.pasteKeyDown.bind(this)} />
        <Grid
          stackable
          centered
          divided
          id="scheduleGrid"
          style={{ margin: "0" }}
        >
          <Grid.Column data-cy="Saturday" className="columnLayout" width="2">
            {this.state.fullSchedule[0]}
          </Grid.Column>
          <Grid.Column data-cy="Sunday" className="columnLayout" width="2">
            {this.state.fullSchedule[1]}
          </Grid.Column>
          <Grid.Column data-cy="Monday" className="columnLayout" width="2">
            {this.state.fullSchedule[2]}
          </Grid.Column>
          <Grid.Column data-cy="Tuesday" className="columnLayout" width="2">
            {this.state.fullSchedule[3]}
          </Grid.Column>
          <Grid.Column data-cy="Wednesday" className="columnLayout" width="2">
            {this.state.fullSchedule[4]}
          </Grid.Column>
          <Grid.Column data-cy="Thursday" className="columnLayout" width="2">
            {this.state.fullSchedule[5]}
          </Grid.Column>
          <Grid.Column data-cy="Friday" className="columnLayout" width="2">
            {this.state.fullSchedule[6]}
          </Grid.Column>
        </Grid>
        <br />
        <TotalHours
          totalUserHours={this.props.totalUserHours}
          maxUserHours={this.props.user.maxHours}
        />
        <br />
        <Button
          onClick={this.submitScheduleForApproval}
          id="scheduleSubmitButton"
          disabled={!this.props.canSubmit}
          inverted
          color="green"
        >
          Submit Schedule
        </Button>
        <br />

        {this.state.copiedSchedule.start && this.state.pasteShow && this.props.locked ? (
          <Message
            style={{ display: "table", margin: "0 auto", textAlign: "center" }}
            positive
            compact
            attached="bottom"
            header="Shift Copied!"
            content={
              moment(this.state.copiedSchedule.start)
                .local()
                .format("LT") +
              " - " +
              moment(this.state.copiedSchedule.stop)
                .local()
                .format("LT")
            }
          />
        ) : (
          ""
        )}
        <br />
        <AddEditModal
          visible={this.state.visible}
          editMode={this.state.editMode}
          handleCancel={this.handleCancel}
          verifyTime={this.verifyTime}
          handleConfirm={this.handleConfirm}
          onStartChange={this.onStartChange}
          onStopChange={this.onStopChange}
          schedule={this.state.schedule}
          copiedSchedule={this.copiedSchedule}
          showDeleteModal={this.showDeleteModal}
          date={this.state.date}
          startTimeErrorMessage={this.state.startTimeErrorMessage}
          endTimeErrorMessage={this.state.endTimeErrorMessage}
        />
        <ConflictModal
          conflictModalVisible={this.state.conflictModalVisible}
          conflictScheduleArray={this.state.conflictScheduleArray}
          conflictModalMessage={this.state.conflictModalMessage}
          handleConflictDelete={this.handleConflictDelete}
          handleConflictClose={this.handleConflictClose}
          date={this.props.date}
        />
        <DeleteScheduleModal
          deleteModalVisible={this.state.deleteModalVisible}
          schedule={this.state.schedule}
          handleScheduleDelete={this.handleScheduleDelete}
          handleDeleteClose={this.handleDeleteClose}
        />
        <MaxHourModal
          maxHourModalVisible={this.state.maxHourModalVisible}
          startTimeErrorMessage={this.state.startTimeErrorMessage}
          handleMaxHourClose={this.handleMaxHourClose}
        />
      </Fragment>
    );
  }
}

ScheduleBuilder.propTypes = {
  user: PropTypes.object
};

const mapStateToProps = state => {
  return {
    user: state.user,
    schedule: state.schedule
  };
};

export default connect(mapStateToProps)(ScheduleBuilder);
