import moment from "moment";
import React, { useEffect } from "react";
import { Grid, Popup } from "semantic-ui-react";
import PropTypes from "prop-types";

import { getSchedulePopupText, isTimeOnDifferentDay } from '../utilities/timeRangeDisplay'
function TeamScheduleCol(props) {
  useEffect(() => {}, [props]);

  return (
    <div>
      {props.schedule ? (
        <Popup
          position="bottom center"
          trigger={
            <Grid.Row className="dateColRowLayout">
              <div style={ isTimeOnDifferentDay(props.schedule, "start") ? { fontWeight: 'bold' } : { fontWeight: 'normal' } } >
                {moment(props.schedule.startTime).local().format(
                  "LT"
                )}
              </div>
              {" "}
              -{" "}
              <div style={ isTimeOnDifferentDay(props.schedule, "end") ? { fontWeight: 'bold' } : { fontWeight: 'normal' } } >
                {moment(props.schedule.endTime).local().format(
                  "LT"
                )}
              </div>
            </Grid.Row>
          }
          content={getSchedulePopupText(props.schedule)}
        />
      ) : (
        <Grid.Row className="hiddenRow">
          <div>BLANK ROW</div>
        </Grid.Row>
      )}
    </div>
  );
}

TeamScheduleCol.propTypes = {
  schedule: PropTypes.object
};

export default TeamScheduleCol;
