import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Input, Label, Table, Checkbox } from "semantic-ui-react";
import Axios from "axios";
import UserForm from "./userForm";
import { updateUserAccount } from "../../redux-store/actions/userActions";
import _ from "lodash";

import "../../styles/userTab.css";

function UserTab(props) {
  const [userList, setUserList] = useState([]);
  const [activeList, setActiveList] = useState([]);
  const [showInactiveChecked, setShowInactiveChecked] = useState(false);
  const [sortedList, setSorted] = useState([]);
  const [sortInfo, setSortInfo] = useState({ column: "name", sortASC: true });
  const [filteredList, setFilteredList] = useState([]);
  const [filterBy, setFilterBy] = useState("");
  const [openForm, setOpenForm] = useState(true);
  const [editUser, setEditUser] = useState({});

  useEffect(() => {
    getUserList();
  }, []);

  useEffect(() => {
    sort(sortInfo);
  }, [activeList, sortInfo]);

  useEffect(() => {
    filter(filterBy);
  }, [sortedList, filterBy]);

  useEffect(() => {
    setOpenForm(!openForm);
  }, [editUser]);

  useEffect(() => {
    updateActiveList();
  }, [showInactiveChecked]);

  function getUserList() {
    Axios.get("/user/findAllUsers").then(res => {
      res.data.map(user => {
        user.isLoggedIn = user.id === props.id ? true : false;
      });

      setUserList(res.data);
      setActiveList(filterActiveUsers(res.data));
    });
  }

  function filter(text) {
    if (text === "") setFilteredList(sortedList);
    else {
      let lowText = text.toLowerCase();
      let fList = sortedList.filter(user => {
        let userProjects = "";
        for (let proj of user.projects) userProjects += proj.projectName;
        return (
          `${user.firstName} ${user.lastName}`
            .toLowerCase()
            .includes(lowText) ||
          user.coe.name.toLowerCase().includes(lowText) ||
          userProjects.toLowerCase().includes(lowText)
        );
      });
      setFilteredList(fList);
    }
  }

  function updateActiveList() {
    if (showInactiveChecked) setActiveList(userList);
    else {
      let aList = filterActiveUsers(userList);
      setActiveList(aList);
    }
  }

  function filterActiveUsers(users) {
    return users.filter(user => { return user.active; });
  }

  function sortColumn(column) {
    let sortASC = sortInfo.column === column ? !sortInfo.sortASC : true;
    setSortInfo({ column, sortASC });
  }

  function sort() {
    let newUserList = _.cloneDeep(activeList);
    switch (sortInfo.column) {
      case "COE":
        newUserList.sort((a, b) => {
          return a.coe.name.toLowerCase() > b.coe.name.toLowerCase() ? 1 : -1;
        });
        break;
      case "project":
        newUserList.sort((a, b) => {
          let projsA = [],
            projsB = [];
          for (const i of a.projects) projsA.push(i);
          for (const i of b.projects) projsB.push(i);
          return projsA.length > projsB.length ? 1 : -1;
        });
        break;
      default:
        newUserList.sort((a, b) => {
          return `${a.firstName} ${a.lastName}`.toLowerCase() >
            `${b.firstName} ${b.lastName}`.toLowerCase()
            ? 1
            : -1;
        });
    }

    if (!sortInfo.sortASC) newUserList.reverse();

    setSorted(newUserList);
  }

  function isUserInList(user) {
    return userList.find(i => i.email === user.email && i.id !== user.id);
  }
  
  return (
    <div>
      <div>
        <h2 className="title">User Table</h2>
      </div>
      <br />
      <div className="aboveTable" id="userTableHeader">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            id="addUserButton"
            data-cy="addUserButton"
            inverted
            color="green"
            onClick={() => setEditUser({})}
            disabled={!(parseInt(props.user.userRole.adminUsersPerm) > 1)}
          >
            Add User
          </Button>
          <Checkbox
            label="Show inactive users"
            checked={showInactiveChecked}
            onChange={() => setShowInactiveChecked(!showInactiveChecked)}
          />
          <Input
            icon="search"
            placeholder="Filter By"
            maxLength="30"
            onChange={e => setFilterBy(e.target.value)}
          />
        </div>
        <br />
        <Table sortable celled verticalAlign="middle" textAlign="center">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                className="columnHeader"
                onClick={() => sortColumn("name")}
                sorted={
                  sortInfo.column === "name"
                    ? sortInfo.sortASC
                      ? "ascending"
                      : "descending"
                    : null
                }
              >
                Name
              </Table.HeaderCell>
              <Table.HeaderCell
                className="columnHeader"
                onClick={() => sortColumn("COE")}
                sorted={
                  sortInfo.column === "COE"
                    ? sortInfo.sortASC
                      ? "ascending"
                      : "descending"
                    : null
                }
              >
                COE
              </Table.HeaderCell>
              <Table.HeaderCell
                className="columnHeader"
                onClick={() => sortColumn("project")}
                sorted={
                  sortInfo.column === "project"
                    ? sortInfo.sortASC
                      ? "ascending"
                      : "descending"
                    : null
                }
              >
                Project
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {filteredList.map(user => (
              <Table.Row
                key={user.id}
                className="rowLayout"
                onClick={() => setEditUser(user)}
              >
                <Table.Cell content={`${user.firstName} ${user.lastName}`} />
                <Table.Cell content={`${user.coe.name}`} />
                <Table.Cell>
                  {user.projects.map(p => (
                    <Label
                      horizontal
                      size="large"
                      key={p.id}
                      content={p.projectName}
                    />
                  ))}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
      <UserForm
        open={openForm}
        closeCallback={setEditUser}
        user={editUser}
        updateListCallback={getUserList}
        isUserInList={isUserInList}
      />
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    updateUserAccount: userId => {
      dispatch(updateUserAccount(userId));
    }
  };
};

const mapStateToProps = state => {
  return {
    id: state.user.id,
    user: state.user
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserTab);
