import moment from "moment";
import PropTypes from "prop-types";
import React, { Component, useState, useEffect } from "react";
import { Grid, Popup } from "semantic-ui-react";

import { getSchedulePopupText, isTimeOnDifferentDay } from '../utilities/timeRangeDisplay'

function ScheduleCol(props) {
  const [locked, setLocked] = useState(props.locked);
  const [selectedSchedule, setSelectedSchedule] = useState({
    id: "",
    day: "",
    start: moment("08:00:00", "HH:mm Z")
      .local()
      .format("LT"),
    stop: moment("08:00:00", "HH:mm Z")
      .local()
      .format("LT")
  });

  useEffect(() => {
    setLocked(props.locked);
  }, [props.locked]);

  useEffect(() => {
    if (selectedSchedule.id) {
      props.showModal("Edit", selectedSchedule.day, selectedSchedule);
    }
  }, [selectedSchedule]);

  const displayModal = schedule => {
    // Displays the delete modal if not locked out
    if (locked) {
      setSelectedSchedule({
        id: schedule.id,
        day: schedule.day,
        start: schedule.startTime,
        stop: schedule.endTime
      });
    }
  };

  return (
    <div
      onMouseOver={() => props.hoverHandler(props.singleSchedule)}
      onMouseOut={() => props.hoverHandler(null)}
    >
      {props.singleSchedule ? (
        <Popup
          position="bottom center"
          trigger={
            <Grid.Row
              className={props.locked ? "schEditLayout" : "schRowLayout"}
              onClick={() => {
                displayModal(props.singleSchedule);
              }}
            >
                <div style={ isTimeOnDifferentDay(props.singleSchedule, "start") ? { fontWeight: 'bold' } : { fontWeight: 'normal' } } >
                  {moment(props.singleSchedule.startTime).local().format(
                    "LT"
                  )}
                </div>
                {" "}
                -{" "}
                <div style={ isTimeOnDifferentDay(props.singleSchedule, "end") ? { fontWeight: 'bold' } : { fontWeight: 'normal' } } >
                  {moment(props.singleSchedule.endTime).local().format(
                    "LT"
                  )}
                </div>
            </Grid.Row>
          }
          content={getSchedulePopupText(props.singleSchedule)}
        />
      ) : (
        <Grid.Row className="hiddenRow">
          <div>BLANK ROW</div>
        </Grid.Row>
      )}
    </div>
  );
}

ScheduleCol.propTypes = {
  week: PropTypes.array,
  day: PropTypes.string
};

export default ScheduleCol;
