import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Menu, Sidebar } from "semantic-ui-react";
import TroubleTicketModal from "../components/troubleTicketPage/troubleTicketModal";
import "../styles/sidebar.css";

function SideBarContainer(props) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [trouble, setTrouble] = useState(null);

  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleOnClick = index => {
    if (window.innerWidth < 769) {
      props.closeSidebar();
    }
    setActiveItem(index);
    setDropdownOpen(false);
  };

  const handleTrouble = () => {
    trouble.open();
  };

  // sidebar default tabs
  let columns = [{ name: "Home", link: "/home", cypressName: "home" }];
  // shows the admin tab if userRole is Admin

  if (props.user.userRole.schedulePerm > 0) {
    columns.push({
      name: "Schedule",
      link: "/schedule",
      cypressName: "scheduleTab"
    });
  }

  if (props.user.userRole.teamPerm > 0) {
    columns.push({ name: "Team", link: "/team", cypressName: "team" });
  }
  if (props.user.userRole.meetingsPagePerm > 0) {
    columns.push({
      name: "Meetings",
      link: "/meetings",
      cypressName: "meetings"
    });
  }
  if (props.user.userRole.id === 3 || props.user.userRole.id === 2) {
    columns.push({
      name: "Tech Lead",
      link: "/techLead"
    });
  }
  if (
    props.user.userRole.adminUsersPerm > 0 ||
    props.user.userRole.adminProjectsPerm > 0 ||
    props.user.userRole.adminBlocksPerm > 0 ||
    props.user.userRole.adminClientsPerm > 0 ||
    props.user.userRole.adminHolidayPerm > 0
  ) {
    columns.push({ name: "Admin", link: "/admin" });
  }

  const items = columns.map((d, index) => {
    return (
      <Menu.Item
        as={Link}
        key={index}
        data-cy={d.cypressName}
        onClick={() => handleOnClick(index)}
        className={
          index === activeItem
            ? "dropdown-item sidebarActiveItem"
            : "dropdown-item"
        }
        to={d.link}
      >
        {d.name}
      </Menu.Item>
    );
  });

  const newitem = [
    { name: "Account", link: "/account", cypressName: "account" }
  ];
  const newItem = newitem.map((e, index) => {
    return (
      <Menu.Item
        as={Link}
        key={index}
        className={
          activeItem === 99
            ? "dropdown-item sidebarActiveItem"
            : "dropdown-item"
        }
        to={e.link}
        onClick={() => handleOnClick(99)}
      >
        {e.name}
      </Menu.Item>
    );
  });

  return (
    <Sidebar
      as={Menu}
      animation="overlay"
      icon="labeled"
      inverted
      direction="left"
      vertical
      visible={props.sidebarVisible}
      width="thin"
    >
      {items}
      <div id="mobileSidebar">
        {/* Account*/}
        {newItem}
      </div>

      <TroubleTicketModal
        onRef={ref => {
          setTrouble(ref);
        }}
      />
      <Menu.Item
        as="a"
        style={{ position: "absolute", width: "100%", bottom: "0px" }}
        data-cy="trouble-ticket"
        onClick={() => handleTrouble()}
      >
        Trouble Ticket
      </Menu.Item>
    </Sidebar>
  );
}

SideBarContainer.propTypes = {
  logout: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

SideBarContainer.propTypes = {
  history: PropTypes.object,
  user: PropTypes.object
};

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(SideBarContainer)
);
