import _ from "lodash";
import React from "react";
import { Button, Popup } from "semantic-ui-react";

function WidgetToolbar(props) {
  return (
    <div className="homePageToolbar">
      {_.map(props.componentListDefault, component => (
        <Popup
          key={component.key}
          content="Add to screen"
          trigger={
            <Button
              color="blue"
              inverted
              disabled={props.renderedComponentList[component.key] != undefined}
              onClick={() => {
                props.addComponent(component.key);
              }}
            >
              {component.text}
            </Button>
          }
        ></Popup>
      ))}
    </div>
  );
}

export default WidgetToolbar;
