import axios from "axios";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Form,
  Icon,
  Input,
  Checkbox,
  Label,
  Modal,
  Table
} from "semantic-ui-react";
import "../../styles/adminTabs.css";
import ModalBuilder from "./modalBuilder";

function ProjectTab(props) {
  const [visible, setVisible] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [displayedList, setDisplayedList] = useState([]);
  const [showInactiveChecked, setShowInactiveChecked] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [techLeadList, setTechLeadList] = useState([]);
  const [newProjectName, setNewProjectName] = useState("");
  const [newClientId, setNewClientId] = useState(null);
  const [newLeadIds, setNewLeadIds] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteProjectId, setDeleteProjectId] = useState(null);
  const [deleteProjectName, setDeleteProjectName] = useState("");
  const [nameError, setNameError] = useState("");
  const [clientError, setClientError] = useState("");
  const [techLeadError, setTechLeadError] = useState("");
  const [projectAssignedToUser, setProjectAssignedToUser] = useState(false);
  const [editable, setEditable] = useState(
    props.user.userRole.adminProjectsPerm > 1
  );
  const [previousSort, setPreviousSort] = useState({
    column: "name",
    sortASC: true
  });
  const [filterBy, setFilterBy] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const [editProject, setEditProject] = useState({});
  const [modal, setModal] = useState("");

  useEffect(() => {
    getProjectList();
    getTechLeadList();
  }, []);

  useEffect(() => {
    if (previousSort.column === "name" && previousSort.sortASC) {
      filter(filterBy);
    } else {
      sort(previousSort);
    }
    getClientList();
  }, [projectList]);

  useEffect(() => {
    updateDisplayedProjects();
  }, [showInactiveChecked, filteredList]);

  const getProjectList = () => {
    axios.get("/project/findProjectList").then(res => {
      setProjectList(res.data);
      setDisplayedList(showInactiveChecked ? res.data : filterActiveProjects(res.data))
    });
  };

  const getClientList = () => {
    axios.get("/client/getClientList/active").then(res => {
      setClientList(res.data);
    });
  };

  const getTechLeadList = () => {
    const req = {
      role: ["Admin", "Tech Lead"]
    };
    axios.post("role/findUserListByRole", req).then(res => {
      setTechLeadList(res.data);
    });
  };

  const handleDelete = () => {
    axios.post("/project/deleteProject", { id: deleteProjectId }).then(res => {
      if (res.status === 204) {
        setProjectAssignedToUser(true);
      }
      getProjectList();
    });
    hideModal();
  };

  const projectEdit = project => {
    setEditProject(project);
    setModal("editProject");
  };

  const showDeleteModal = project => {
    setDeleteModalVisible(true);
    setDeleteProjectId(project.id);
    setDeleteProjectName(project.projectName);
  };

  const closeModal = () => {
    setModal(null);
    setNameError("");
    setClientError("");
    setTechLeadError("");
  };

  const hideModal = () => {
    setVisible(false);
    setDeleteModalVisible(false);
    setNewProjectName("");
    setNewClientId(null);
    setNewLeadIds(null);
    setNameError("");
    setClientError("");
    setTechLeadError("");
    setProjectAssignedToUser(false);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleSubmit = () => {
    let validForm = true;
    if (newProjectName === "") {
      setNameError("Enter Project Name");
      validForm = false;
    } else if (projectList.find(e => e.projectName === newProjectName)) {
      setNameError("Project Already Exists");
      validForm = false;
    }
    if (newClientId === null) {
      setClientError("Select a Client");
      validForm = false;
    }
    if (newLeadIds.length <= 0) {
      setTechLeadError("Select a Tech Lead");
      validForm = false;
    }
    if (validForm) {
      const req = {
        projectName: newProjectName,
        clientId: newClientId,
        techLeadIdList: newLeadIds
      };
      axios.post("/project/createProject", req).then(res => {
        setVisible(false);
        hideModal();
        getProjectList();
      });
    }
  };

  const sort = previousSort => {
    const newProjectList = projectList;
    if (previousSort.column === "name") {
      newProjectList.sort((a, b) => {
        return a.projectName.toLowerCase() > b.projectName.toLowerCase()
          ? 1
          : -1;
      });
    } else if (previousSort.column === "client") {
      newProjectList.sort((a, b) => {
        return a.client.clientName.toLowerCase() >
          b.client.clientName.toLowerCase()
          ? 1
          : -1;
      });
    } else if (previousSort.column === "techLead") {
      newProjectList.sort((a, b) => {
        console.log(a, b);
        let projsA = [],
          projsB = [];
        for (const i of a.techLeads) projsA.push(i);
        for (const i of b.techLeads) projsB.push(i);
        return projsA.length > projsB.length ? 1 : -1;
      });
    }
    if (!previousSort.sortASC) newProjectList.reverse();

    setPreviousSort(previousSort);
    setProjectList(newProjectList);
    filter(filterBy);
  };

  const sortBy = column => {
    let sortASC = true;
    if (previousSort.column === column) {
      sortASC = !previousSort.sortASC;
    }
    sort({
      column: column,
      sortASC: sortASC
    });
  };

  const filter = text => {
    if (text === "") {
      setFilterBy("");
      setFilteredList(projectList);
    } else {
      setFilteredList(
        projectList.filter(item => {
          if (item.projectName.toLowerCase().includes(text.toLowerCase())) {
            return true;
          } else if (
            item.client.clientName.toLowerCase().includes(text.toLowerCase())
          ) {
            return true;
          } else if (
            _.find(item.techLeads, techLead => {
              if (
                (techLead.firstName + " " + techLead.lastName)
                  .toLowerCase()
                  .includes(text.toLowerCase())
              ) {
                return true;
              }
            })
          ) {
            return true;
          }
        })
      );
    }
  };

  function updateDisplayedProjects() {
    if (showInactiveChecked) setDisplayedList(filteredList)
    else {
      let activeList = filterActiveProjects(filteredList);
      setDisplayedList(activeList);
    }
  }

  function filterActiveProjects(projects) {
    return projects.filter( proj => proj.active );
  }

  const validateForm = () => {
    let checkProject = editProject;
    let validForm = true;
    if (editProject.projectName === "") {
      setNameError("Enter Project Name");
      validForm = false;
    } else if (
      _.filter(projectList, function(o) {
        return o.id !== checkProject.id;
      }).find(e => e.projectName === checkProject.projectName)
    ) {
      setNameError("Project Already Exists");
      validForm = false;
    }

    if (editProject.techLeads <= 0) {
      setTechLeadError("Select a Tech Lead");
      validForm = false;
    }

    if (validForm) {
      //This checks to see if the user COE has been edited, if so updateUserCOE will be replaced with updateUser
      let newLeads = [];
      _.map(editProject.techLeads, id => {
        newLeads.push(id);
      });

      let req = {
        id: editProject.id,
        projectName: editProject.projectName,
        client: editProject.client,
        active: editProject.active,
        techLeadIdList: newLeads
      };

      axios
        .post("/project/updateProject", req)
        .then(() => {
          getProjectList();
          closeModal();
        })
        .catch(() => {
          setNameError("Project Name may contain no more than 30 characters.");
        });
    }
  };

  function handleLeadChange(data) {
    setEditProject({ ...editProject, techLeads: data });
  }

  function getTechLeadDropdownOptions() {
  // Inside the Edit Project modal, the list of Tech Leads available
  // to assign or remove from a project should only include
  // Tech Leads who are active or who are already assigned
  // to that project, even if inactive. 
    let techLeadSet = new Set();

    function findUserById(user) {
      if (user.id === this) {
        return user;
      }
    }

    let editProjectTechLeads = editProject.techLeads;
    if (Array.isArray(editProjectTechLeads) && editProjectTechLeads.length) {
      editProjectTechLeads.forEach(user => {
        if (!techLeadSet.has(user)) {
          techLeadSet.add(techLeadList.find(findUserById, user.id));
        }
      })
    } 

    let activeTechLeads = techLeadList.filter( user => user.active==true );
    activeTechLeads.forEach(user => {
      if (!techLeadSet.has(user)) {
        techLeadSet.add(user);
      }
    })

    let techLeads = [...techLeadSet];
    return techLeads;
  }

  let clientDropdownOption = [];
  clientDropdownOption = _.map(clientList, client => ({
    key: client.id,
    value: client.id,
    text: client.clientName
  }));

  let techDropdownOption = [];
  let techLeadOptionList = getTechLeadDropdownOptions();
  techDropdownOption = _.map(techLeadOptionList, user => ({
    key: user.id,
    value: user.id,
    text: user.firstName + " " + user.lastName
  }));

  return (
    <div>
      <div>
        <h2 className="title">Project Table</h2>
      </div>
      <br />
      <div className="aboveTable" id="projectTableHeader">
        <Button
          inverted
          color="green"
          disabled={!editable}
          onClick={showModal}
          size="medium"
          className="ui.medium.button"
          data-cy="addProject"
        >
          Add Project
        </Button>
        <Checkbox
          label="Show inactive projects"
          checked={showInactiveChecked}
          onChange={() => setShowInactiveChecked(!showInactiveChecked)}
        />
        <Input
          icon="search"
          placeholder="Filter By"
          maxLength="30"
          onChange={e => filter(e.target.value)}
        />
      </div>
      <br />
      <Table
        sortable
        celled
        id="table"
        data-cy="projectTable"
        verticalAlign="middle"
        textAlign="center"
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              className="columnHeader"
              onClick={() => sortBy("name")}
              sorted={
                previousSort.column === "name"
                  ? previousSort.sortASC
                    ? "ascending"
                    : "descending"
                  : null
              }
            >
              Name
            </Table.HeaderCell>
            <Table.HeaderCell
              className="columnHeader"
              onClick={() => sortBy("client")}
              sorted={
                previousSort.column === "client"
                  ? previousSort.sortASC
                    ? "ascending"
                    : "descending"
                  : null
              }
            >
              Client
            </Table.HeaderCell>
            <Table.HeaderCell
              className="columnHeader"
              onClick={() => sortBy("techLead")}
              sorted={
                previousSort.column === "techLead"
                  ? previousSort.sortASC
                    ? "ascending"
                    : "descending"
                  : null
              }
            >
              Tech Lead(s)
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {_.map(displayedList, project => (
            <Table.Row
              key={project.id}
              className="rowLayout"
              onClick={() => projectEdit(project)}
            >
              <Table.Cell>{project.projectName}</Table.Cell>

              <Table.Cell>{project.client.clientName}</Table.Cell>

              {/* Tech Lead */}
              <Table.Cell>
                {_.map(project.techLeads, techLead => {
                  return (
                    <Label
                      horizontal
                      size="large"
                      key={techLead.id}
                      content={`${techLead.firstName} ${techLead.lastName}`}
                    />
                  );
                })}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Modal
        closeIcon
        id="projectModal"
        open={visible}
        className="scrolling modal"
        size="tiny"
        closeOnDocumentClick
        onClose={hideModal}
      >
        <Modal.Header content="Add Project" />
        <Modal.Content>
          <Form size="small" className="login-form">
            <Form.Group widths="equal">
              <Form.Field>
                <Form.Input
                  required
                  type="text"
                  data-cy="newProject"
                  id="newProject"
                  label="New Project"
                  placeholder="New Project"
                  maxLength="30"
                  error={nameError !== ""}
                  onBlur={e => setNewProjectName(e.target.value.trim())}
                  onChange={() => setNameError("")}
                />
                {nameError === "" ? null : (
                  <Label pointing color="red">
                    {nameError}
                  </Label>
                )}
              </Form.Field>
              <Form.Field>
                <Form.Dropdown
                  required
                  selection
                  label="Client"
                  selectOnBlur={false}
                  error={clientError !== ""}
                  value={newClientId}
                  options={clientDropdownOption}
                  onChange={(e, data) => {
                    setNewClientId(data.value);
                    setClientError("");
                  }}
                  text={newClientId ? null : "Client"}
                />
                {clientError === "" ? null : (
                  <Label pointing color="red">
                    {clientError}
                  </Label>
                )}
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field className="width100">
                <Form.Dropdown
                  required
                  selection
                  multiple
                  label="Tech Lead"
                  selectOnBlur={false}
                  error={techLeadError !== ""}
                  options={techDropdownOption}
                  onChange={(e, data) => {
                    setNewLeadIds(
                      data.value.map(techLead => {
                        return { id: techLead };
                      })
                    );
                    setTechLeadError("");
                  }}
                  text={"Tech Lead(s)"}
                />
                {techLeadError === "" ? null : (
                  <Label pointing color="red">
                    {techLeadError}
                  </Label>
                )}
              </Form.Field>
              <Form.Field />
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            icon
            size="small"
            inverted
            color="green"
            onClick={handleSubmit}
          >
            <Icon name="checkmark" /> Create
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        closeIcon
        id="deleteModal"
        className="scrolling"
        size="tiny"
        open={deleteModalVisible}
        closeOnDocumentClick
        onClose={hideModal}
      >
        <Modal.Header>Delete Project</Modal.Header>
        <Modal.Content>
          <p>
            Are you sure you want to delete{" "}
            <span className="boldText"> {deleteProjectName} </span>?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            size="small"
            icon
            inverted
            color="red"
            data-cy="noBtn"
            onClick={hideModal}
          >
            <Icon name="remove" />
            {" No"}
          </Button>
          <Button
            inverted
            color="green"
            icon
            size="small"
            onClick={handleDelete}
          >
            <Icon name="checkmark" />
            {" Yes"}
          </Button>
        </Modal.Actions>
      </Modal>
      <ModalBuilder
        closeIcon
        title="Error"
        content="There is one or more users assigned to this project preventing you from deleting. If you wish to delete, you must assign the user(s) to a different project."
        type="notification"
        visible={projectAssignedToUser}
        closeFunction={hideModal}
      />

      {/* Edit Project Modal */}
      {modal !== "editProject" ? null : (
        <Modal
          closeIcon
          id="editProjectModal"
          className="ui dimmable dimmed scrolling modal editModal"
          size="tiny"
          open
          closeOnDocumentClick={true}
          onClose={closeModal}
        >
          <Modal.Header>
            {editable ? "Edit Project" : "View Project"}
          </Modal.Header>
          <Modal.Content>
            <Form size="small" className="login-form">
              <Form.Group widths="equal">
                <Form.Field>
                  <Form.Input
                    data-cy="projName"
                    required
                    label="Project Name"
                    placeholder="Edit Name"
                    maxLength="30"
                    disabled={!editable}
                    error={nameError !== ""}
                    onChange={(e, data) => {
                      let newProject = { ...editProject };
                      newProject.projectName = data.value;
                      setEditProject(newProject);
                      setNameError("");
                    }}
                    value={editProject.projectName}
                  />
                  {nameError === "" ? null : (
                    <Label pointing color="red">
                      {nameError}
                    </Label>
                  )}
                </Form.Field>
                <Form.Field>
                  <Form.Dropdown
                    data-cy="clientDropdown"
                    required
                    selection
                    label="Client"
                    disabled={!editable}
                    selectOnBlur={false}
                    defaultValue={editProject.client.id}
                    options={clientDropdownOption}
                    onChange={(e, data) => {
                      for (let i in clientList) {
                        if (clientList[i].id == data.value) {
                          let newProject = { ...editProject };
                          newProject.client = { ...clientList[i] };
                          setEditProject(newProject);
                          break;
                        }
                      }
                    }}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field className="width100">
                  <Form.Dropdown
                    data-cy="tlDropdown"
                    required
                    selection
                    multiple
                    label="Tech Lead(s)"
                    disabled={!editable}
                    selectOnBlur={false}
                    defaultValue={
                      editProject.techLeads
                        ? editProject.techLeads.map(p => {
                            return p.id;
                          })
                        : null
                    }
                    options={techDropdownOption}
                    onChange={(e, data) => {
                      handleLeadChange(
                        data.value.map(proj => {
                          return { id: proj };
                        })
                      );
                    }}
                  />
                  {techLeadError === "" ? null : (
                    <Label pointing color="red">
                      {techLeadError}
                    </Label>
                  )}
                </Form.Field>
                <Form.Field />
              </Form.Group>
              <Form.Group>
                <Form.Field>
                  <Form.Checkbox
                    id="active"
                    data-cy="activeCheckbox"
                    disabled={!editable}
                    defaultChecked={editProject.active}
                    label="Active"
                    onChange={(e, data)  => {
                      let newProject = { ...editProject };
                      newProject.active = data.checked;
                      setEditProject(newProject);
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Modal.Content>
          {editable ? (
            <Modal.Actions>
              {/* The Delete button is fully functional but is being removed for card #371 */}
              {/* <Button
                floated="left"
                inverted
                color="red"
                disabled={!editable}
                className="ui button"
                onClick={e => {
                  showDeleteModal(editProject);
                  closeModal();
                }}
              >
                Delete
              </Button> */}

              <Button
                icon
                inverted
                color="green"
                size="small"
                onClick={() => validateForm()}
              >
                <Icon name="checkmark" />
                Save
              </Button>
            </Modal.Actions>
          ) : null}
        </Modal>
      )}
    </div>
  );
}

ProjectTab.propTypes = {
  user: PropTypes.object
};
const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(ProjectTab);
